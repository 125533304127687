import {useLazyQuery, useMutation} from "@apollo/client"
import {Box, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from "@mui/material"
import CREATE_LICENSE from "api/apollo/mutations/CREATE_LICENSE"
import GET_COURSE_IDS from "api/apollo/queries/GET_COURSE_IDS"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import GET_SCHOOL_TEACHERS_IDS from "api/apollo/queries/GET_SCHOOL_TEACHERS_IDS"
import DataTable from "components/DataTable"
import {DataTableQueryUpdate, DataTableSchema} from "components/DataTable/types.t"
import DateField from "components/DateField"
import SelectAsync from "components/SelectAsync"
import {useFormik} from "formik"
import {
  CreateLicenseMutation,
  CreateLicenseMutationVariables,
  Exam,
  GetCourseIdsQuery,
  GetCourseIdsQueryVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetExamCodesQuery,
  GetExamCodesQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  GetSchoolTeachersIdsQuery,
  GetSchoolTeachersIdsQueryVariables,
  LearnDashCourse,
  School,
  SortOrder,
  User
} from "generated/graphql"
import {pick} from "lodash"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"
import * as Yup from "yup"

interface Props {
  onAfterSubmit: () => void
}

export default function CreateLicenseForm({
  onAfterSubmit
}: Props) {
  const dispatch = useDispatch()

  const [teachersData, setTeachersData] = useState<QueryDataType<Partial<User>>>(null)
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>([])

  const [coursesData, setCoursesData] = useState<Partial<LearnDashCourse>[] | null>(null)
  const [selectedCourses, setSelectedCourses] = useState<number[]>([])

  const [examsData, setExamsData] = useState<QueryDataType<Partial<Exam>>>(null)
  const [selectedExams, setSelectedExams] = useState<Partial<Exam>[]>([])

  const [licenseAssignment, setLicenseAssignment] = useState<
    "ALL_TEACHERS" | "SPECIFIC_TEACHERS"
  >("ALL_TEACHERS")

  const [selectedSchool, setSelectedSchool] = useState<Partial<School> | null>(null)
  const [districtId, setDistrictId] = useState("")

  const containsWpExam = useMemo(() => {
    return selectedExams.some(i => i.displayName.toLowerCase().includes("wordpress"))
  }, [selectedExams])

  const [schoolsQueryFetch, schoolsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [districtQueryFetch, districtQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [teachersQueryFetch, teachersQuery] = useLazyQuery<
    GetSchoolTeachersIdsQuery,
    GetSchoolTeachersIdsQueryVariables
  >(GET_SCHOOL_TEACHERS_IDS)

  const [examsQueryFetch, examsQuery] = useLazyQuery<
    GetExamCodesQuery,
    GetExamCodesQueryVariables
  >(GET_EXAM_CODES)

  const [coursesQueryFetch, coursesQuery] = useLazyQuery<
    GetCourseIdsQuery,
    GetCourseIdsQueryVariables
  >(GET_COURSE_IDS)

  const [createLicense, {loading}] = useMutation<
    CreateLicenseMutation,
    CreateLicenseMutationVariables
  >(CREATE_LICENSE)

  const teachersError = useMemo(() => {
    return !teachersData && (teachersQuery.error || null)
  }, [teachersData, teachersQuery.error])

  const coursesError = useMemo(() => {
    return !coursesData && (coursesQuery.error || null)
  }, [teachersData, coursesQuery.error])

  const examsError = useMemo(() => {
    return !examsData && (examsQuery.error || null)
  }, [examsData, examsQuery.error])

  const schools = useMemo(() => {
    return schoolsQuery.data?.schools.items || []
  }, [schoolsQuery.data])

  const districts = useMemo(() => {
    return districtQuery.data?.districts.items || []
  }, [districtQuery.data])

  const formik = useFormik({
    initialValues: {
      schoolId: "",
      courses: 0,
      exams: 0,
      practices: 0,
      labs: 0,
      name: "",
      isOwnedBySchool: false,
      expirationDate: null,
      isUniversalLicense: false
    },
    validationSchema: Yup.object().shape({
      schoolId: Yup.string().required("License is required"),
      courses: Yup.number().integer().min(0),
      exams: Yup.number().integer().min(0),
      practices: Yup.number().integer().min(0),
      labs: Yup.number().integer().min(0),
      name: Yup.string().required("License Name is required"),
      expirationDate: Yup.number().nullable(true).transform((_, v) => v === Number(v) ? v : null).required("Expiration Date is required")
    }),
    onSubmit: values => {
      if (licenseAssignment === "SPECIFIC_TEACHERS" && !selectedTeachers.length) {
        dispatch(notifyUser({
          message: "Choose teachers first, please",
          variant: "info"
        }))
        return
      }
      if (!values.isUniversalLicense && !selectedExams.length) {
        dispatch(notifyUser({
          message: "Choose exams first, please",
          variant: "info"
        }))
        return
      }
      if (!!(values.courses || values.exams || values.practices || values.labs)) {
        createLicense({
          variables: {
            quota: {
              courses: values.courses || undefined,
              exams: values.exams || undefined,
              practices: values.practices || undefined,
              labs: values.labs || undefined
            },
            licensePayload: {
              courseIds: selectedCourses,
              examCodes: selectedExams.map(i => i.code),
              expirationDate: new Date(values.expirationDate).toISOString(),
              isOwnedBySchool: values.isOwnedBySchool,
              isUniversalLicense: values.isUniversalLicense,
              name: values.name,
              schoolId: values.schoolId,
              teachersScope: selectedTeachers
            }
          }
        }).then(() => {
          onAfterSubmit()
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    }
  })

  const handleQuerySchools = (value: string) => {
    const selectedDistrict = districtId || undefined

    schoolsQueryFetch({
      variables: {
        search: value,
        districtIds: (selectedDistrict === "No district" || !selectedDistrict) ? undefined : [selectedDistrict],
        sortBy: "name",
        order: SortOrder.Asc
      }
    })
  }

  const handleQueryDistricts = (value: string) => {
    districtQueryFetch({
      variables: {
        search: value
      }
    })
  }

  const teachersTableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "First Name",
        fieldName: "firstName",
        sort: "firstName",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "text",
        headerText: "Last Name",
        fieldName: "lastName",
        sort: "lastName",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "checkbox",
        key: data => data._id,
        checked: data => {
          return selectedTeachers.includes(data._id)
        },
        handler: (selected, data) => {
          if (selected) {
            setSelectedTeachers(prevState => [...prevState, data._id])
          } else {
            setSelectedTeachers(prevState => prevState.filter(i => i !== data._id))
          }
        }
      }
    ]
  }, [teachersData, selectedTeachers])

  const handleQueryTeachers: DataTableQueryUpdate = (state) => {
    const {itemsPerPage, page, sort, searchQuery} = state

    if (selectedSchool) {
      teachersQueryFetch({
        variables: {
          schoolId: selectedSchool._id,
          take: itemsPerPage,
          offset: itemsPerPage * page,
          sortBy: sort?.key,
          order: sort?.order,
          search: searchQuery
        }
      }).then(res => {
        setTeachersData(res.data?.getSchool?.teachers || null)
      })
    }
  }

  const coursesTableSchema: DataTableSchema<LearnDashCourse> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Title",
        fieldName: "title.raw",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "checkbox",
        key: data => data.id.toString(),
        checked: data => {
          return selectedCourses.includes(data.id)
        },
        handler: (selected, data) => {
          if (selected) {
            setSelectedCourses(prevState => [...prevState, data.id])
          } else {
            setSelectedCourses(prevState => prevState.filter(i => i !== data.id))
          }
        }
      }
    ]
  }, [coursesData, selectedCourses])


  const handleQueryCourses: DataTableQueryUpdate = () => {
    coursesQueryFetch().then(res => {
      setCoursesData(res.data?.courses || null)
    })
  }

  const examsTableSchema: DataTableSchema<Exam> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Exam code",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "displayCode",
        sort: "displayCode"
      },
      {
        type: "text",
        headerText: "Exam name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "displayName"
      },
      {
        type: "checkbox",
        key: data => data._id,
        checked: data => {
          return selectedExams.some(i => i.code === data.code)
        },
        handler: (selected, data) => {
          if (selected) {
            setSelectedExams(prevState => [...prevState, pick(data, ["code", "displayName"])])
          } else {
            setSelectedExams(prevState => prevState.filter(i => i.code !== data.code))
          }
        }
      }
    ]
  }, [examsData, selectedExams])

  const handleQueryExams: DataTableQueryUpdate = (state) => {
    const {itemsPerPage, page, sort, searchQuery} = state

    examsQueryFetch({
      variables: {
        search: searchQuery,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order
      }
    }).then(res => setExamsData(res.data?.exams || null))
  }

  useEffect(() => {
    const id = formik.values.schoolId
    setSelectedSchool(id ? schools.find(i => i._id === id) : null)
    setSelectedTeachers([])
    setTeachersData(null)
    setLicenseAssignment("ALL_TEACHERS")
  }, [formik.values.schoolId])

  useEffect(() => {
    setSelectedTeachers([])
  }, [licenseAssignment])

  useEffect(() => {
    if (!containsWpExam) {
      formik.setFieldValue("labs", 0)
    }
  }, [containsWpExam])

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box px={4} minWidth="54vw">
          <Typography variant="h5" textAlign="center" p={2} mb={2}>
            Create inventory
          </Typography>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              Inventory Details
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              <Grid item xs={6}>
                <TextField
                  name="name"
                  value={formik.values["name"]}
                  error={!!(formik.touched["name"] && formik.errors["name"])}
                  helperText={formik.touched["name"] && formik.errors["name"]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label="License Name"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="courses"
                  value={formik.values["courses"]}
                  error={!!(formik.touched["courses"] && formik.errors["courses"])}
                  helperText={formik.touched["courses"] && formik.errors["courses"]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label="Courses"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    min: 0,
                    type: "number"
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    name="exams"
                    value={formik.values["exams"]}
                    error={!!(formik.touched["exams"] && formik.errors["exams"])}
                    helperText={formik.touched["exams"] && formik.errors["exams"]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Exams"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      type: "number"
                    }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    name="practices"
                    value={formik.values["practices"]}
                    error={!!(formik.touched["practices"] && formik.errors["practices"])}
                    helperText={formik.touched["practices"] && formik.errors["practices"]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Practices"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      type: "number"
                    }}
                />
              </Grid>
              <Grid item xs={6}>
                <DateField
                  name="expirationDate"
                  onChange={(value) => formik.setFieldValue("expirationDate", value)}
                  value={formik.values["expirationDate"]}
                  error={formik.touched["expirationDate"] && formik.errors["expirationDate"] as string}
                  label="Expiration Date"
                  touched={!!(formik.touched["expirationDate"])}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              License belongs to the school
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              <Grid item xs={6}>
                <RadioGroup
                  name="isOwnedBySchool"
                  value={formik.values["isOwnedBySchool"]}
                  onChange={(_, value) => formik.setFieldValue("isOwnedBySchool", value === "true")}
                >
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              License is universal
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              <Grid item xs={6}>
                <RadioGroup
                  name="isUniversalLicense"
                  value={formik.values["isUniversalLicense"]}
                  onChange={(_, value) => {
                    formik.setFieldValue("isUniversalLicense", value === "true")
                    formik.setFieldValue("labs", 0)
                    setSelectedExams([])
                  }}
                >
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Box>
          {!formik.values.isUniversalLicense &&
            <Box minHeight="20vh" mb={2}>
              <Typography variant="h6" mb={2}>
                Exams
              </Typography>
              <DataTable
                schema={examsTableSchema}
                data={examsData?.items}
                error={!!examsError}
                itemsTotalCount={examsData?.total}
                onQueryUpdate={handleQueryExams}
                search="Search Exams"
                loading={examsQuery.loading}
                lastPage={!examsData?.hasMore}
              />
            </Box>
          }
          {(formik.values.isUniversalLicense || containsWpExam) && (
            <Box mb={2}>
              <Typography variant="h6" mb={2}>
                Labs
              </Typography>
              <TextField
                name="labs"
                value={formik.values["labs"]}
                error={!!(formik.touched["labs"] && formik.errors["labs"])}
                helperText={formik.touched["labs"] && formik.errors["labs"]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Labs"
                fullWidth
                variant="outlined"
                inputProps={{
                  min: 0,
                  type: "number"
                }}
              />
            </Box>
          )}
          <Box minHeight="20vh" mb={2}>
            <Typography variant="h6" mb={2}>
              Courses
            </Typography>
            <DataTable
              schema={coursesTableSchema}
              data={coursesData}
              error={!!coursesError}
              onQueryUpdate={handleQueryCourses}
              loading={coursesQuery.loading}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="h6" mb={2}>
              School Details
            </Typography>
            <Grid container spacing={3} direction="row" maxWidth="860px">
              <Grid item xs={6}>
                <SelectAsync
                  id="districtId"
                  label="District"
                  value={districtId}
                  onChange={value => {
                    setDistrictId(value)
                    setSelectedSchool(null)
                    formik.setFieldValue("schoolId", "")
                  }}
                  loading={districtQuery.loading}
                  options={[
                    {label: "No district", value: "No district"},
                    ...districts.map(i => ({
                      label: i.name,
                      value: i._id
                    }))
                  ]}
                  onUpdateOptions={handleQueryDistricts}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectAsync
                  id="schoolId"
                  label="School"
                  error={formik.errors["schoolId"]}
                  touched={formik.touched["schoolId"]}
                  value={formik.values["schoolId"]}
                  onChange={(value) => formik.setFieldValue("schoolId", value)}
                  loading={schoolsQuery.loading}
                  options={schools.map(i => ({
                    label: i.name,
                    value: i._id
                  }))}
                  onUpdateOptions={handleQuerySchools}
                />
              </Grid>
            </Grid>
          </Box>
          {selectedSchool &&
            <Box mb={2}>
              <Typography variant="h6" mb={2}>
                Assign inventory to specific teacher
              </Typography>
              <Grid container spacing={3} direction="row" maxWidth="860px">
                <Grid item xs={6}>
                  <RadioGroup
                    value={licenseAssignment}
                    onChange={((_e, value: typeof licenseAssignment) => setLicenseAssignment(value))}
                  >
                    <FormControlLabel value="ALL_TEACHERS" control={<Radio />} label="No, allow access to all teachers" />
                    <FormControlLabel value="SPECIFIC_TEACHERS" control={<Radio />} label="Yes, select teachers" />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Box>
          }
          {selectedSchool && licenseAssignment === "SPECIFIC_TEACHERS" &&
            <Box minHeight="20vh">
              <Typography variant="h6" mb={2}>
                Teachers
              </Typography>
              <DataTable
                schema={teachersTableSchema}
                data={teachersData?.items}
                error={!!teachersError}
                itemsTotalCount={teachersData?.total}
                emptyDataMessage={selectedSchool ? "Teachers not found" : "Please select a school"}
                onQueryUpdate={handleQueryTeachers}
                search="Search Teachers"
                loading={teachersQuery.loading}
                lastPage={!teachersData?.hasMore}
              />
            </Box>
          }
        </Box>
        <Box display="flex" justifyContent="end" p={4}>
          <Button
            type="submit"
            variant="contained"
            disabled={!(
              formik.values.courses ||
              formik.values.exams ||
              formik.values.practices ||
              formik.values.labs
            ) || loading}
          >
            Create
          </Button>
        </Box>
      </form>
    </Box>
  )
}
