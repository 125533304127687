import {gql} from "@apollo/client"

export default gql`
  query GetMyClassrooms(
    $offset: Int!
    $take: Int!
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    getMyClassrooms(
    offset: $offset
    take: $take
    sortBy: $sortBy
    order: $order
    search: $search
    ) {
      hasMore
      total
      items {
        _id
        createdAt
        updatedAt
        isArchived
        joinCode
        name
        school {
          _id
          createdAt
          updatedAt
          code
          name
          state
          city
          logo
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        status
        assignedTeachers
        createdBy
        teachers {
          _id
          username
          firstName
          lastName
          fullName
          totalTimeSpentInLabs
        }
      }
    }
  }
`
