import {gql} from "@apollo/client"

export default gql`
  query GetStaff(
    $isStaffInSchool: GraphQLObjectId
    $isStaffInDistrict: GraphQLObjectId
    $offset: Int = 0
    $take: Int = 100
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    users(
      isStaffInSchool: $isStaffInSchool
      isStaffInDistrict: $isStaffInDistrict
      search: $search 
      sortBy: $sortBy 
      order: $order 
      offset: $offset 
      take: $take
    ) {
      items {
        _id
        adminInSchoolsCount(districtId: $isStaffInDistrict)
        teacherInSchoolsCount(districtId: $isStaffInDistrict)
        adminInSchoolIds
        adminInDistrictIds 
        lastName
        firstName
        email
        isAdmin
        isProctor
        teacherInSchoolIds
      }
      total
      hasMore
    }
  }
`
