import React from "react"
import {createStyles, makeStyles} from "@mui/styles"
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs"
import SyntaxHighlighter from "react-syntax-highlighter"
import {ICustomAccordionItemProps} from "../index"

const useStyles = makeStyles(theme =>
  createStyles({
    qdescription: {
      color: "#172b4d",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "bold",
      padding: "10px 0"
    },
    rightAnswerWrap: {
      border: "1px solid #0000002b",
      padding: "0 10px",
      "& .syn-hlight-wrapper, .mult-root-c-label": {
        marginBottom: 0
      }
    },
    codeSnippetClass: {
      display: "flex",
      alignItems: "center",
      minWidth: "300px",
      backgroundColor: "rgb(248, 248, 255)",
      fontSize: "14px",
      margin: "0 0 20px 0"
    }
  })
)

interface Props extends Pick<ICustomAccordionItemProps, "codeSnippet" | "description" | "answerToRender"> {}

export default function MultipleAccordionDetails({
  description,
  codeSnippet,
  answerToRender
}: Props) {
  const s = useStyles()

  return (
    <>
      <div className={s.qdescription}>
        {description}
      </div>
      {codeSnippet && (
        <div className={` qcd-syntax-wrap ${s.codeSnippetClass}`}>
          {/*@ts-ignore*/}
          <SyntaxHighlighter
            language={codeSnippet?.language || "python"}
            style={docco}>
            {codeSnippet?.code || ""}
          </SyntaxHighlighter>
        </div>
      )}
      <div className={s.rightAnswerWrap}>
        {answerToRender}
      </div>
    </>
  )
}
