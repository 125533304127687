import {gql} from "@apollo/client"

export default gql`
  query GetClassroomIds(
    $districtId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
    $includeArchived: Boolean
  ) {
    classrooms(
      districtId: $districtId
      schoolId: $schoolId
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
      includeArchived: $includeArchived
    ) {
      total
      hasMore
      items {
        _id
        name
      }
    }
  }
`
