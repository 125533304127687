import {gql} from "@apollo/client"

export default gql`
  query GetUserClassrooms($userId: GraphQLObjectId!) {
    getUser(userId: $userId) {
      _id
      classrooms {
        _id
        assignedTeachers
        createdAt
        createdBy
        isArchived
        joinCode
        name
        schoolId
        status
        updatedAt
      }
    }
  }
`
