import {gql} from "@apollo/client"

export default gql`
  mutation ExtendLabSessionTime($labSessionId: GraphQLObjectId!) {
    extendLabSessionTime(labSessionId: $labSessionId) {
      expirationDate
      _id
    }
  }
`
