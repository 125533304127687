import {useLazyQuery} from "@apollo/client"
import {Box} from "@mui/material"
import GET_EXAMS from "api/apollo/queries/GET_EXAMS"
import DataTable from "components/DataTable"
import {
  DataTableQueryUpdate,
  DataTableSchema
} from "components/DataTable/types.t"
import {
  Classroom,
  Exam,
  GetExamsQuery,
  GetExamsQueryVariables, SortOrder
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"

interface Props {
  classroom?: DeepPartial<Classroom>
  loading: boolean
  onSubmit: (selectedExams: string[]) => void
}

export default function ExamsTable({
  classroom,
  loading,
  onSubmit
}: Props) {
  const [selectedExams, setSelectedExams] = useState(classroom?.defaultExamFilter || [])


  const [examsQueryFetch, examsQuery] = useLazyQuery<
    GetExamsQuery,
    GetExamsQueryVariables
  >(GET_EXAMS)

  const examsData = useMemo(() => {
    return examsQuery.data?.exams || null
  }, [examsQuery.data])

  const examsError = useMemo(() => {
    return !examsData && (examsQuery.error || null)
  }, [
    examsData,
    examsQuery.error
  ])

  const tableSchema: DataTableSchema<Exam> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Exam code",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "displayCode"
      },
      {
        type: "text",
        headerText: "Exam name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "displayName"
      },
      {
        type: "checkbox",
        key: data => data._id,
        checked: data => {
          return selectedExams.includes(data.code)
        },
        handler: (selected, data) => {
          if (selected) {
            setSelectedExams(prevState => [...prevState, data.code])
          } else {
            setSelectedExams(prevState => prevState.filter(i => i !== data.code))
          }
        }
      }
    ]
  }, [examsData, selectedExams, classroom?.defaultExamFilter])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const {itemsPerPage, page, sort, searchQuery} = state

    const fetchPolicy = options?.refetch ? "network-only" : undefined

    examsQueryFetch({
      fetchPolicy,
      variables: {
        search: searchQuery,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key || "displayCode",
        order: sort?.order || SortOrder.Asc
      }
    })
  }

  useEffect(() => {
    onSubmit(selectedExams)
  }, [selectedExams])

  return (
    <Box minHeight="20vh">
      <DataTable
        schema={tableSchema}
        data={examsData?.items}
        loading={examsQuery.loading || loading}
        error={!!examsError}
        itemsTotalCount={examsData?.total}
        lastPage={!examsData?.hasMore}
        emptyDataMessage="Exams not found"
        onQueryUpdate={handleQuery}
        search="Search Exams"
      />
    </Box>
  )
}
