import {useMemo} from "react"
import getExamCodeName from "utils/getExamCodeName"

const useExamFullName = (params) => {
  return useMemo(() => {
    if (!params?.examName) return ""

    let result = ""
    let abbr = params.examName.split("-")

    if (abbr && abbr[1]) result = getExamCodeName(abbr[1])

    return result ? result : ""
  }, [params])
}

export default useExamFullName
