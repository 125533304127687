import React, {useEffect, useState, useMemo} from "react"
import get from "lodash/get"
import CopyToClipboard from "./CopyToClipboard"
import {DataTableTextCol} from "../types.t"
import {Box, List, ListItem, ListItemText, SxProps, TableCell, Theme} from "@mui/material"

interface Props {
  input: any
  schemaColRef: DataTableTextCol
  sx?: SxProps<Theme>
}

export default function DefaultCell({
  input,
  schemaColRef,
  sx
}: Props) {
  const [cellContent, setCellContent] = useState(null)

  const fieldInCell = useMemo(() => {
    return get(input, schemaColRef?.fieldName) || ""
  }, [input, schemaColRef])

  useEffect(() => {
    if (!input) return
    if (!Array.isArray(fieldInCell)) return
    if (!fieldInCell?.length) return

    const textItems = fieldInCell.map((el, idx) => {
      return (
        <ListItem sx={{padding: 0}} key={`${idx} _cell_array_item`}>
          <ListItemText>{el}</ListItemText>
        </ListItem>
      )
    })

    setCellContent(
      <List sx={{padding: 0}}>
        {textItems}
      </List>
    )
  }, [input])

  useEffect(() => {
    if (!input || !(typeof fieldInCell === "string" || typeof fieldInCell === "number")) {
      return
    }

    setCellContent(fieldInCell)
  }, [input])

  return (
    <TableCell sx={sx}>
      {(schemaColRef.copyAction && !!cellContent) ? (
        <Box display="flex" alignItems="center">
          {cellContent}
          <Box ml={1}>
            <CopyToClipboard value={cellContent}/>
          </Box>
        </Box>
      ) : (
        cellContent || schemaColRef.placeholder
      )}
    </TableCell>
  )
}
