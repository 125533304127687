import {Link as RouterLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {Box, Button, Container, Typography} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import {useTheme} from "@mui/material"
import useMainPageLink from "hooks/useMainPageLink"

interface Props {
  link?: {
    path: string
    label: string
  }
}

export default function NotFound({
  link
}: Props) {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"))

  const {mainPageLink} = useMainPageLink()

  return (
    <>
      <Helmet>
        <title>404 Not Found | KP Platform</title>
      </Helmet>
      <Box display="flex" alignItems="center" minHeight="100%" px={3} py="80px" sx={{
        backgroundColor: "background.paper"
      }}>
        <Container maxWidth="lg">
          <Typography align="center" color="textPrimary" variant={mobileDevice ? "h4" : "h2"}>
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            mt={2}
            variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Typography>
          <Box display="flex" justifyContent="center" mt={6}>
            <Button
              component={RouterLink}
              to={link?.path || mainPageLink}
              color="primary"
              variant="contained"
              sx={{px: 4}}>
              {link?.label || "Back to Home"}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}
