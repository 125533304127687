import {IconButton} from "@mui/material"
import React, {useState} from "react"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"

interface Props {
  text: string
}

export default function SpeechSynthesis({text}: Props) {
  const [play, setPlay] = useState(false)

  const handleClick = () => {
    const synth = window.speechSynthesis

    const startSpeak = () => {
      const utterance = new SpeechSynthesisUtterance(text)

      utterance.onstart = () => {
        setPlay(true)
      }

      utterance.onerror = () => {
        setPlay(false)
      }

      utterance.onend = () => {
        setPlay(false)
      }

      synth.speak(utterance)
    }

    if (synth.speaking) {
      synth.cancel()
      !play && startSpeak()
    } else {
      startSpeak()
    }
  }

  return (
    <IconButton onClick={handleClick}>
      <VolumeUpIcon color={play ? "primary" : undefined}/>
    </IconButton>
  )
}
