import React from "react"
import {useLocation} from "react-router-dom"
import ReactGA from "react-ga4"

import {gaConfig} from "config"

export const useGoogleAnalytics = () => {
  ReactGA.set({environment: process.env.REACT_APP_SENTRY_ENVIRONMENT})

  const location = useLocation()

  React.useEffect(() => {
    if (gaConfig.gtmId) {
      ReactGA.initialize(gaConfig.gtmId)
    }
  }, [])

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    ReactGA.set({page: currentPath})
    ReactGA.send({hitType: "pageview", page: currentPath})
  }, [location])
}