import {gql} from "@apollo/client"

export default gql`
  query GetTestingGroups(
    $schoolId: GraphQLObjectId
    $examCode: String
    $includeArchived: Boolean
    $order: SortOrder
    $sortBy: String
    $take: Int = 100
    $offset: Int = 0
    $search: String
  ) {
    testingGroups(
      schoolId: $schoolId
      examCode: $examCode
      includeArchived: $includeArchived
      order: $order
      sortBy: $sortBy
      take: $take
      offset: $offset
      search: $search
    ) {
      total
      hasMore
      items {
        _id
        id: _id
        createdAt
        updatedAt
        schoolId
        licenseId
        teacher {
          _id
          username
          firstName
          lastName
          email
          phone
          isProctor
          fullName
          photoUrl
          totalTimeSpentInLabs
        }
        proctor {
          _id
          username
          firstName
          lastName
          email
          phone
          isProctor
          proctorSignedAt
          fullName
          photoUrl
          totalTimeSpentInLabs
        }
        license {
          _id
          createdAt
          updatedAt
          schoolId
          name
          duration
          examCodes
          expirationDate
          examVouchersRemain
          examVouchersInitial
          practiceVouchersRemain
          practiceVouchersInitial
          allowedRetakesCount
          coursesRemain
          coursesInitial
          labsInitial
          labsRemain
        }
        proctoring
        name
        code
        status
        paymentType
        examCode
        isArchived
      }
    }
  }
`
