import {gql} from "@apollo/client"

export default gql`
  mutation RemoveAdminFromDistrict(
    $districtId: GraphQLObjectId! 
    $userId: GraphQLObjectId!
  ) {
    removeAdminFromDistrict(
      districtId: $districtId
      userId: $userId
    ) {
      _id
    }
  }
`
