import {ExamSessionStatus} from "generated/graphql"

const names = {
  [ExamSessionStatus.Initiating]: "Initiating",
  [ExamSessionStatus.Initiated]: "Loading",
  [ExamSessionStatus.InProgress]: "In Progress",
  [ExamSessionStatus.Finished]: "Finished",
  [ExamSessionStatus.ScoringInProgress]: "Scoring",
  [ExamSessionStatus.ProctorReviewInProgress]: "Pending Review",
  [ExamSessionStatus.ReviewedByProctor]: "Completed",
  [ExamSessionStatus.ViolationReported]: "Violation Reported",
  [ExamSessionStatus.Canceled]: "Canceled"
}

export default function getExamSessionStatusName(input: string) {
  return names[input]
}
