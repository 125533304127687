import SearchIcon from "assets/icons/Search"
import {DataTableSearchConfig} from "components/DataTable/types.t"
import SelectAsync from "components/SelectAsync"
import React from "react"
import {
  InputAdornment,
  TextField
} from "@mui/material"

type Props = DataTableSearchConfig & {
  value?: string
  onChange?: (value: string) => void
}

export default function Search({
  label = "Search",
  disabled,
  select,
  value,
  onChange
}: Props) {
  return !!select ? (
    <SelectAsync
      id="search"
      label={label}
      icon={<SearchIcon/>}
      loading={select.loading}
      disabled={disabled}
      options={select.options}
      value={value}
      onChange={onChange}
      onUpdateOptions={select.onUpdateOptions}
    />
  ) : (
    <TextField
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small"/>
          </InputAdornment>
        )
      }}
      placeholder={label}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      value={value || ""}
    />
  )
}
