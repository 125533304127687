import CorrectIncorrectAnswer from "components/CorrectIncorrectAnswer"
import React from "react"
import {Box} from "@mui/material"
import {makeStyles} from "@mui/styles"

export interface Props {
  options: Array<{
    id: string
    _id: string
    text: string
    isCorrect: boolean
    isChosen: boolean
  }>
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.25, 0),
    width: "auto"
  }
}))

export default function SimpleAnswer({
  options
}: Props) {
  const s = useStyles()

  return (
    <Box className={s.root}>
      {options && options.map((i, num) => (
        <CorrectIncorrectAnswer
          key={i._id}
          index={num}
          text={i.text}
          isCorrect={i.isCorrect}
          isChosen={i.isChosen}
        />
      ))}
    </Box>
  )
}
