import {useEffect, useState} from "react"
import jsonParse from "utils/jsonParse"

export default function useLocalStorage<T>(key: string): [T, (value: T) => void] {
  const [value, setValue] = useState(jsonParse(localStorage.getItem(key)))

  useEffect(() => {
    const handler = (e: StorageEvent) => {
      if (e.key !== key) return

      const item = localStorage.getItem(key)
      setValue(jsonParse(item ?? ""))
    }

    window.addEventListener("storage", handler)

    return () => window.removeEventListener("storage", handler)
  }, [])

  const setValueWrap = (value: T) => {
    setValue(value)

    localStorage.setItem(key, JSON.stringify(value))
    window.dispatchEvent(new StorageEvent("storage", {key}))
  }

  return [value, setValueWrap]
}
