import {gql} from "@apollo/client"

export default gql`
  query GetCodingTaskLiveEvaluation($sourceCode: String!, $taskId: GraphQLObjectId!, $sessionId: GraphQLObjectId!) {
    getCodingTaskLiveEvaluation(sourceCode: $sourceCode, taskId: $taskId, sessionId: $sessionId) {
      canEvaluateAgain
      result {
        createdAt
        response {
          markdown
          rendered
        }
        sessionId
        userCognitoId
        taskId
      }
    }
  }
`
