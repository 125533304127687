import {useLazyQuery, useMutation, useQuery} from "@apollo/client"
import {LoadingButton} from "@mui/lab"
import {Box, Button, Link} from "@mui/material"
import CONNECT_TO_USER_LAB_SESSION from "api/apollo/mutations/CONNECT_TO_USER_LAB_SESSION"
import FINISH_LAB_SESSION from "api/apollo/mutations/FINISH_LAB_SESSION"
import GET_ACTIVE_LAB_SESSION from "api/apollo/queries/GET_ACTIVE_LAB_SESSION"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_LAB_SESSIONS from "api/apollo/queries/GET_LAB_SESSIONS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import ConfirmationAlert from "components/ConfirmationAlert"
import DataTable from "components/DataTable"
import {
  DataTableFilters,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import {LINKS} from "consts/links"
import {
  ConnectToUserLabSessionMutation,
  ConnectToUserLabSessionMutationVariables,
  District,
  FinishLabSessionMutation,
  FinishLabSessionMutationVariables,
  GetActiveLabSessionQuery,
  GetActiveLabSessionQueryVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetLabSessionsQuery,
  GetLabSessionsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  LabSession,
  School
} from "generated/graphql"
import {capitalize} from "lodash"
import React, {useMemo, useState} from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"
import convertSecondsToTime from "utils/convertSecondToTime"
import formatDate from "utils/formatDate"

type ContentType = "classroom" | "school" | "district" | "admin" | "user"

interface Props {
  userId?: string
  classroomId?: string
  schoolId?: string
  districtId?: string
  type?: ContentType
  latestOnly?: boolean
  prevOnly?: boolean
  showFinishDate?: boolean
}

export default function LabSessionsTable(props: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [query, setQuery] = useState<DataTableState | null>(null)
  const [sessionToManage, setSessionToManage] = useState<{
    id: string
    userId: string
  }>(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedSession, setSelectedSession] = useState<string | null>(null)
  const [labSessionsData, setLabSessionsData] = useState<QueryDataType<DeepPartial<LabSession>>>(null)
  const [schoolIdsData, setSchoolIdsData] = useState<Partial<School>[] | null>(null)
  const [districtIdsData, setDistrictIdsData] = useState<Partial<District>[] | null>(null)

  const activeLabSessionQuery = useQuery<
    GetActiveLabSessionQuery,
    GetActiveLabSessionQueryVariables
  >(GET_ACTIVE_LAB_SESSION, {
    fetchPolicy: "network-only"
  })

  const [labSessionsQueryFetch, labsSessionsQuery] = useLazyQuery<
    GetLabSessionsQuery,
    GetLabSessionsQueryVariables
  >(GET_LAB_SESSIONS, {
    fetchPolicy: "network-only"
  })

  const [districtIdsQueryFetch, districtIdsQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [connectToUserLabSession] = useMutation<
    ConnectToUserLabSessionMutation,
    ConnectToUserLabSessionMutationVariables
  >(CONNECT_TO_USER_LAB_SESSION)

  const [finishLabSession, {loading: finishLabSessionLoading}] = useMutation<
    FinishLabSessionMutation,
    FinishLabSessionMutationVariables
  >(FINISH_LAB_SESSION)

  const activeLabSession = useMemo(() => {
    return activeLabSessionQuery.data?.me?.activeLabSession || null
  }, [activeLabSessionQuery.data])

  const labSessionsError = useMemo(() => {
    return !labSessionsData && (labsSessionsQuery.error || null)
  }, [labsSessionsQuery.error, labSessionsData])

  const handleQuerySchoolIds = (value: string, tableState: DataTableState) => {
    const districtId = props.districtId || tableState.filters?.find(i => i.id === "districtId")?.value

    schoolIdsQueryFetch({
      variables: {
        districtIds: districtId ? [districtId] : undefined,
        search: value
      }
    }).then(res => {
      setSchoolIdsData(res.data?.schools?.items || null)
    })
  }

  const handleQueryDistrictIds = (value: string) => {
    districtIdsQueryFetch({
      variables: {
        search: value
      }
    }).then(res => {
      setDistrictIdsData(res.data?.districts?.items || null)
    })
  }

  const tableSchema: DataTableSchema<LabSession> = useMemo(() => {
    return [
      props.type !== "user" && {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        fieldName: "createdByUser.firstName"
      },
      props.type !== "user" && {
        type: "text",
        headerText: "Last Name",
        headerNoWrap: true,
        fieldName: "createdByUser.lastName"
      },
      {
        type: "custom",
        headerText: "Created At",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => formatDate(data.createdAt)
      },
      props.showFinishDate && {
        type: "custom",
        headerText: "Finished At",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => data.isFinished ? formatDate(data.finishedAt) : ""
      },
      {
        type: "custom",
        headerText: "Status",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          if (data.isFinished) {
            return "Closed"
          }
          if (data.instance.status === "running") {
            return "In Progress"
          }
          return capitalize(data.instance.status)
        }
      },
      {
        type: "custom",
        headerText: "Usage time",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: (data) => {
          return convertSecondsToTime(data?.progressOfUser?.totalTimeSpentInLabs)
        }
      },
      props.type !== "user" && {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        content: ({createdByUser}) => {
          return (
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
              <Link
                to={`${LINKS.studentsListPage}/${createdByUser._id}?tab=labs`}
                component={RouterLink}
                target="_blank"
                underline="always">
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={!!selectedSession || finishLabSessionLoading}
                  size="small">
                  All saved sessions
                </Button>
              </Link>
            </Box>
          )
        }
      },
      !props.prevOnly && {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        content: ({progressOfUserId, _id}) => {
          const isActiveSession = activeLabSession?.progressOfUser?._id === progressOfUserId

          return (
            (activeLabSessionQuery.loading || selectedSession === _id) ? (
              <Box display="flex" justifyContent="center">
                <LoadingButton
                  loading
                  variant="outlined"
                  color="secondary"
                  size="small">
                  View Student Lab
                </LoadingButton>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                {isActiveSession && (
                  <Button
                    disabled={finishLabSessionLoading}
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => handleFinishSession()}>
                    Close & Save Session
                  </Button>
                )}
                <Button
                  color={isActiveSession ? "success" : "secondary"}
                  variant={isActiveSession ? "contained" : "outlined"}
                  disabled={!!selectedSession || finishLabSessionLoading}
                  onClick={() => {
                    const path = LINKS[props.type === "admin" ? (
                      "adminStudentWpLabWindow"
                    ) : props.type === "district" ? (
                      "districtAdminStudentWpLabWindow"
                    ) : (
                      "studentWpLabWindow"
                    )]

                    if (isActiveSession) {
                      navigate(`${path}/${activeLabSession._id}`)
                    } else {
                      if (activeLabSession) {
                        setSessionToManage({
                          userId: progressOfUserId, id: _id
                        })
                        setShowModal(true)
                      } else {
                        handleConnectToUserLabSession(progressOfUserId, _id)
                      }
                    }
                  }}
                  size="small">
                  {isActiveSession ? "Join Active Session"  : "View Student Lab"}
                </Button>
              </Box>
            )
          )
        }
      }
    ]
  }, [
    labSessionsData,
    selectedSession,
    activeLabSession,
    activeLabSessionQuery.loading,
    props.type,
    props.showFinishDate
  ])

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      main: ["admin", "district"].includes(props.type) && [
        props.type !== "district" && {
          id: "districtId",
          type: "select-single",
          label: "District",
          loading: districtIdsQuery.loading,
          onUpdateOptions: handleQueryDistrictIds,
          options: [
            {label: "All districts", value: ""},
            ...(districtIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        },
        {
          id: "schoolId",
          type: "select-single",
          label: "School",
          loading: schoolIdsQuery.loading,
          onUpdateOptions: handleQuerySchoolIds,
          options: [
            {label: "All schools", value: ""},
            ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        }
      ]
    }
  }, [
    props.classroomId,
    props.schoolId,
    props.districtId,
    schoolIdsQuery.loading,
    districtIdsQuery.loading,
    districtIdsData,
    schoolIdsData
  ])

  const handleQuery: DataTableQueryUpdate = (state) => {
    setQuery(state)

    const {itemsPerPage, page, sort, filters, searchQuery} = state
    const classroomId = props.classroomId || filters?.find(i => i.id === "classroomId")?.value
    const schoolId = props.schoolId || filters?.find(i => i.id === "schoolId")?.value
    const districtId = props.districtId || filters?.find(i => i.id === "districtId")?.value

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      search: searchQuery,
      onlyLatest: props.latestOnly || false
    }

    labSessionsQueryFetch({
      fetchPolicy: "network-only",
      variables: {
        ...commonVars,
        districtId,
        schoolId,
        classroomId,
        userId: props.userId || null
      }
    }).then(res => {
      const data = res?.data?.labSessions
      let list = data.items

      if (props.prevOnly) {
        list.shift()
      }

      setLabSessionsData(data ? {
        total: data.total,
        hasMore: data.hasMore,
        items: list
      } : null)
    })
  }

  const handleFinishSession = async () => {
    await finishLabSession({
      variables: {
        labSessionId: activeLabSession?._id
      }
    }).then(() => {
      handleQuery(query)
      activeLabSessionQuery.refetch()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  const handleConnectToUserLabSession = (userId: string, id: string) => {
    setSelectedSession(id)
    connectToUserLabSession({
      variables: {userId}
    }).then(({data}) => {
      const path = LINKS[props.type === "admin" ? (
        "adminStudentWpLabWindow"
      ) : props.type === "district" ? (
        "districtAdminStudentWpLabWindow"
      ) : (
        "studentWpLabWindow"
      )]
      navigate(`${path}/${data.connectToUserLabSession._id}`)
    }).catch((err) => {
      activeLabSessionQuery.refetch()
      dispatch(handleError(err))
    }).finally(() => {
      setSelectedSession(null)
    })
  }

  const handleCancelModal = () => {
    setShowModal(false)
    setSessionToManage(null)
  }

  const handleConfirm = () => {
    handleFinishSession().then(() => {
      handleConnectToUserLabSession(sessionToManage.userId, sessionToManage.id)
      setShowModal(false)
      setSessionToManage(null)
    })
  }

  return (
    <Box minHeight="32vh">
      <DataTable
        schema={tableSchema}
        data={labSessionsData?.items}
        loading={labsSessionsQuery.loading || activeLabSessionQuery.loading}
        error={!!labSessionsError}
        itemsTotalCount={labSessionsData?.total}
        onQueryUpdate={handleQuery}
        lastPage={!labSessionsData?.hasMore}
        filters={tableFilters}
      />
      <ConfirmationAlert
        isOpen={showModal}
        setOpen={handleCancelModal}
        handleConfirm={handleConfirm}
        handleCancel={handleCancelModal}
        dialogTitle="Close Lab Session"
        dialogContentText="Are you sure you want to continue and close an active lab session?"
        dialogContentDynamic
        cancelText="No"
        confirmText={{color: "error", text: "Yes, Close & Save Session"}}
      />
    </Box>
  )
}
