import {gql} from "@apollo/client"

export default gql`
  query GetClassroomStudentsProgress(
    $classroomId: GraphQLObjectId!
    $examCode: String!
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 10 # No more than 10
  ) {
    getClassroom(classroomId: $classroomId) {
      sessionExamCodes {
        testing
        exam
      }
      students(
        sortBy: $sortBy
        order: $order
        offset: $offset
        take: $take
      ) {
        items {
          _id
          id: _id
          firstName
          lastName
          fullName
          email
          username
          totalTimeSpentInLabs
          studentProgress(examCode: $examCode) {
            examCode
            basedOnSessionsCount
            averageSessionScore
            progress {
              objective {
                _id
                createdAt
                updatedAt
                title
                examCode
                objectiveCode
              }
              answerRate
              successRate
              objectiveRate
              averageObjectiveScore
            }
          }
        }
        total
        hasMore
      }
    }
  }
`
