import {gql} from "@apollo/client"

export default gql`
  query GetExamPassingRate(
    $examCode: String!
    $schoolId: GraphQLObjectId
    $dateEnd: DateTimeISO
    $dateStart: DateTimeISO
  ) {
    getExamPassingRate(
      examCode: $examCode
      schoolId: $schoolId
      dateEnd: $dateEnd 
      dateStart: $dateStart
    ) {
      averagePassingRate
      totalPassedSessions
      totalPassedUsers
      totalSessions
      totalUsers
      usersPassedAfterAttempt1
      usersPassedAfterAttempt2
      usersPassedAfterAttempt3
    }
  }
`
