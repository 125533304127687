import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {Box, Button, Divider, Link, ListItem} from "@mui/material"
import SchoolIcon from "@mui/icons-material/School"
import PeopleIcon from "@mui/icons-material/People"
import EqualizerIcon from "@mui/icons-material/Equalizer"
import MapIcon from "@mui/icons-material/Map"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import ArticleIcon from "@mui/icons-material/Article"
import InventoryIcon from "@mui/icons-material/Inventory"
import LicenseLogsIcon from "@mui/icons-material/SyncAlt"
import ScienceIcon from "@mui/icons-material/Science"
import MoreTimeIcon from "@mui/icons-material/MoreTime"
import ReportIcon from "@mui/icons-material/Report"
import DataObjectIcon from "@mui/icons-material/DataObject"
import List from "@mui/material/List"
import {makeStyles} from "@mui/styles"
import {LINKS} from "consts/links"
import React from "react"
import {Link as RouterLink, matchPath, useLocation} from "react-router-dom"

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    width: "25%",
    height: "100%",
    background: "linear-gradient(#2192C0, #002955)",
    overflowY: "auto",
    padding: 16
  }
}))

const navList = [
  {label: "Districts", path: LINKS.admin, icon: <MapIcon/>},
  {label: "Schools", path: LINKS.adminSchools, icon: <SchoolIcon/>},
  {label: "Users", path: LINKS.adminUsers, icon: <PeopleIcon/>},
  {label: "Exam Results", path: LINKS.adminExamResults, icon: <EqualizerIcon/>},
  {label: "Exam Passing Rate", path: LINKS.adminExamPassingRate, icon: <TrendingUpIcon/>},
  {label: "Tasks", path: LINKS.adminTasks, icon: <AssignmentTurnedInIcon/>},
  {label: "Task Reports", path: LINKS.adminTaskReports, icon: <ReportIcon/>},
  {label: "Exams", path: LINKS.adminExams, icon: <ArticleIcon/>},
  {label: "Inventory", path: LINKS.adminInventory, icon: <InventoryIcon/>},
  {label: "License Inventory Logs", path: LINKS.adminLicenseLogs, icon: <LicenseLogsIcon/>},
  {label: "Personnel", path: LINKS.adminPersonnel, icon: <PeopleIcon/>},
  {label: "WP Labs", path: LINKS.adminLabList, icon: <ScienceIcon/>},
  {label: "Extended Time", path: LINKS.adminExtendedTime, icon: <MoreTimeIcon/>},
  {label: "CodeEditor Demo", path: `${LINKS.admin}/code-editor-demo`, icon: <DataObjectIcon/>}
]

export default function Sidebar() {
  const s = useStyles()
  const location = useLocation()

  return (
    <Box className={s.root}>
      <Box>
        <Box mb={1.5}>
          <Link to={LINKS.main} component={RouterLink} underline="none">
            <Button color={"white" as any} startIcon={<ArrowBackIcon/>} sx={{px: 2}}>
              Back
            </Button>
          </Link>
        </Box>
        <Divider/>
      </Box>
      <Box mt={2} mb={6}>
        <Box>
          <List>
            {navList.map(i => {
              const isActive = i.path ? !!matchPath({path: i.path}, location.pathname) : false

              return (
                <ListItem key={i.path} disableGutters sx={{display: "flex", py: 0}}>
                  <Button
                    component={RouterLink}
                    variant="text"
                    startIcon={i.icon}
                    to={i.path}
                    sx={{
                      color: "white.main",
                      fontWeight: "fontWeightMedium",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      py: "12px",
                      px: "16px",
                      textTransform: "none",
                      width: "100%",
                      ...(isActive && {
                        backgroundColor: "rgba(255, 255, 255, .05)",
                        color: "white.main",
                        textShadow: "0 0 1px white",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, .05)"
                        }
                      })
                    }}>
                    <Box flexGrow={1}>
                      {i.label}
                    </Box>
                  </Button>
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
