import {gql} from "@apollo/client"

export default gql`
  query GetSchoolStudentDetails(
    $schoolId: GraphQLObjectId!
    $classroomIds: [GraphQLObjectId]
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
    $search: String
  ) {
    users(
      schoolId: $schoolId
      classroomIds: $classroomIds
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
      search: $search
    ) {
      total
      hasMore
      items {
        _id
        assignedCourses {
          id
          title {
            raw
          }
        }
        disabilityMultiplierRequest {
          _id
          status
        }
        username
        studentId
        firstName
        lastName
        email
        cognitoUserId
        labsLicense {
          _id
          isActive
          expirationDate
        }
        disabilityMultiplier
        vouchers {
          _id
          expirationDate
          examCode
        }
      }
    }
  }
`
