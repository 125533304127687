import {gql} from "@apollo/client"

export default gql`
  query GetUserIds(
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
    $isStaffInDistrict: GraphQLObjectId 
    $isTeacherInSchool: GraphQLObjectId
    $isStaff: Boolean
  ) {
    users(
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
      isStaffInDistrict: $isStaffInDistrict
      isTeacherInSchool: $isTeacherInSchool
      isStaff: $isStaff
    ) {
      hasMore
      total
      items {
        _id
        cognitoUserId
        firstName
        fullName
        lastName
        studentId
        username
      }
    }
  }
`
