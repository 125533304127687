import {TestingGroupStatus} from "generated/graphql"

export const testingGroupStatusNames = {
  [TestingGroupStatus.Inprogress]: "Active",
  [TestingGroupStatus.Done]: "Closed",
  "archived": "Archived"
}

export default function getTestingGroupStatusName(input: string) {
  return testingGroupStatusNames[input]
}
