import {gql} from "@apollo/client"

export default gql`
  query GetDistrict($districtId: GraphQLObjectId!) {
    getDistrict(districtId: $districtId) {
      _id
      country
      createdAt
      name
      state
      logo
      schools {
        total
      }
      remainingLicenseSeats {
        coursesRemainTotal
        examsRemainTotal
        labsRemainTotal
        practicesRemainTotal
      }
    }
  }
`
