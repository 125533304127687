import {gql} from "@apollo/client"

export default gql`
  mutation CreateTeacher($userPayload: CreateUserPayload!) {
    createTeacher(userPayload: $userPayload) {
      _id
      email
    }
  }
`
