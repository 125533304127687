import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete"
import {useTheme} from "@mui/styles"
import {isEqual} from "lodash"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  FormHelperText,
  TextField
} from "@mui/material"

type AutocompleteDefaultProps<
  T = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>

interface Props {
  value?: string
  onChange?: (value: string) => void
  id: string
  label?: string
  icon?: JSX.Element
  options?: Array<{
    label?: string
    value: string
    disabled?: boolean
    icon?: string | JSX.Element
  }>
  noOptionsText?: string
  disabled?: boolean
  noFilterOptions?: boolean
  sx?: AutocompleteDefaultProps["sx"]
  fieldName?: string
  // inputValue?: string
  onFocus?: () => void
  onInputChange?: (value: string) => void
  onBlur?: (fieldName: string) => void
  touched?: boolean
  error?: null | boolean | string | string[]
  loading?: boolean
}

export default function SelectAutocomplete({ // TODO: update multiple behaviour
  id,
  label,
  icon: iconProp,
  options: optionsProp,
  noOptionsText,
  value: valueProp,
  noFilterOptions,
  disabled,
  sx,
  fieldName,
  onFocus,
  onInputChange,
  onChange,
  onBlur,
  touched,
  error,
  loading: loadingProp
}: Props) {
  const theme = useTheme()

  const [popupMinWidth, setPopupMinWidth] = useState(200)
  const [options, setOptions] = useState<typeof optionsProp>(optionsProp || [])
  const [inputValue, setInputValue] = useState<{
    value: string
    reason: AutocompleteInputChangeReason | null
  }>({
    value: "",
    reason: null
  })
  const [value, setValue] = useState<Props["options"][0]>({value: valueProp} || null)

  const rootRef = useRef<HTMLDivElement>(null)

  const loading = useMemo(() => {
    return loadingProp && (!options || options.length === 0 || (options.length === 1 && !options[0].value))
  }, [loadingProp, options])

  const icon = useMemo(() => {
    if (iconProp) {
      return React.cloneElement(iconProp, {
        fontSize: "small",
        sx: {
          "& *": {
            fill: theme.palette.action.active
          }
        },
        style: {
          position: "absolute",
          transform: "translateY(-50%)",
          top: "50%",
          left: 12,
          pointerEvents: "none"
        }
      })
    } else {
      return null
    }
  }, [iconProp, theme])

  useEffect(() => {
    if (!loadingProp) {
      setOptions(optionsProp)
    }
  }, [loadingProp, optionsProp])

  useEffect(() => {
    if (onInputChange) {
      onInputChange(inputValue.value)
    }
  }, [inputValue.value])

  useEffect(() => {
    if (rootRef.current?.children?.[0]) {
      setPopupMinWidth(rootRef.current.children[0].clientWidth)
    }
  }, [rootRef])

  useEffect(() => {
    handleChangeValueFromProp(valueProp)
  }, [valueProp])

  useEffect(() => {
    if (onChange) {
      function changeHandler<T>(value: T) {
        if (!isEqual(valueProp, value)) {
          (onChange as unknown as (value: T) => void)(value)
        }
      }

      if (Array.isArray(value)) {
        changeHandler<string[]>(value.map(i => typeof i === "string" ? i : i.value))
      } else {
        changeHandler<string>(typeof value === "string" ? value : (value?.value || ""))
      }
    }
  }, [value])

  const handleInputChange = (value: string, reason: AutocompleteInputChangeReason) => {
    if (value !== inputValue.value) {
      setInputValue({
        value,
        reason
      })
    }
  }

  const handleChangeValueFromProp = (valueFromProp: typeof valueProp) => {
    let newValue: typeof value

    newValue = options.find(i => (
      !!i.value && i.value === valueFromProp
    )) || null

    if (!isEqual(value, newValue)) {
      setValue(newValue)
    }
  }

  const handleChange = (selected: Props["options"][0]) => {
    setValue(selected)
  }

  return (
    <Box position="relative">
      <Autocomplete
        id={`select-${id}`}
        ref={rootRef}
        blurOnSelect
        autoHighlight
        sx={sx}
        componentsProps={{
          popper: {
            style: {
              width: "fit-content",
              minWidth: popupMinWidth
            }
          }
        }}
        disabled={disabled}
        loading={loading}
        inputValue={inputValue.value}
        value={value}
        onChange={(e, newValue) => handleChange(newValue)}
        onInputChange={(e, newValue, reason) => handleInputChange(newValue, reason)}
        onFocus={onFocus}
        onBlur={onBlur ? () => onBlur(fieldName) : undefined}
        options={options}
        filterOptions={noFilterOptions ? i => i : undefined}
        isOptionEqualToValue={(option, v) => option.value === v.value}
        getOptionLabel={(option: Props["options"][0]) => option.label || ""}
        getOptionDisabled={option => !!option.disabled}
        noOptionsText={noOptionsText}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.value}>
            {!!option.icon && (
              <Box width={20} height={20} display="flex" alignItems="center" justifyContent="center" mr={1}>
                {typeof option.icon === "string" ? (
                  <img
                    loading="lazy"
                    width="100%"
                    src={option.icon}
                    alt={option.label}
                  />
                ) : (
                  option.icon
                )}
              </Box>
            )}
            {option.label}
          </Box>
        )}
        renderInput={params => (
          <Box position="relative">
            <TextField
              {...params}
              label={label || "Select"}
              error={!!(touched && error)}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: icon ? {
                  transition: ".15s",
                  "&:not(.Mui-focused):not(.MuiFormLabel-filled)": {
                    pl: "26px",
                    transition: ".15s"
                  }
                } : undefined
              }}
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
                style: icon ? {
                  paddingLeft: "32px"
                } : undefined,
                endAdornment: (
                  <>
                    {!!loading && <CircularProgress color="inherit" size={20}/>}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
            {icon}
          </Box>
        )}
        renderTags={tags => (
          <Box pl={0.5}>
            {!tags ? null : tags.length > 1 ? (
              `${tags.length} selected`
            ) : tags[0].label.length <= 9 ? (
              tags[0].label
            ) : (
              `${tags[0].label.slice(0, 8)}...`
            )}
          </Box>
        )}
      />
      {!!(touched && error) && (
        <FormHelperText
          error
          sx={{
            position: "absolute",
            width: "fit-content",
            maxWidth: "100%",
            bottom: "-8px",
            px: "4px",
            ml: "8px",
            mr: "8px",
            background: "white"
          }}>
          {typeof error === "string" ? (
            error
          ) : typeof error === "object" ? (
            error[0]
          ) : null}
        </FormHelperText>
      )}
    </Box>
  )
}
