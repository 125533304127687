import {gql} from "@apollo/client"

export default gql`
  query GetAdministratedDistricts(
    $sortBy: String
    $order: SortOrder
    $search: String
    $offset: Int = 0
    $take: Int = 100
  ) {
    getAdministratedDistricts(
      sortBy: $sortBy
      search: $search
      offset: $offset
      order: $order
      take: $take
    ) {
      hasMore
      items {
        _id
        name
        logo
        schools {
          total
        }
      }
      total
    }
  }
`
