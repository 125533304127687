import {gql} from "@apollo/client"

export default gql`
  query GetTaskHelp($taskId: GraphQLObjectId!, $sessionId: GraphQLObjectId!) {
    getTaskHelp(taskId: $taskId, sessionId: $sessionId) {
      markdown
      rendered
    }
  }
`
