import {gql} from "@apollo/client"

export default gql`
  query GetSchoolTeachersIds(
    $schoolId: GraphQLObjectId!
    $offset: Int = 0
    $take: Int = 100
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    getSchool(schoolId: $schoolId) {
      teachers(
        take: $take
        offset: $offset
        sortBy: $sortBy
        order: $order
        search: $search
      ) {
        total
        hasMore
        items {
          _id
          firstName
          lastName
        }
      }
    }
  }
`
