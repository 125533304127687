import {gql} from "@apollo/client"

export default gql`
  query GetUserLabDetails {
    me {
      _id
      activeLabSession {
        _id
        isConnection
        createdAt
        createdByUser {
          _id
          username
        }
        progressOfUser {
          _id
          username
        }
        progressChannel
        progressFound
      }
      totalTimeSpentInLabs
      latestFinishedLabSession {
        _id
        createdAt
      }
      isLabLicenseActive
      labsLicenseId
      labsLicense {
        _id
        labsRemain
        labsInitial
        expirationDate
      }
    }
  }
`
