import {gql} from "@apollo/client"

export default gql`
  mutation ReportTask(
    $reportInput: TaskReportInput
    $taskId: GraphQLObjectId
  ) {
    reportTask(
      reportInput: $reportInput
      taskId: $taskId
    ) {
      _id
    }
  }
`
