import {useMutation, useQuery} from "@apollo/client"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import {Backdrop, Box, CircularProgress, Grid, Theme, Typography} from "@mui/material"
import Button from "@mui/material/Button"
import {useTheme} from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"
import EXTEND_LAB_SESSIONS_TIME from "api/apollo/mutations/EXTEND_LAB_SESSIONS_TIME"
import FINISH_LAB_SESSION from "api/apollo/mutations/FINISH_LAB_SESSION"
import GET_LAB_SESSION from "api/apollo/queries/GET_LAB_SESSION"
import Dialog from "components/Dialog"
import {LINKS} from "consts/links"
import {
  ExtendLabSessionTimeMutation, ExtendLabSessionTimeMutationVariables,
  FinishLabSessionMutation, FinishLabSessionMutationVariables,
  GetLabSessionQuery,
  GetLabSessionQueryVariables
} from "generated/graphql"
import SnackbarCredentials from "components/SnackbarCredentials"
import WordpressWorkspace from "components/WordpressWorkspace"
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {FullScreen, useFullScreenHandle} from "react-full-screen"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import {useIdleTimer} from "react-idle-timer"
import {
  TaskHeader,
  TaskFooterFirstBar
} from "@knowledge-pillars-education-inc/kp-fe-lib"
import CustomErrorClass from "store/slices/notifier/customErrorClass"
import {customErrors} from "store/slices/notifier/errorObject"
import {handleError} from "store/slices/notifier/notifier"
import {useExamWindowStyles} from "./PracticeExamWindow/examWindowStyles"

const footerHeight = "46px"

const timeout = 12e5

const useStyles = makeStyles<Theme>((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#ffff",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column"
  },
  workspaceLayout: {
    position: "relative",
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: theme.typography.fontFamily
  },
  footer: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    background: "white",
    zIndex: 1,
    minWidth: "980px"
  },
  line: {
    height: "100%",
    background: theme.palette.grey[200]
  }
}))

type ModalType = "CLOSE_LAB" | "EXTEND_LAB_SESSION_TIME" | "LOW_ACTIVITY"

interface Props {
  isStudent?: boolean
  navigatePath?: string
}

export default function WPLabWindow(props: Props) {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const s = useStyles()

  const fullScreenHandle = useFullScreenHandle()

  const [timer, setTimer] = useState(1000)
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [openHint, setOpenHint] = useState(false)
  const [iframeWasLoaded, setIframeWasLoaded] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [expirationDate, setExpirationDate] = useState(null)
  const [isExpired, setIsExpired] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [remainingTime, setRemainingTime] = useState({
    minutes: Math.floor((timeout / 1000 / 60) % 60),
    seconds: Math.floor((timeout / 1000) % 60)
  })
  const [showLib, setShowLib] = useState(true)

  const examWindowStyles = useExamWindowStyles({
    showLib,
    showSidebar: false
  })

  const {data, error, stopPolling} = useQuery<
    GetLabSessionQuery,
    GetLabSessionQueryVariables
  >(GET_LAB_SESSION, {
    variables: {
      labSessionId: params.labSessionId
    },
    pollInterval: 2000
  })

  const [finishLabSession] = useMutation<
    FinishLabSessionMutation,
    FinishLabSessionMutationVariables
  >(FINISH_LAB_SESSION)

  const [extendLabSessionTimeMutation] = useMutation<
    ExtendLabSessionTimeMutation,
    ExtendLabSessionTimeMutationVariables
  >(EXTEND_LAB_SESSIONS_TIME)

  const modalTitle = useMemo(() => {
    switch (modalType) {
      case "CLOSE_LAB":
        return "Save Progress And Close"
      case "EXTEND_LAB_SESSION_TIME":
        return "Extend the time"
      case "LOW_ACTIVITY":
        return "Attention!"
      default:
        return null
    }
  }, [modalType])

  const isInstanceReady = useMemo(() => {
    return data?.getLabSession?.instance?.status === "running" || false
  }, [data])

  const instance = useMemo(() => {
    return data?.getLabSession?.instance || null
  }, [data])

  const onIdle = () => {
    handleFinishLabSession()
  }

  const onActive = () => {
    setModalType(null)
  }

  const onPrompt = () => {
    setModalType("LOW_ACTIVITY")
    setOpenHint(false)
  }

  const onAction = () => {
    if (showOverlay) {
      setShowOverlay(false)
    }
  }

  const {activate, getRemainingTime} = useIdleTimer({
    onIdle,
    onAction,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle: 3e5,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime({
        minutes: Math.floor((getRemainingTime() / 1000 / 60) % 60),
        seconds: Math.floor((getRemainingTime() / 1000) % 60)
      })
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (!isExpired) {
      if (timer < 100) {
        setModalType("EXTEND_LAB_SESSION_TIME")
      }
      if (timer <= -300000) {
        handleFinishLabSession()
        setIsExpired(true)
      }
    }
  }, [timer, isExpired])

  useEffect(() => {
    if (isInstanceReady) {
      stopPolling()
    }
  }, [isInstanceReady])

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [])

  useEffect(() => {
    if (error || ["deleted", "failed"].includes(instance?.status) || data?.getLabSession?.isFinished) {
      dispatch(handleError(error || new CustomErrorClass(customErrors.INSTANCE_UNAVAILABLE)))
      navigate(props.navigatePath || LINKS.dashboard)
    }
  }, [error, instance, data])

  const onFullButtonClick = useCallback(() => {
    if (fullScreenHandle.active) {
      setIsFullScreen(false)
      return fullScreenHandle.exit().catch(console.warn)
    }

    setIsFullScreen(true)

    fullScreenHandle.enter().catch(console.warn)
  }, [fullScreenHandle])

  async function handleFinishLabSession() {
    await finishLabSession({
      variables: {
        labSessionId: params.labSessionId
      }
    })
    navigate(props.navigatePath || LINKS.dashboard)
  }

  const handleClose = async (closeType: string) => {
    if (closeType === "confirm") {
      await handleFinishLabSession()
      return
    }

    if (closeType === "continue") {
      setModalType(null)
      return
    }

    if (closeType === "extendLabSessionTime") {
      await extendLabSessionTimeMutation({
        variables: {
          labSessionId: params.labSessionId
        }
      }).then(res => {
        setExpirationDate(res.data.extendLabSessionTime.expirationDate)
        setModalType(null)
      }).catch(err => {
        dispatch(handleError(err))
        handleFinishLabSession()
      })
    }
  }

  useEffect(() => {
    if ((remainingTime.minutes < 20) &&
      (remainingTime.minutes % 5 === 0) &&
      !showOverlay
    ) {
      setShowOverlay(true)
    }
  }, [remainingTime])

  return (
    <>
      <Helmet>
        <title>
          {props.isStudent ? "WordPress Lab" : "Student WordPress Lab"}
        </title>
      </Helmet>
      <FullScreen
        handle={fullScreenHandle}
        className={examWindowStyles.root}
      >
        <Box
          sx={{
            position: "fixed",
            top: "0",
            zIndex: 2,
            overflow: "hidden",
            width: "100%",
            height: "auto",
            maxHeight: showLib ? "90px" : 0,
            transition: "all .35s ease-out",
            "& > div": {
              position: "relative"
            }
          }}
        >
          <TaskHeader
            examName="WordPress Lab"
            finishButtonTitle="Save Progress And Close"
            studentName={data?.getLabSession?.createdByUser?.username}
            sessionId={params?.labSessionId}
            deadline={expirationDate || data?.getLabSession?.expirationDate}
            setTimer={setTimer}
            loading={!isInstanceReady}
            setType={() => setModalType("CLOSE_LAB")}
            logo="/static/kp_logo.png"
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            minHeight: "20%",
            maxHeight: showLib ? `calc(100% - ${footerHeight})` : "100%",
            height: "100%",
            transition: "all 0.35s ease-out"
          }}
        >
          {instance && !modalType && openHint && (
            <SnackbarCredentials
              open={openHint}
              handleClose={() => setOpenHint(false)}
              username={instance?.username}
              password={instance?.password}
            />
          )}
          <Grid
            item
            xs={12}
            className={s.workspaceLayout}>
            {!isInstanceReady ? (
              <Backdrop open className={s.backdrop}>
                <Typography variant="h6" align="center" mb={4}>
                  We are currently preparing your test environment and it may take
                  few minutes. <br/>
                  <b>Please don't refresh or close the tab.</b>
                </Typography>
                <CircularProgress color="inherit"/>
              </Backdrop>
            ) : (
              <WordpressWorkspace
                setOpenHint={setOpenHint}
                iframeWasLoaded={iframeWasLoaded}
                setIframeWasLoaded={setIframeWasLoaded}
                instance={instance}
              />
            )}
          </Grid>
          <Box
            position="absolute"
            right={20}
            bottom={15}
            zIndex={10}
            sx={{
              transition: ".2s",
              opacity: .5,
              "&:hover": {
                opacity: 1,
                transition: ".2s"
              }
            }}
          >
            <Button
              onClick={() => setShowLib(!showLib)}
              sx={{
                transition: ".2s",
                boxShadow: 1,
                minWidth: "auto",
                "&:hover": {
                  background: theme.palette.white.main,
                  transition: ".2s"
                }
              }}
            >
              {showLib ? (
                <OpenInFullIcon fontSize="small"/>
              ) : (
                <CloseFullscreenIcon fontSize="small"/>
              )}
            </Button>
          </Box>
        </Box>
        <Grid
          item
          xs={12}
          className={s.footer}
          sx={{
            height: !showLib ? 0 : footerHeight,
            overflow: "hidden",
            transition: "all 0.35s ease-out"
          }}
        >
          <Box className={s.line}/>
          <TaskFooterFirstBar
            setOpenHint={setOpenHint}
            onFullButtonClick={onFullButtonClick}
            isFullScreen={isFullScreen}
            isWordpressExam
          />
        </Grid>
        <Dialog
          open={modalType === "CLOSE_LAB"}
          title={modalTitle}
          actions={[{
            label: "No, return to lab",
            color: "error",
            onClick: () => handleClose("continue")
          }, {
            label: "Yes",
            color: "success",
            onClick: () => handleClose("confirm")
          }]}>
          <Box sx={{width: "auto", pt: 1}}>
            <Typography variant="subtitle2">
              All your current progress will be securely saved, allowing you to resume your work at a later time. Would you like to proceed with closing the lab?
            </Typography>
          </Box>
        </Dialog>
        <Dialog
          open={modalType === "EXTEND_LAB_SESSION_TIME"}
          title={modalTitle}
          actions={[{
            label: "No, close the lab",
            color: "error",
            onClick: () => handleClose("confirm")
          }, {
            label: "Yes",
            color: "success",
            onClick: () => handleClose("extendLabSessionTime")
          }]}>
          <Box sx={{width: "auto", pt: 1}}>
            <Typography variant="subtitle2" align="center">
              Do you want to extend the time?
            </Typography>
          </Box>
        </Dialog>
        <Dialog
          open={modalType === "LOW_ACTIVITY"}
          title={modalTitle}
          actions={[{
            label: "Close Lab",
            color: "error",
            onClick: () => handleClose("confirm")
          },
            {
              label: "Keep Working",
              color: "success",
              onClick: () => activate()
            }]}>
          <Box sx={{width: "auto", pt: 1}}>
            <Typography variant="h6" align="center" mb={1} color="error">
              0{remainingTime.minutes}:{remainingTime.seconds < 10 && "0"}{remainingTime.seconds}
            </Typography>
            <Typography variant="subtitle2">
              We've noticed you haven't been active in the WordPress lab for past minutes. To manage our lab resources efficiently, we need to check if you're still there.
              Please click on the 'Keep Working' button within the next 5 minutes to continue your session.
              If no action is taken, your current lab session will be automatically closed to free up resources for other students.
            </Typography>
          </Box>
        </Dialog>
        <Box
          top={0}
          left={0}
          position="absolute"
          width="100%"
          height="100%"
          sx={{pointerEvents: showOverlay ? "auto" : "none"}}
        />
      </FullScreen>
    </>
  )
}
