import {gql} from "@apollo/client"

export default gql`
  query GetExamSessions(
    $districtId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $userId: GraphQLObjectId
    $classroomId: GraphQLObjectId
    $take: Int = 100
    $offset: Int = 0
    $sortBy: String
    $order: SortOrder
    $search: String
    $testingGroupId: GraphQLObjectId
    $voucherUsed: Boolean
    $examCodes: [String!]
    $dateStart: DateTimeISO
    $dateEnd: DateTimeISO
    $showCanceled: Boolean
    $isPassed: Boolean
  ) {
    examSessions(
      districtId: $districtId
      schoolId: $schoolId
      classroomId: $classroomId
      userId: $userId
      take: $take
      offset: $offset
      sortBy: $sortBy
      order: $order
      search: $search
      testingGroupId: $testingGroupId
      voucherUsed: $voucherUsed
      examCodes: $examCodes
      dateStart: $dateStart
      dateEnd: $dateEnd
      showCanceled: $showCanceled
      isPassed: $isPassed
    ) {
      hasMore
      total
      items {
        _id
        _score
        createdAt
        startDate
        updatedAt
        finishDate
        examCode
        examus {
          report {
            archive
          }
        }
        canceledBy {
          fullName
        }
        certificateId
        consideredAsRetake
        cancelReason
        testingGroup {
          proctor {
            _id
            fullName
          }
          status
        }
        progress {
          averageObjectiveScore
          objective {
            _id
            title
          }
        }
        isPassed
        score
        status
        user {
          _id
          firstName
          lastName
        }
        voucherCode
      }
    }
  }
`
