import {useLazyQuery} from "@apollo/client"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import GET_CODING_TASK_LIVE_EVALUATION from "api/apollo/queries/GET_CODING_TASK_LIVE_EVALUATION"
import GET_TASK_HELP from "api/apollo/queries/GET_TASK_HELP"
import {MD} from "@knowledge-pillars-education-inc/kp-fe-lib"
import SpeechSynthesis from "components/SpeechSynthesis"
import {
  GetCodingTaskLiveEvaluationQuery,
  GetCodingTaskLiveEvaluationQueryVariables,
  GetTaskHelpQuery,
  GetTaskHelpQueryVariables,
  LiveEvaluationResult,
  MarkdownItem
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"
import {Box, Collapse, Divider, IconButton, LinearProgress, Typography, useTheme} from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import FlagIcon from "@mui/icons-material/Flag"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {selectTaskDetails} from "store/selectors"
import {handleError} from "store/slices/notifier/notifier"
import {
  addFlaggedTaskThunk,
  getSessionTasksStatus,
  removeFlaggedTaskThunk
} from "store/slices/practiceSession/practiceSession"
import formatDate from "utils/formatDate"
import Timer from "./components/Timer"
import Highlight from "./components/Highlight"

interface Props {
  onSwitchTask: (value: "next" | "prev") => void
  isWpExam: boolean
  setTimer: (value: number) => void
  currentTaskNumber: number
  numberOfTasks: number
  setOpenHint: (state: (value: boolean) => boolean) => void
  setPopupType: (value: string) => void
  setOpenReport: (value: boolean) => void
  onSubmit: () => void
  showDescription: boolean
  onFullScreen: () => void
  codeAnswer: string | null
}

export default function Sidebar({
  onSwitchTask,
  isWpExam,
  setTimer,
  currentTaskNumber,
  numberOfTasks,
  setOpenHint,
  setPopupType,
  setOpenReport,
  onSubmit,
  showDescription,
  onFullScreen,
  codeAnswer
}: Props) {
  const params = useParams()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [expandedFooter, setExpandedFooter] = useState(true)
  const [taskHelp, setTaskHelp] = useState<MarkdownItem | null>(null)
  const [codingTaskLiveEvaluations, setCodingTaskLiveEvaluations] = useState<
    LiveEvaluationResult[]
  >([])
  const [disabledGuideButton, setDisabledGuideButton] = useState(false)

  const [taskHelpQueryFetch, taskHelpQuery] = useLazyQuery<
    GetTaskHelpQuery,
    GetTaskHelpQueryVariables
  >(GET_TASK_HELP)

  const [codingTaskLiveEvaluationQueryFetch, codingTaskLiveEvaluationQuery] = useLazyQuery<
    GetCodingTaskLiveEvaluationQuery,
    GetCodingTaskLiveEvaluationQueryVariables
  >(GET_CODING_TASK_LIVE_EVALUATION)

  const {sessionTasksStatus, currentTask, loading: practiceSessionLoading} = useSelector(state => state.practiceSession)
  const currentSession = useSelector(state => state.practiceSession.currentSession || {})
  const {type} = useSelector(selectTaskDetails)

  const {sessionId, taskId} = useMemo(() => params || {}, [params])

  const isTaskFlagged = useMemo(() => {
    return sessionTasksStatus?.some(i => i.taskId === taskId && i.flag)
  }, [sessionTasksStatus, taskId])

  const isPractical = useMemo(() => {
    return type === "practical"
  }, [type])

  const loading = useMemo(() => {
    return practiceSessionLoading || taskHelpQuery.loading || codingTaskLiveEvaluationQuery.loading || false
  }, [practiceSessionLoading, taskHelpQuery.loading, codingTaskLiveEvaluationQuery.loading])

  const handleQueryTaskHelp = () => {
    taskHelpQueryFetch({
      variables: {
        taskId,
        sessionId
      }
    })
    .then((res) => {
      const data = res.data?.getTaskHelp
      setTaskHelp(data || null)
      !isPractical && setDisabledGuideButton(true)
    })
    .catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleFlagClick = async () => {
    if (isTaskFlagged) {
      await dispatch(removeFlaggedTaskThunk({taskId, sessionId}))
    } else {
      await dispatch(addFlaggedTaskThunk({taskId, sessionId}))
    }

    await dispatch(getSessionTasksStatus(sessionId))
  }

  const handleQueryCodingTaskLiveEvaluation = () => {
    codingTaskLiveEvaluationQueryFetch({
      variables: {
        taskId,
        sessionId,
        sourceCode: codeAnswer
      },
      fetchPolicy: "network-only"
    }).then(res => {
      const data = res.data.getCodingTaskLiveEvaluation
      if (data) {
        setCodingTaskLiveEvaluations((prev) => [...prev, data] as any)
        !data.canEvaluateAgain && setDisabledGuideButton(true)
        const id = `evaluation-${codingTaskLiveEvaluations.length + 1}`
        setTimeout(() => {
          const el = document.getElementById(id)

          el.scrollIntoView({behavior: "smooth"})
        }, 100)
      }
    })
  }

  useEffect(() => {
    setTaskHelp(null)
    setDisabledGuideButton(false)
    setCodingTaskLiveEvaluations([])
  }, [currentTaskNumber])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        gap={1}
        p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}>
          <Box display="flex" width={28}>
            <img
              alt="Logo"
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%"
              }}
              src="/static/kp_logo.png"
            />
          </Box>
          <Box flex={1} minWidth={0} textAlign="center">
            <Typography fontWeight="bold" color="textPrimary">
              {currentSession.examName}
            </Typography>
          </Box>
          <IconButton color="error" onClick={() => setPopupType("finishExamFirst")}>
            <LogoutIcon/>
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center">
          <Timer onChange={setTimer} deadline={currentSession.expirationDate}/>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography color="textPrimary" mb={.5}>
            Student Name: <span style={{fontWeight: 500}}>{currentSession.userName}</span>
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Session: <span style={{fontWeight: 500}}>{sessionId}</span>
          </Typography>
        </Box>
      </Box>
      <Box position="relative">
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1} pt={0}>
          <IconButton
            color="primary"
            onClick={() => onSwitchTask("prev")}
            disabled={loading || currentTaskNumber === 1}>
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography>
            Task {currentTaskNumber} of {numberOfTasks}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => onSwitchTask("next")}
            disabled={loading || currentTaskNumber === numberOfTasks}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Box>
        <Divider/>
        <Box
          position="absolute"
          left={0}
          width="100%"
          sx={{
            transform: "translateY(-50%)"
          }}>
          {loading && <LinearProgress sx={{borderRadius: 0}}/>}
        </Box>
      </Box>
      <Box flex="auto" sx={{overflowY: "auto"}} bgcolor="white.main">
        <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2} p={2} pb={5}>
          {showDescription &&
            <Box fontFamily={theme.typography.fontFamily}>
              <MD value={currentTask?.task?.description}/>
            </Box>
          }
          {taskHelp && (
            <Box>
              <Typography variant="h6" color="textPrimary" mb={2} textAlign="center">
                Guide
              </Typography>
              <Box>
                <Highlight markdown={taskHelp.markdown}/>
              </Box>
            </Box>
          )}
          {!!codingTaskLiveEvaluations?.length && (
            <Box>
              <Typography variant="h6" color="textPrimary" mb={2} textAlign="center">
                AI Tutor
              </Typography>
              {codingTaskLiveEvaluations.map((i, num) => (
                <Box key={num} id={`evaluation-${num + 1}`}>
                  <Typography variant="subtitle1" color="textPrimary" mb={2} fontWeight="500">
                    Suggestion {num + 1} - {formatDate(i.result.createdAt, "full-en")}
                  </Typography>
                  <Highlight markdown={i.result.response.markdown}/>
                  <SpeechSynthesis text={i.result.response.markdown}/>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Divider/>
      <Box position="relative" p={2} bgcolor="background.paper">
        <Box
          position="absolute"
          borderRadius="100%"
          sx={{
            left: "50%",
            transform: "translateX(-50%)",
            top: -20,
            bgcolor: theme.palette.background.paper
          }}>
          <IconButton sx={{border: "1px solid #DCDCDC"}} onClick={() => setExpandedFooter(!expandedFooter)}>
            <ExpandMoreIcon sx={{
              transform: expandedFooter ? "rotate(0deg)" : "rotate(180deg)",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
              })
            }}/>
          </IconButton>
        </Box>
        <Collapse in={expandedFooter}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}>
            {isWpExam && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpenHint((value) => !value)}>
                Login Credentials
              </Button>
            )}
            <Button variant="outlined" size="small" onClick={() => setOpenReport(true)}>
              Report Task
            </Button>
            <Box display="flex" alignItems="center">
              <IconButton
                color={isTaskFlagged ? "error" : undefined}
                onClick={handleFlagClick}
                disabled={loading}>
                <FlagIcon/>
              </IconButton>
              <IconButton color="primary" onClick={onFullScreen}>
                <FullscreenIcon/>
              </IconButton>
            </Box>
          </Box>
        </Collapse>
        <Box
          display="flex"
          width="100%"
          justifyContent={currentSession?.options?.disableAi ? "flex-end" : "space-between"}
          alignItems="center"
          mt={1}>
          {!currentSession?.options?.disableAi && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => !taskHelp ? handleQueryTaskHelp() : handleQueryCodingTaskLiveEvaluation()}
              disabled={
                loading ||
                disabledGuideButton ||
                (taskHelp && !codeAnswer)
              }>
              {isPractical && taskHelp ? (
                codingTaskLiveEvaluations.length ?
                  "Ask AI for More Help"
                  :
                  "Ask AI for Help"
              ) : "Guide Me"}
            </Button>
          )}
          <Button
            variant="contained"
            disabled={loading}
            onClick={onSubmit}>
            Submit Task
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
