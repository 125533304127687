export const ENDPOINT = {
  program: "/program",
  exam: "/exam",
  examStudentAccess: "/exam/student-access-to-run-exam/?examCode=",
  examByProgramId: "/exam?programId=",
  userExamsScrore: "/user/exam-result-list",
  exams: "/exams",
  programs: "/programs",
  examSession: "/exam-session",
  examsSessionInit: "/exam-session/initiate",
  voucher: "/voucher",
  organization: "/organization",
  license: "/license",
  group: "organization/exam-group",
  currentGroup: "/exam-group",
  user: "/user",
  userRole: "/user/role",
  usersByRole: "/user/exam-group-info",
  payment: "/pay-off",
  validatePaymentVoucher: "/voucher/validate-voucher/?",
  notification: "/notification",
  sme: "/sme/",
  smeExamQuestions: "/sme/",
  smeTask: "/sme/task/",
  taskEvaluationScore: "/smeTasks/score",
  taskEvaluationReview: "/smeTasks/review",
  practiceVoucher: "/testing/vouchers",
  redeemVoucher: "/voucher/redeem",
  getAvailableExamCodes: "/testing/exams/codes",
  getExamCodesHaveSessions: "/testing/exams-sessions/codes",
  getExamCodesHavePracticeSessions: "/testing/sessions/codes",
  createNewSession: "/testing/sessions",
  getObjectives: "/objectives",
  getTask: "/testing/sessions",
  finishSessionExam: "/testing/sessions",
  getPracticeSessionsBySchool: "/testing/sessions/school",
  testingGroups: "/testing-groups",
  students: "/users",
  batchStudents: "/users/batch",
  batchStudentsToClass: "/users/batch/classroom",
  users: "/users",
  registerAsProctor: "/users/me/proctor",
  fetchUserInfo: "/users/me",
  updateUserInfo: "/users/me",
  authAsProctor: "/auth/proctor",
  assignPracticeTests: "/testing/vouchers/students",
  fetchLicensesByExamCode: "/license/school",
  classrooms: "/classrooms"
}
