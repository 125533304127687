import {useLazyQuery} from "@apollo/client"
import {Box, Button} from "@mui/material"
import GET_CLASSROOM_IDS from "api/apollo/queries/GET_CLASSROOM_IDS"
import GET_DISABILITY_MULTIPLIER_REQUESTS from "api/apollo/queries/GET_DISABILITY_MULTIPLIER_REQUESTS"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableFilters,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import ViewRequestsForm from "components/ExtendedSessionTimeTable/components/ViewRequestsForm"
import {
  Classroom,
  DisabilityMultiplierRequest,
  District,
  GetClassroomIdsQuery,
  GetClassroomIdsQueryVariables,
  GetDisabilityMultiplierRequestsQuery,
  GetDisabilityMultiplierRequestsQueryVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  School
} from "generated/graphql"
import JsFileDownloader from "js-file-downloader"
import React, {useMemo, useState} from "react"
import {QueryDataType} from "types/typeUtils"
import convertSecondsToTime from "utils/convertSecondToTime"
import formatDate from "utils/formatDate"

type Props = {
  schoolId?: string
  districtId?: string
  classroomId?: string
  userId?: string
  userType?: "admin"
}

const SESSION_DURATION = 2700

export default function ExtendedSessionTimeTable(props: Props) {
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [showModal, setShowModal] = useState(false)

  const [requestsData, setRequestsData] = useState<QueryDataType<DeepPartial<DisabilityMultiplierRequest>>>(null)
  const [districtIdsData, setDistrictIdsData] = useState<
    Partial<District>[] | null
  >(null)
  const [schoolIdsData, setSchoolIdsData] = useState<
    Partial<School>[] | null
  >(null)
  const [classroomIdsData, setClassroomIdsData] = useState<
    Partial<Classroom>[]
  >([])

  const [disabilityMultiplierRequestsQueryFetch, disabilityMultiplierRequestsQuery] = useLazyQuery<
    GetDisabilityMultiplierRequestsQuery,
    GetDisabilityMultiplierRequestsQueryVariables
  >(GET_DISABILITY_MULTIPLIER_REQUESTS)

  const [districtIdsQueryFetch, districtIdsQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] =  useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [classroomIdsQueryFetch, classroomIdsQuery] = useLazyQuery<
    GetClassroomIdsQuery,
    GetClassroomIdsQueryVariables
  >(GET_CLASSROOM_IDS)

  const requestsLoading = useMemo(() => {
    return disabilityMultiplierRequestsQuery.loading || false
  }, [disabilityMultiplierRequestsQuery.loading])

  const requestsError = useMemo(() => {
    return !requestsData && (
      disabilityMultiplierRequestsQuery.error ||
      null
    )
  }, [
    requestsData,
    disabilityMultiplierRequestsQuery.error
  ])

  const tableSchema: DataTableSchema<DisabilityMultiplierRequest> = useMemo(() => {
    return [
      !props.userId && {
        type: "text",
        headerText: "Full Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "targetUser.fullName"
      },
      {
        type: "text",
        headerText: "District",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "district.name"
      },
      {
        type: "text",
        headerText: "School",
        fieldName: "school.name",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "custom",
        headerText: "Extended time",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const value = data.multiplierValue
          return value ? convertSecondsToTime(SESSION_DURATION * value) : ""
        }
      },
      {
        type: "text",
        headerText: "Reason",
        headerNoWrap: true,
        fieldName: "message"
      },
      {
        type: "text",
        headerText: "Rejection Reason",
        headerNoWrap: true,
        fieldName: "rejectionReason"
      },
      {
        type: "custom",
        headerText: "Document",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const filename = data?.proofFileMetadata?.filename
          const url = data?.proofFile
          return url && filename ? (
            <Button variant="text" onClick={() => {
              return new JsFileDownloader({
                url,
                filename
              })
            }}>
              {filename}
            </Button>
          ) : ""
        }
      },
      {
        type: "text",
        headerText: "Requested by",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "createdByUser.fullName"
      },
      {
        type: "custom",
        headerText: "Expiration date",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => data.disabilityMultiplierExpiration ? formatDate(data.disabilityMultiplierExpiration) : ""
      },
      {
        type: "text",
        headerText: "Status",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "status"
      }
    ]
  }, [requestsData])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setQuery(state)
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, filters, searchQuery} = state
    const classroomId = props.classroomId || filters?.find(i => i.id === "classroomId")?.value
    const schoolId = props.schoolId || filters?.find(i => i.id === "schoolId")?.value
    const districtId = props.districtId || filters?.find(i => i.id === "districtId")?.value

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      search: searchQuery,
      targetUserId: props.userId,
      classroomId,
      schoolId,
      districtId
    }

    disabilityMultiplierRequestsQueryFetch({
      variables: commonVars,
      fetchPolicy
    }).then(res => {
      setRequestsData(res.data?.disabilityMultiplierRequests || null)
    })
  }

  const handleQueryDistrictIds = (value: string) => {
    districtIdsQueryFetch({
      variables: {
        search: value
      }
    }).then(res => {
      setDistrictIdsData(res.data?.districts?.items || null)
    })
  }

  const handleQuerySchoolIds = (value: string, tableState: DataTableState) => {
    const districtId = props.districtId || tableState.filters?.find(i => i.id === "districtId")?.value

    schoolIdsQueryFetch({
      variables: {
        districtIds: districtId ? [districtId] : undefined,
        search: value
      }
    }).then(res => {
      setSchoolIdsData(res.data?.schools?.items || null)
    })
  }

  const handleQueryClassroomIds = (value: string, tableState: DataTableState) => {
    const schoolId = props.schoolId || tableState.filters?.find(i => i.id === "classroomId")?.value
    classroomIdsQueryFetch({
      variables: {
        schoolId,
        includeArchived: false,
        search: value
      }
    }).then(res => {
      setClassroomIdsData(res.data?.classrooms?.items || null)
    })
  }

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      main: props.schoolId ? [
        props.schoolId && {
          id: "classroomId",
          type: "select-single",
          label: "Classroom",
          loading: classroomIdsQuery.loading,
          onUpdateOptions: handleQueryClassroomIds,
          options: [
            {label: "All classrooms", value: ""},
            ...classroomIdsData.map(i => ({
              label: i.name || "",
              value: i._id
            }))
          ].filter(Boolean)
        }
      ] : [
        props.userType === "admin" && {
          id: "districtId",
          type: "select-single",
          label: "District",
          loading: districtIdsQuery.loading,
          onUpdateOptions: handleQueryDistrictIds,
          options: [
            {label: "All districts", value: ""},
            ...(districtIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        },
        (props.districtId || props.userType === "admin")  && {
          id: "schoolId",
          type: "select-single",
          label: "School",
          loading: schoolIdsQuery.loading,
          onUpdateOptions: handleQuerySchoolIds,
          options: [
            {label: "All schools", value: ""},
            ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        }
      ]
    }
  }, [
    props.classroomId,
    props.schoolId,
    props.districtId,
    props.userType,
    classroomIdsQuery.loading,
    districtIdsQuery.loading,
    schoolIdsQuery.loading,
    classroomIdsData,
    districtIdsData,
    schoolIdsData
  ])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      props.userType === "admin" && {
        key: "viewRequests",
        label: "View Requests",
        variant: "outlined",
        color: "success",
        onClick: () => setShowModal(true)
      }
    ]
  }, [])

  const handleCancelModal = (refetch?: boolean) => {
    setShowModal(false)
    refetch && handleQuery(query, {
      refetch: true
    })
  }

  return (
    <Box>
      <DataTable
        schema={tableSchema}
        data={requestsData?.items}
        actionButtons={tableActionButtons}
        loading={requestsLoading}
        error={!!requestsError}
        itemsTotalCount={requestsData?.total}
        onQueryUpdate={handleQuery}
        search="Search Requests"
        filters={tableFilters}
        lastPage={!requestsData?.hasMore}
      />
      <CustomModal
        open={showModal}
        onClose={() => handleCancelModal()}>
        <ViewRequestsForm
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
    </Box>
  )
}
