import React from "react"
import {Checkbox, SxProps, TableCell, Theme} from "@mui/material"

interface Props {
  id: string
  disabled?: boolean
  checked: boolean
  onClick: (id: string) => void
  sx?: SxProps<Theme>
}

export default function CheckboxCell({
  id,
  disabled,
  checked,
  onClick,
  sx
}: Props) {
  return (
    <TableCell
      align="center"
      key={id}
      padding="checkbox"
      sx={{
        textAlign: "center",
        ...sx
      }}>
      <Checkbox
        color="primary"
        disabled={disabled}
        onChange={() => onClick(id)}
        checked={checked || false}
      />
    </TableCell>
  )
}
