import {Box} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import MainLayout from "components/MainLayout"
import {Outlet, useParams} from "react-router-dom"
import {useSelector} from "store"
import Sidebar from "./components/Sidebar"
import React, {useMemo} from "react"

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  layout: {
    height: "100%",
    paddingLeft: "25%",
    overflowY: "auto"
  },
  contentOnly: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  }
}))

export default function AdminLayout() {
  const s = useStyles()
  const params = useParams()

  const schoolId = useSelector(({schoolSlice}) => schoolSlice.currentSchool)

  const checkExamWindowPage = useMemo(() => {
    return !!(params.labSessionId)
  }, [params])

  return (
    <MainLayout key={schoolId} noSidebar type="admin">
      <Box className={s.root}>
        {!checkExamWindowPage && (
          <Sidebar/>
        )}
        <Box className={cn(s.layout, {[s.contentOnly]: checkExamWindowPage})}>
          <Outlet/>
        </Box>
      </Box>
    </MainLayout>
  )
}
