import {gql} from "@apollo/client"

export default gql`
  query GetDistrictStudentDetails($districtId: GraphQLObjectId!, $search: String, $sortBy: String, $order: SortOrder, $offset: Int!, $take: Int!) {
    users(
      districtId: $districtId
      search: $search 
      sortBy: $sortBy 
      order: $order 
      offset: $offset 
      take: $take
    ) {
      hasMore
      items {
        _id
        assignedCourses {
          id
          title {
            raw
          }
        }
        disabilityMultiplierRequest {
          _id
          status
        }
        username
        studentId
        firstName
        lastName
        email
        cognitoUserId
        labsLicense {
          _id
          isActive
          expirationDate
        }
        disabilityMultiplier
        vouchers {
          _id
          expirationDate
          examCode
        }
        school {
          name
        }
      }
      total
    }
  }
`
