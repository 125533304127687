import {gql} from "@apollo/client"

export default gql`
  query GetSchool($schoolId: GraphQLObjectId!) {
    getSchool(schoolId: $schoolId) {
      _id
      logo
      city
      name
      state
      country
      postcode
      address
      districtId
      district {
        _id
        country
        name
        logo
        state
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
      }
      remainingLicenseSeats {
        coursesRemainTotal
        examsRemainTotal
        labsRemainTotal
        practicesRemainTotal
      }
    }
  }
`
