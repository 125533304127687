import PersonnelTable from "components/PersonnelTable"
import React from "react"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {useSelector} from "store"
import useMainPageLink from "hooks/useMainPageLink"

export default function SchoolPersonnel() {
  const {mainPageLink} = useMainPageLink()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="School Personnel"
      title="School Personnel"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {text: "School Personnel"}
      ]}>
      <PersonnelTable schoolId={schoolId}/>
    </LayoutWithBreadcrumbs>
  )
}
