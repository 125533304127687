import {gql} from "@apollo/client"

export default gql`
  query GetManagedSchools(
    $sortBy: String
    $order: SortOrder
    $search: String
    $offset: Int = 0
    $take: Int = 100
  ) {
    getManagedSchools(
      sortBy: $sortBy
      order: $order
      search: $search
      offset: $offset
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        createdAt
        name
        state
        city
        logo
        districtId
        district {
          _id
          country
          name
        }
      }
    }
  }
`
