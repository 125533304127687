import {useLazyQuery} from "@apollo/client"
import GET_SCHOOL_TEACHERS from "api/apollo/queries/GET_SCHOOL_TEACHERS"
import DataTable from "components/DataTable"
import {
  Classroom,
  GetSchoolTeachersQuery,
  GetSchoolTeachersQueryVariables,
  User
} from "generated/graphql"
import React, {useMemo, useState} from "react"
import {Box, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {DataTableQueryUpdate, DataTableSchema} from "components/DataTable/types.t"
import {useSelector} from "store"
import {QueryDataType} from "types/typeUtils"

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "500px",
    [theme.breakpoints.down(undefined)]: {
      width: "95%",
      minWidth: "0"
    }
  }
}))

interface Props {
  classroom: DeepPartial<Classroom>
  onManageTeacher: (action: "REMOVE" | "ADD", id: string) => void
  loading?: boolean
}

export default function AddTeacherForm({
  classroom,
  onManageTeacher,
  loading
}: Props) {
  const s = useStyles()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const [teachersData, setTeachersData] = useState<QueryDataType<DeepPartial<User>>>(null)

  const [teachersQueryFetch, teachersQuery] = useLazyQuery<
    GetSchoolTeachersQuery,
    GetSchoolTeachersQueryVariables
  >(GET_SCHOOL_TEACHERS)

  const teachersError = useMemo(() => {
    return !teachersData && (teachersQuery.error || null)
  }, [teachersData, teachersQuery.error])

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Last Name",
        fieldName: "lastName",
        sort: "lastName"
      },
      {
        type: "text",
        headerText: "First Name",
        fieldName: "firstName",
        sort: "firstName"
      },
      {
        type: "checkbox",
        key: data => data._id,
        checked: data => {
          return classroom.assignedTeachers.includes(data._id)
        },
        handler: handleSelectTeacher
      }
    ]
  }, [classroom, teachersData])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, searchQuery} = state

    teachersQueryFetch({
      fetchPolicy,
      variables: {
        schoolId,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order,
        search: searchQuery
      }
    }).then(res => {
      setTeachersData(res.data?.getSchool?.teachers || null)
    })
  }

  function handleSelectTeacher(selected: boolean, data: User) {
    onManageTeacher(selected ? "ADD" : "REMOVE", data._id)
  }

  return (
    <Box className={s.root} py={3} px={4}>
      <Typography variant="h5" mb={2}>
        Teachers for Classroom
      </Typography>
      <Box minWidth="50vw" minHeight="20vh">
        <DataTable
          schema={tableSchema}
          data={teachersData?.items}
          loading={loading || teachersQuery.loading}
          error={!!teachersError}
          itemsTotalCount={teachersData?.total}
          onQueryUpdate={handleQuery}
          search="Search Teachers"
          lastPage={!teachersData?.hasMore}
        />
      </Box>
    </Box>
  )
}
