import {LINKS} from "consts/links"
import useAuth from "hooks/useAuth"
import Login from "pages/authentication/Login"
import React, {useState} from "react"
import {Navigate, useLocation} from "react-router-dom"
import {useSelector} from "store"
import {Roles} from "types/access"

interface Props {
  rolesAllowed?: Roles[]
}

export default function AuthGuard({
  children,
  rolesAllowed
}: React.PropsWithChildren<Props>) {
  const {isAuthenticated, user} = useAuth()
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)
  const {fakeRoles} = useSelector((store) => store.userSlice)

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }

    return <Login/>
  }

  if (location.pathname === "/") {
    return (
      <Navigate
        to={user?.roles.includes(Roles.Student) || fakeRoles?.includes(Roles.Student) ?
            LINKS.dashboard
          :
            LINKS.classrooms
        }
      />
    )
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null)

    return <Navigate to={requestedLocation}/>
  }

  if (!!rolesAllowed?.length && !rolesAllowed.some(i => !!user?.roles.includes(i))) {
    return <Navigate to="/"/>
  }

  return <>{children}</>
}
