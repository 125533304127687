import {Box} from "@mui/material"
import React from "react"
import {createStyles, makeStyles} from "@mui/styles"
import CodeAnswer from "./components/CodeAnswer"
import SimpleAnswer from "./components/SimpleAnswer"
import MatchAnswer from "./components/MatchAnswer"
import CustomAccordionItem from "./components/CustomAccordionItem"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      "& .Mui-expanded": {
        margin: 0
      },
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "12px 0"
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "48px"
      }
    }
  })
)

export interface IQuestionItem {
  id: string
  type: string
  displayName: string
  description: string
  submitted?: boolean
  validated: boolean
  correctAnswer: Array<{
    id: string
    text: string
    position?: number
  }>
  multipleDetails?: {
    options: Array<{
      id: string
      _id: string
      text: string
    }>
  }
  codeSnippet: {
    code: string
    language: string
  } | null
  answers?: Array<{id: string}>
}

interface Props {
  questions: Array<IQuestionItem>
  backgroundColorProp?: string
}

export default function ControlledAccordions({
  questions,
  backgroundColorProp
}: Props) {
  const s = useStyles()

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const renderQuestionHelper = (
    type: string,
    correctAnswer: IQuestionItem["correctAnswer"],
    questionId: string
  ) => {
    switch (type) {
      case "single":
      case "multiple": {
        const questionRef = (questions || []).find(i => i.id === questionId)
        const options = questionRef?.multipleDetails?.options?.map(i => ({
          ...i,
          isCorrect: !!correctAnswer.find(answer => answer.id === i._id),
          isChosen: !!questionRef.answers?.find(answer => answer.id === i._id)
        }))

        return <SimpleAnswer options={options}/>
      }
      case "match":
        return <MatchAnswer correctAnswer={correctAnswer}/>
      case "parsons":
      case "practice":
        return <CodeAnswer correctAnswer={correctAnswer}/>
      default:
        return <Box/>
    }
  }

  return (
    <Box className={s.root}>
      {questions && questions.map((i, num) => (
        <CustomAccordionItem
          key={i.id}
          id={i.id}
          backgroundColorProp={backgroundColorProp}
          expanded={expanded === i.id}
          handleChange={handleChange}
          answerToRender={renderQuestionHelper(i.type, i.correctAnswer, i.id)}
          type={i.type}
          displayName={`${num + 1}. ${i.displayName}`}
          description={i.description}
          submitted={i.submitted}
          validated={i.validated}
          codeSnippet={i.codeSnippet}
        />
      ))}
    </Box>
  )
}
