import {Box, SxProps, Theme, Typography} from "@mui/material"
import CircularProgress from "components/CircularProgress"
import React from "react"

interface Props {
  label?: string
  value?: number
  align?: "left" | "center" | "right"
}

export default function Score({
  label = "Score",
  value = 0,
  align = "left"
}: Props) {
  const sx: SxProps<Theme> = {
    justifyContent: align === "right" ? "flex-end" : align === "center" ? "center" : "flex-start"
  }

  return (
    <Box display="flex" alignItems="center" sx={sx}>
      <Box mr={2}>
        <Typography align="right" variant="subtitle2">
          {value}%
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {label}
        </Typography>
      </Box>
      <CircularProgress value={value}/>
    </Box>
  )
}
