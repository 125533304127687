import {ApolloProvider} from "@apollo/client"
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles"
import GlobalStyles from "components/GlobalStyles"
import PracticeNotify from "components/PracticeNotify"
import RTL from "components/RTL"
import SplashScreen from "components/SplashScreen"
import {LINKS} from "consts/links"
import {School} from "generated/graphql"
import useAuth from "hooks/useAuth"
import {useGoogleAnalytics} from "hooks/useGoogleAnalytics"
import useScrollReset from "hooks/useScrollReset"
import useSettings from "hooks/useSettings"
import {SnackbarProvider} from "notistack"
import {useEffect, useMemo, useState} from "react"
import {useLocation, useNavigate, useRoutes} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {
  cleanSpecificDistrictStateField,
  getDistrictInfoThunk, setCurrentDistrict
} from "store/slices/districtSlice/districtsSlice"
import {cleanSpecificSchoolStateField, getSchoolInfoThunk} from "store/slices/schoolSlice/schoolSlice"
import {fetchUserDetailsThunk, getUserInfoThunk, setFakeGroup} from "store/slices/userSlice/userSlice"
import {createTheme} from "theme"
import {Roles} from "types/access"
import useLocalStorage from "hooks/useLocalStorage"
import client from "./api/apollo"
import "./i18n"
import getAllowedRoutes from "./routes"

export default function Wrapper() {
  const {settings} = useSettings()

  const auth = useAuth()
  const isAuthenticated = auth.isAuthenticated
  const navigate = useNavigate()

  useScrollReset()
  useGoogleAnalytics()

  const dispatch = useDispatch()
  const location = useLocation()

  const [selectedAccount, setSelectedAccount] = useLocalStorage<SelectedAccount>("selectedAccount")

  const {fakeRoles, roles: userRoles, loadingSigUp} = useSelector((store) => store.userSlice)
  const {currentSchool} = useSelector((store) => store.schoolSlice)
  const {currentDistrict} = useSelector((store) => store.districtSlice)

  const [accountLoading, setAccountLoading] = useState(true)

  const currentView = useRoutes(getAllowedRoutes(fakeRoles?.length ? fakeRoles : userRoles))

  const isCertificatePage = useMemo(() => location.pathname.split("/")[1] === "c", [location])

  const isGroupsLoading = useMemo(() => {
    return (
      location.pathname.includes("authentication") ||
      isCertificatePage ||
      location.pathname === "/"
    )
  }, [location, isCertificatePage])

  const loading = useMemo(() => {
    return isAuthenticated && (loadingSigUp || accountLoading || false)
  }, [accountLoading, loadingSigUp, isAuthenticated])

  useEffect(() => {
    if (!isGroupsLoading && auth.isInitialized && !isAuthenticated) {
      navigate("/")
    }
  }, [auth.isInitialized, auth.isAuthenticated, isGroupsLoading])

  useEffect(() => {
    if (!isAuthenticated) return

    dispatch(fetchUserDetailsThunk())
    dispatch(getUserInfoThunk())

    handleLoadAccountData()
  }, [isAuthenticated])

  const handleLoadAccountData = () => {
    if (isAuthenticated) {
      setAccountLoading(true)
      dispatch(setFakeGroup(null))

      const districtId = selectedAccount?.districtId
      const schoolId = selectedAccount?.schoolId

      // if (districtId) {
      //   dispatch(getDistrictInfoThunk(districtId)).then((res) => {
      //     if (res.meta.requestStatus === "rejected") {
      //       handleSelectStudentAccount()
      //     } else {
      //       const school = res.payload as Schoo
      //       dispatch(cleanSpecificSchoolStateField([
      //         "currentSchool",
      //         "logo",
      //         "name",
      //         "districtId",
      //         "districtName",
      //         "districtLogo"
      //       ]))
      //       !isCertificatePage && ![LINKS.districtAdmin, LINKS.admin].some(i => location.pathname.includes(i)) && handleNavigate(LINKS.districtAdmin)
      //     }
      //   }).finally(() => setAccountLoading(false))
      // } else if (schoolId) {
      //   dispatch(getSchoolInfoThunk(schoolId)).then(res => {
      //     if (res.meta.requestStatus === "rejected") {
      //       handleSelectStudentAccount()
      //     } else {
      //       dispatch(cleanSpecificDistrictStateField([
      //         "currentDistrict",
      //         "logo",
      //         "name"
      //       ]))
      //     }
      //   }).finally(() => setAccountLoading(false))
      // } else {
      //   handleSelectStudentAccount()
      // }

      if ((districtId && schoolId ) || (schoolId)) {
        dispatch(getSchoolInfoThunk(schoolId)).then(res => {
          if (res.meta.requestStatus === "rejected") {
            handleSelectStudentAccount()
          } else {
            const school = res.payload as School
            if (districtId) {
              if (districtId === school?.districtId) {
                dispatch(setCurrentDistrict(districtId))
                // handleNavigate(LINKS.classrooms)
              } else {
                handleSelectStudentAccount()
              }
            } else {
              dispatch(cleanSpecificDistrictStateField([
                "currentDistrict",
                "logo",
                "name",
                "totalSchools"
              ]))
              // handleNavigate(LINKS.classrooms)
            }
          }
        }).finally(() => setAccountLoading(false))
      } else if (districtId) {
        dispatch(getDistrictInfoThunk(districtId)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            handleSelectStudentAccount()
          } else {
            dispatch(cleanSpecificSchoolStateField([
              "currentSchool",
              "logo",
              "name",
              "districtId"
            ]))
            !isCertificatePage && ![LINKS.districtAdmin, LINKS.admin].some(i => location.pathname.includes(i)) && handleNavigate(LINKS.districtAdmin)
          }
        }).finally(() => setAccountLoading(false))
      } else {
        handleSelectStudentAccount()
      }
    }
  }

  const handleNavigate = (link: string) => {
    if (![LINKS.admin].some(i => location.pathname.includes(i))) {
      !isCertificatePage && navigate(link, {
        replace: true
      })
    }
  }

  const handleSelectStudentAccount = () => {
    setSelectedAccount({
      schoolId: "",
      districtId: ""
    })
    dispatch(cleanSpecificSchoolStateField([
      "currentSchool",
      "logo",
      "name",
      "districtId"
    ]))
    dispatch(cleanSpecificDistrictStateField([
      "currentDistrict",
      "logo",
      "name",
      "totalSchools"
    ]))
    dispatch(setFakeGroup(Roles.Student))
    setAccountLoading(false)
  }

  useEffect(() => {
    if ((selectedAccount?.schoolId !== (currentSchool || "") || selectedAccount.districtId !== (currentDistrict || "")) && !accountLoading) {
      handleLoadAccountData()
    }
  }, [selectedAccount])

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          {!isGroupsLoading && !userRoles && <SplashScreen/>}
          <RTL direction={settings.direction}>
            <SnackbarProvider dense maxSnack={3}>
              <GlobalStyles/>
              {auth.isInitialized && !loading ? currentView : <SplashScreen/>}
              <PracticeNotify/>
            </SnackbarProvider>
          </RTL>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
