import {IconButton, TextField} from "@mui/material"
import {TextFieldProps} from "@mui/material/TextField/TextField"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {useState} from "react"


export default function PasswordInput(props: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
        >
          {showPassword ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
      }}
    />
  )
}
