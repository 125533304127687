import {gql} from "@apollo/client"

export default gql`
  mutation CreateLicense(
    $quota: LicenseQuotaInput!
    $licensePayload: NewLicenseInput!
  ) {
    createLicense(
      quota: $quota
      licensePayload: $licensePayload
    ) {
      _id
    }
  }
`
