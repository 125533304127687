import {signInWithRedirect} from "aws-amplify/auth"
import {Link as RouterLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {Box, Card, CardContent, Link, Grid, Typography, Divider, IconButton, Tooltip} from "@mui/material"
import LoginAmplify from "./components/LoginAmplify"
import SidebarLogoName from "components/SidebarLogoName"
import useAuth from "hooks/useAuth"
import {makeStyles} from "@mui/styles"
import {useTheme} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Footer from "components/Footer"
import Google from "assets/icons/Google"
import MasteryCoding from "assets/pictures/masteryCoding.jpeg"
import Clever from "assets/pictures/clever.png"

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    height: "100%",
    overflow: "auto",
    paddingBottom: "48px"
  },
  sidesContainer: {
    minHeight: "calc(100vh - 51px)"
  },
  rightSideContainer: {
    display: "flex",
    justifyContent: "center"
  },
  loginCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "75%"
    },
    [theme.breakpoints.up("md")]: {
      width: "60%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  },
  loginCard: {
    boxShadow: "none"
  },
  linksContainer: {
    marginTop: theme.spacing(1)
  },
  createNewAccount: {
    float: "left"
  },
  forgotPassword: {
    float: "right"
  },
  titleOne: {
    color: theme.palette.white.main,
    marginTop: theme.spacing(2)
  },
  titleTwo: {
    color: theme.palette.white.main,
    fontWeight: 200,
    marginTop: theme.spacing(1)
  }
}))

export default function Login() {
  const {platform} = useAuth() as any
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      <Helmet>
        <title>Login | KP Platform</title>
      </Helmet>
      <Box className={classes.loginContainer}>
        <Grid container className={classes.sidesContainer}>
          {!isMobile && <SidebarLogoName/>}
          <Grid
            item
            xs={isMobile ? 12 : 8}
            md={8}
            className={classes.rightSideContainer}>
            <Box className={classes.loginCardContainer}>
              <Card className={classes.loginCard}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 4
                  }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography color="primary" gutterBottom variant="h2">
                        Log in
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        Log in on the internal platform
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: 50,
                        "& > img": {
                          maxHeight: "100%",
                          width: "auto"
                        }
                      }}>
                      <img alt="Auth platform" src="/static/kp_logo.png"/>
                    </Box>
                  </Box>
                  <Box flexGrow={1} mt={3}>
                    {platform === "Amplify" && <LoginAmplify/>}
                  </Box>
                  <Box className={classes.linksContainer}>
                    <Link
                      className={classes.createNewAccount}
                      color="textSecondary"
                      component={RouterLink}
                      to="/authentication/register"
                      variant="body2">
                      Create new account
                    </Link>
                    {platform === "Amplify" && (
                      <Link
                        className={classes.forgotPassword}
                        color="textSecondary"
                        component={RouterLink}
                        to="/authentication/password-recovery"
                        variant="body2">
                        Forgot password
                      </Link>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Divider color="primary">
                      <Typography color="textSecondary">
                        or sign in with
                      </Typography>
                    </Divider>
                  </Box>
                  <Box mt={2} display="flex" justifyContent="center" alignItems="center" gap={2}>
                    <Tooltip title="Mastery Coding">
                      <IconButton
                        color="primary"
                        sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                        onClick={() => signInWithRedirect({provider: {custom: "MasteryCoding"}})}
                      >
                        <Box
                          sx={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "100%",
                            overflow: "hidden"
                          }}
                        >
                          <img src={MasteryCoding} alt="mastery coding" className="object-contain" fetchpriority="high"/>
                        </Box>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Clever">
                      <IconButton
                        color="primary"
                        sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                        onClick={() => signInWithRedirect({provider: {custom: "Clever"}})}
                      >
                        <Box
                          sx={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "100%",
                            overflow: "hidden"
                          }}
                        >
                          <img src={Clever} alt="clever" className="object-contain" fetchpriority="high"/>
                        </Box>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Google">
                     <IconButton
                       color="primary"
                       sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                       onClick={() => signInWithRedirect({provider: "Google"})}
                     >
                       <Google/>
                     </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </>
  )
}
