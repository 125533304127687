import {useMutation, useQuery} from "@apollo/client"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography
} from "@mui/material"
import MOVE_USER_TO_ANOTHER_CLASSROOM from "api/apollo/mutations/MOVE_USER_TO_ANOTHER_CLASSROOM"
import GET_SCHOOL_CLASSROOMS from "api/apollo/queries/GET_SCHOOL_CLASSROOMS"
import GET_USER_CLASSROOMS from "api/apollo/queries/GET_USER_CLASSROOMS"
import {Formik} from "formik"
import {
  GetSchoolClassroomsQuery,
  GetSchoolClassroomsQueryVariables,
  GetUserClassroomsQuery,
  GetUserClassroomsQueryVariables,
  MoveUserToAnotherClassroomMutation,
  MoveUserToAnotherClassroomMutationVariables,
  User
} from "generated/graphql"
import React, {useMemo} from "react"
import {Link as RouterLink} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {
  fetchSchoolStudentsThunk,
  fetchStudentsForClassThunk
} from "store/slices/schoolSlice/schoolSlice"
import * as Yup from "yup"

interface Props {
  classroomId?: string
  student: Partial<User>
  onAfterSubmit: () => void
  onCancel: () => void
  schoolId?: string
  districtId?: string
}

export default function TransferStudentForm({
  classroomId,
  student,
  onAfterSubmit,
  onCancel,
  schoolId,
  districtId
}: Props) {
  const dispatch = useDispatch()

  const currentSchoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const classroomsQuery = useQuery<
    GetSchoolClassroomsQuery,
    GetSchoolClassroomsQueryVariables
  >(GET_SCHOOL_CLASSROOMS, {
    variables: {
      schoolId: schoolId || currentSchoolId,
      offset: 0,
      take: 100
    }
  })

  const userClassroomsQuery = useQuery<
    GetUserClassroomsQuery,
    GetUserClassroomsQueryVariables
  >(GET_USER_CLASSROOMS, {
    variables: {
      userId: student.studentId
    }
  })

  const [moveUserToAnotherClassroom] = useMutation<
    MoveUserToAnotherClassroomMutation,
    MoveUserToAnotherClassroomMutationVariables
  >(MOVE_USER_TO_ANOTHER_CLASSROOM)

  const classroomsData = useMemo(() => {
    return classroomsQuery.data?.classrooms.items
  }, [classroomsQuery.data])

  const userClassroomsData = useMemo(() => {
    return userClassroomsQuery.data?.getUser.classrooms
  }, [userClassroomsQuery.data])

  const formInitialValues = useMemo(() => {
    return {
      currentClassroom: classroomId || (userClassroomsData && userClassroomsData[0]?._id) || "",
      newClassroom: ""
    }
  }, [classroomId, userClassroomsData])

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    moveUserToAnotherClassroom({
      variables: {
        userId: student._id,
        fromClassroom: data.currentClassroom,
        toClassroom: data.newClassroom
      }
    }).then(() => {
      dispatch(fetchSchoolStudentsThunk(schoolId || currentSchoolId))

      if (!!classroomId) {
        dispatch(fetchStudentsForClassThunk({classroomId}))
      }

      dispatch(notifyUser({message: "STUDENT_TRANSFER_SUCCESS"}))
      onAfterSubmit()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        currentClassroom: Yup.string().required("Field is required"),
        newClassroom: Yup.string().required("Field is required")
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        values,
        touched,
        handleChange,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Box py={3} px={3} minWidth="30vw">
            <Box>
              <Typography variant="h5" mb={1}>
                Transfer Student
              </Typography>
              <Typography variant="h6" mb={4}>
                Please select the new Classroom
              </Typography>
              {!classroomId && userClassroomsData?.length > 0 && (
                <Box mb={2}>
                  <FormControl fullWidth>
                    <InputLabel error={!!(touched.currentClassroom && errors.currentClassroom)}>
                      From Classroom
                    </InputLabel>
                    <Select
                      name="currentClassroom"
                      labelId="currentClassroom-select-label"
                      id="currentClassroom-select"
                      label="From Classroom"
                      error={!!(touched.currentClassroom && errors.currentClassroom)}
                      value={values.currentClassroom || ""}
                      onChange={handleChange}>
                      {userClassroomsData.map(i => (
                        <MenuItem key={i._id} value={i._id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!!(touched.currentClassroom && errors.currentClassroom) && (
                    <FormHelperText error sx={{margin: "3px 14px 0 14px"}}>
                      {touched.currentClassroom && errors.currentClassroom}
                    </FormHelperText>
                  )}
                </Box>
              )}
              <Box>
                <FormControl fullWidth>
                  <InputLabel error={!!(touched.newClassroom && errors.newClassroom)}>
                    To Classroom
                  </InputLabel>
                  <Select
                    name="newClassroom"
                    labelId="newClassroom-select-label"
                    id="newClassroom-select"
                    label="To Classroom"
                    error={!!(touched.newClassroom && errors.newClassroom)}
                    value={values.newClassroom || ""}
                    onChange={handleChange}>
                    {(classroomsData || []).map(i => (
                      <MenuItem key={i._id} value={i._id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!(touched.newClassroom && errors.newClassroom) && (
                  <FormHelperText error sx={{margin: "3px 14px 0 14px"}}>
                    {touched.newClassroom && errors.newClassroom}
                  </FormHelperText>
                )}
              </Box>
              {!districtId &&
                <Typography
                  variant="body2"
                  textAlign="end"
                  p={1}
                  pb={0}>
                  <Link to="/classrooms" component={RouterLink} underline="always">
                    Create new classroom
                  </Link>
                </Typography>
              }
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={4} gap={1}>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
