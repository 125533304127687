import {gql} from "@apollo/client"

export default gql`
  mutation MarkExamSessionAsReviewed($examSessionId: GraphQLObjectId!) {
    markExamSessionAsReviewed(examSessionId: $examSessionId) {
      _id
      certificateId
    }
  }
`
