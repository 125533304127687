import {gql} from "@apollo/client"

export default gql`
  query GetPendingInvitations($classroomId: GraphQLObjectId!) {
    getPendingInvitations(classroomId: $classroomId) {
      _id
      createdAt
      updatedAt
      status
      decidedAt
      user {
        _id
        cognitoUserId
        firstName
        lastName
        email
        username
      }
      invitationSourceId
      comment
      classroomId
      classroom {
        _id
        name
        school {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
      }
    }
  }
`
