import LicensesTable from "components/LicensesTable"
import React from "react"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {useSelector} from "store"
import useMainPageLink from "hooks/useMainPageLink"

export default function LicenseList() {
  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)
  const {mainPageLink} = useMainPageLink()

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Inventory"
      title="Inventory"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          text: "Inventory"
        }
      ]}>
      <LicensesTable schoolId={schoolId}/>
    </LayoutWithBreadcrumbs>
  )
}
