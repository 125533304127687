import {gql} from "@apollo/client"

export default gql`
  query GetUser {
    me {
      _id
      id: _id
      cognitoUserId
      username
      isAdmin
      adminInDistrictIds
      adminInSchoolIds
      districtId
      teacherInSchoolIds
      schoolId
      school {
        _id
        logo
        name
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
      }
      isProctor
      studentId
      firstName
      lastName
      photoUrl
      proctorSignedAt
      email
      country
      city
      postcode
      address
      birthdate
      phone
      totalTimeSpentInLabs
      activeLabSession {
        _id
        createdAt
        isConnection
        createdByUser {
          _id
          username
        }
        progressOfUser {
          _id
          username
        }
        progressChannel
        progressFound
      }
      isLabLicenseActive
      labsLicenseId
      labsLicense {
        _id
        labsRemain
        labsInitial
        expirationDate
      }
      classroomIds
      assignedCourseIds
      classrooms {
        _id
        createdAt
        updatedAt
        name
        school {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        assignedTeachers
        teachers {
          _id
          username
          email
          firstName
          lastName
          phone
        }
        createdBy
      }
      invitations {
        _id
        createdAt
        updatedAt
        status
        decidedAt
        userId
        invitationSourceId
        comment
        classroomId
        classroom {
          _id
          name
          school {
            _id
            name
            remainingLicenseSeats {
              coursesRemainTotal
              examsRemainTotal
              labsRemainTotal
              practicesRemainTotal
            }
          }
        }
      }
      waitingList {
        _id
        createdAt
        updatedAt
        status
        failedReason
        decidedAt
        studentId
        classroomId
        classroom {
          _id
          createdAt
          updatedAt
          status
          name
          schoolId
          school {
            _id
            createdAt
            updatedAt
            name
            logo
            remainingLicenseSeats {
              coursesRemainTotal
              examsRemainTotal
              labsRemainTotal
              practicesRemainTotal
            }
          }
          teachers {
            _id
            createdAt
            updatedAt
            username
            firstName
            lastName
            email
            fullName
          }
        }
        student {
          _id
          username
          firstName
          lastName
          email
          fullName
          photoUrl
        }
      }
    }
  }
`
