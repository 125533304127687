import EqualizerIcon from "@mui/icons-material/Equalizer"
import PersonIcon from "@mui/icons-material/Person"
import {Roles} from "types/access"
import VpnKeyIcon from "@mui/icons-material/VpnKey"
import DesktopMacIcon from "@mui/icons-material/DesktopMac"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import SchoolIcon from "@mui/icons-material/School"
import {LINKS} from "consts/links"

export default [
  {
    title: "Dashboard",
    items: [
      {
        title: "Overview",
        path: "/dashboard",
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "My Classrooms",
        path: "/my-classrooms",
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "Account",
        path: "/account",
        icon: <PersonIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "Redeem Key",
        path: "/redeem-key",
        icon: <VpnKeyIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      }
      /*{
        title: "My Exam Vouchers",
        path: "/my-exam-vouchers",
        icon: <VpnKeyIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.Student],
      },*/
    ]
  },
  {
    title: "Management",
    items: [
      /*{
        title: "Overview",
        path: LINKS.examAnalytics,
        icon: <EqualizerIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.School_Admin, Group.Sme],
      },*/
      // {
      //   title: "Students",
      //   path: LINKS.districtStudents,
      //   icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [Roles.District]
      // },
      // {
      //   title: "Personnel",
      //   path: LINKS.districtPersonnel,
      //   icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [Roles.District]
      // },
      {
        title: "Classrooms",
        path: LINKS.classrooms,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          // Group.Sme,
          Roles.Teacher
          // Group.District,
        ]
      },
      {
        title: "Exam results",
        path: LINKS.examResults,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme
        ]
      },
      {
        title: "Practice test results",
        path: LINKS.studentPracticeResults,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme
        ]
      },
      // {
      //   title: "Assign practice tests",
      //   path: LINKS.assignPracticeTestsPage,
      //   icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [
      //     Roles.School_Admin,
      //     Roles.Teacher
      //   ]
      // },
      {
        title: "Register as proctor",
        path: LINKS.registerAsProctor,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Teacher, Roles.School_Admin]
      },
      {
        title: "Students",
        path: LINKS.studentsListPage,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher
        ]
      },
      // {
      //   title: "Manage student accounts",
      //   path: "manage-students",
      //   icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [
      //     Roles.School_Admin,
      //     Roles.Sme,
      //     Roles.Teacher
      //   ]
      // },
      {
        title: "School Personnel",
        path: LINKS.schoolPersonnel,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Teacher
        ]
      },
      /*{
        title: "Classroom",
        path: LINKS.classroomListPage,
        icon: <EqualizerIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District],
      },*/
      {
        title: "Testing groups",
        path: LINKS.testingGroup,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher
        ]
      },
      /*{
        title: "Assign practice tests",
        path: "/assign-practice-tests",
        icon: <EqualizerIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District],
      },*/
      /*{
        title: "Start/Finish exam",
        path: "/start-finish-exam",
        icon: <EqualizerIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District],
      },*/
      {
        title: "Inventory",
        path: LINKS.licenseListPage,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher
        ]
      },
      {
        title: "WP Labs",
        path: LINKS.labList,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher
        ]
      },
      {
        title: "Extended Time",
        path: LINKS.extendedTime,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher
        ]
      },
      // {
      //   title: "License Inventory Logs",
      //   path: LINKS.licenseLogs,
      //   icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [
      //     Roles.School_Admin,
      //     Roles.Sme,
      //     Roles.Teacher
      //   ]
      // },
      {
        title: "School Settings",
        path: LINKS.settings,
        icon: <EqualizerIcon fontSize="small" style={{color: "white"}}/>,
        groups: [
          Roles.School_Admin
        ]
      }
      /*{
        title: "Vouchers",
        path: LINKS.vouchersListPage,
        icon: <EqualizerIcon fontSize="small" style={{ color: "white" }}/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District],
      },*/
    ]
  },
  {
    title: "Learning",
    items: [
      {
        title: "Learn",
        path: LINKS.courses,
        icon: <SchoolIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "Practice",
        path: "/practice-tests",
        icon: <DesktopMacIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "Practice Results",
        path: "/practice-results",
        icon: (
          <PlaylistAddCheckIcon fontSize="small" style={{color: "white"}}/>
        ),
        groups: [Roles.Student]
      }
      // {
      //   title: "Course (beta)",
      //   path: "/course-demo",
      //   icon: <SchoolIcon fontSize="small" style={{color: "white"}}/>,
      //   groups: [Roles.Student]
      // }
    ]
  },
  {
    title: "Certify",
    items: [
      /*{
        title: "Redeem Voucher",
        path: "/redeem-voucher",
        icon: <GetAppIcon fontSize="small" style={{ color: "white" }}/>,
      },
      {
        title: "My Exams",
        path: "/my-exams",
        icon: <FormatListBulletedIcon fontSize="small" style={{ color: "white" }}/>,
      },
      {
        title: "Prepare for examination",
        path: "/prepare-exam",
        icon: <CreateIcon fontSize="small" style={{ color: "white" }}/>,
      },*/
      {
        title: "Take Exam",
        path: LINKS.certify,
        icon: <SchoolIcon fontSize="small" style={{color: "white"}}/>,
        groups: [Roles.Student]
      },
      {
        title: "Exam Results",
        path: "/my-results",
        icon: (
          <PlaylistAddCheckIcon fontSize="small" style={{color: "white"}}/>
        ),
        groups: [Roles.Student]
      }
    ]
  }
  // {
  //   title: "SME",
  //   items: [
  //     {
  //       title: "Item",
  //       path: "/dashboard/sme",
  //     },
  //   ],
  // },
  // {
  //   title: "Admin",
  //   items: [
  //     {
  //       title: "Item",
  //       path: "/dashboard/admin",
  //     },
  //   ],
  // },
]
