import {DataTableToggleFilter} from "components/DataTable/types.t"
import React, {useEffect, useState} from "react"
import {
  Box,
  FormControlLabel,
  Switch
} from "@mui/material"

type Props = Omit<DataTableToggleFilter, "type"> & {
  onChange: (value: boolean) => void
}

export default function ToggleFilter({
  label,
  onChange,
  initialValue,
  value: valueProp
}: Props) {
  const [value, setValue] = useState<boolean | null>(() => {
    if (valueProp) {
      return valueProp
    } else if (typeof initialValue === "boolean") {
      return initialValue
    } else {
      return null
    }
  })

  useEffect(() => {
    if (valueProp === null) {
      setValue(initialValue || false)
    } else if (typeof valueProp === "boolean") {
      setValue(valueProp)
    }
  }, [valueProp])

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleChange = () => {
    setValue(!value)
  }

  return (
    <Box py="9px">
      <FormControlLabel
        sx={{
          ml: 0
        }}
        control={
          <Switch
            checked={value || false}
            edge="start"
            name="direction"
            onChange={handleChange}
          />
        }
        label={
          <Box>
            {label}
          </Box>
        }
      />
    </Box>
  )
}
