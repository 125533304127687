import {ProctoringType} from "generated/graphql"

const names = {
  [ProctoringType.Classroom]: "Classroom",
  [ProctoringType.Online]: "Online"
}

export default function getProctoringTypeName(input: string) {
  return names[input]
}
