import {
  Box,
  useTheme
} from "@mui/material"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import useMainPageLink from "hooks/useMainPageLink"
import Classrooms from "./components/Classrooms"
import Requests from "./components/Requests"
import React, {useState} from "react"

export default function MyClassrooms() {
  const theme = useTheme()
  const {mainPageLink} = useMainPageLink()

  const [key, setKey] = useState("INITIAL")

  const handleUpdate = () => {
    setKey(Math.random().toString(36).slice(2, 12))
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="My Classrooms"
      title="My Classrooms"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          text: "My Classrooms"
        }
      ]}>
      <Box minHeight={theme.spacing(32)} mb={4}>
        <Box>
          <Classrooms key={`classrooms-${key}`} onUpdate={handleUpdate}/>
        </Box>
        <Box mt={4}>
          <Requests key={`requests-${key}`} onUpdate={handleUpdate}/>
        </Box>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
