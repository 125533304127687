import {gql} from "@apollo/client"

export default gql`
  query GetSchoolStaffIds(
    $schoolId: GraphQLObjectId!
    $offset: Int = 0
    $take: Int = 100
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    getSchool(schoolId: $schoolId) {
      staff(
        take: $take
        offset: $offset
        sortBy: $sortBy
        order: $order
        search: $search
      ) {
        items {
          _id
          fullName
          firstName
          lastName
        }
        total
        hasMore
      }
    }
  }
`
