import CertificateTemplate, {CertificateType} from "components/CertificateTemplate"
import React, {useMemo} from "react"
import {useSearchParams} from "react-router-dom"

export default function Certificate() {
  const [searchParams] = useSearchParams()
  const data = useMemo(() => {
    return Object.fromEntries([...searchParams]) as CertificateType
  }, [searchParams])

  return <CertificateTemplate data={data}/>
}
