import {gql} from "@apollo/client"

export default gql`
  query GetUserDistricts($userId: GraphQLObjectId!) {
    getUser(userId: $userId) {
      _id
      adminInDistricts {
        _id
        name
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
      }
    }
  }
`
