import React, {useMemo, useRef} from "react"
import {useDispatch, useSelector} from "store"
import {makeStyles} from "@mui/styles"

const useStyles = makeStyles(() => ({
  iFrame: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    position: "absolute"
  },
  iFrameWrapper: {
    flexGrow: 1,
    position: "relative",
    background: "#ffffff",
    height: "100%"
  }
}))

interface Props {

}

export default function DynamicIframeWorkspace(props: Props) {
  const styles = useStyles()
  const gridIframe = useRef(null)

  const currentSession = useSelector(
    (state) => state.practiceSession.currentSession
  )

  const currentTask = useSelector((state) => {
    return state.practiceSession.currentTask
  })

  const iframeSrc = useMemo(() => {
    return currentSession && currentTask ? currentSession.iframeMap[currentTask.task._id] : null
  }, [currentSession, currentTask])

  return (
    <div className={styles.iFrameWrapper}>
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          ref={gridIframe}
          className={styles.iFrame}
          title="office365-exam"
          width="100%"
          height="100%"
        />
      ) : (
        <></>
      )}
    </div>
  )
}


