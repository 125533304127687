import {useLazyQuery} from "@apollo/client"
import {FileDownload} from "@mui/icons-material"
import {Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Link, Typography} from "@mui/material"
import EXPORT_LICENSES from "api/apollo/queries/EXPORT_LICENSES"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_DISTRICT_STAFF_IDS from "api/apollo/queries/GET_DISTRICT_STAFF_IDS"
import GET_LICENSES from "api/apollo/queries/GET_LICENSES"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import GET_SCHOOL_STAFF_IDS from "api/apollo/queries/GET_SCHOOL_STAFF_IDS"
import GET_USER_IDS from "api/apollo/queries/GET_USER_IDS"
import {getApiCallHeaders} from "api/rest"
import Plus from "assets/icons/Plus"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableFilter,
  DataTableFilters,
  DataTableQueryUpdate,
  DataTableSchema, DataTableState
} from "components/DataTable/types.t"
import CreateLicenseForm from "components/LicensesTable/components/CreateLicenseForm"
import TransferLicenseForm from "components/LicensesTable/components/TransferLicenseForm"
import {
  District,
  ExportLicensesQuery,
  ExportLicensesQueryVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetDistrictStaffIdsQuery,
  GetDistrictStaffIdsQueryVariables,
  GetLicensesQuery,
  GetLicensesQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  GetSchoolStaffIdsQuery,
  GetSchoolStaffIdsQueryVariables,
  GetUserIdsQuery,
  GetUserIdsQueryVariables,
  License,
  School,
  User
} from "generated/graphql"
import JsFileDownloader from "js-file-downloader"
import React, {useMemo, useState} from "react"
import {useSelector} from "store"
import {Roles} from "types/access"
import {QueryDataType} from "types/typeUtils"
import formatDate from "utils/formatDate"
import ArticleIcon from "@mui/icons-material/Article"

type Props = {
  schoolId?: string
  districtId?: string
  type?: "licenseAdmin"
}

type DataType = DeepPartial<License>

type ModalTypes = "TRANSFER_LICENSE" | "CREATE_LICENSE" | "EXPORT_LOADING"

export default function LicensesTable(props: Props) {
  const {roles} = useSelector(store => store.userSlice)
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [licensesData, setLicensesData] = useState<QueryDataType<DataType>>(null)
  const [selectedLicense, setSelectedLicense] = useState<Partial<License> | null>(null)
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null)
  const [selectedDistrictId, setSelectedDistrictId] = useState<string | null>(null)
  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [schoolIdsData, setSchoolIdsData] = useState<
    Partial<School>[] | null
  >(null)
  const [districtIdsData, setDistrictIdsData] = useState<
    Partial<District>[] | null
  >(null)
  const [licenseId, setLicenseId] = useState<string | null>(null)
  const [teacherIdsData, setTeacherIdsData] = useState<Partial<User>[] | null>(null)

  const [licensesFetch, licensesQuery] = useLazyQuery<
    GetLicensesQuery,
    GetLicensesQueryVariables
  >(GET_LICENSES)

  const [districtIdsQueryFetch, districtIdsQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] =  useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [districtStaffIdsQueryFetch, districtStaffIdsQuery] = useLazyQuery<
    GetDistrictStaffIdsQuery,
    GetDistrictStaffIdsQueryVariables
  >(GET_DISTRICT_STAFF_IDS)

  const [schoolStaffIdsQueryFetch, schoolStaffIdsQuery] = useLazyQuery<
    GetSchoolStaffIdsQuery,
    GetSchoolStaffIdsQueryVariables
  >(GET_SCHOOL_STAFF_IDS)

  const [staffIdsQueryFetch, staffIdsQuery] = useLazyQuery<
    GetUserIdsQuery,
    GetUserIdsQueryVariables
  >(GET_USER_IDS)

  const [exportLicenses] = useLazyQuery<
    ExportLicensesQuery,
    ExportLicensesQueryVariables
  >(EXPORT_LICENSES, {
    fetchPolicy: "network-only"
  })

  const licensesLoading = useMemo(() => {
    return (licensesQuery.loading || false)
  }, [licensesQuery.loading])

  const licensesError = useMemo(() => {
    return !licensesData && (licensesQuery.error || null)
  }, [licensesData, licensesQuery.error])

  const tableSchema: DataTableSchema<License> = useMemo(() => {
    return [
      {
        type: "custom",
        headerText: "Inventory name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        sort: "name",
        content: data => {
          return (
            <Box display="flex" alignItems="center">
              {data.name}
              <Box ml={0.5}>
                <IconButton size="small" onClick={() => setLicenseId(data._id.match(/.{1,4}/g).join("-"))}>
                  <ArticleIcon />
                </IconButton>
              </Box>
            </Box>
          )
        }
      },
      (!props.districtId || props.type === "licenseAdmin") && !props.schoolId && {
        type: "text",
        headerText: "District name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "district.name"
      },
      (!props.schoolId) && {
        type: "text",
        headerText: "School name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "school.name"
      },
      {
        type: "custom",
        headerText: "Teachers",
        headerNoWrap: true,
        contentWrap: "pre",
        content: data => {
          if (!data.teachers.length) return "Any"
          return data.teachers.map(i => i.fullName || "").join("\n")
        }
      },
      {
        type: "custom",
        headerText: "Program",
        headerNoWrap: true,
        contentWrap: "pre-line",
        content: data => {
          if (data.isUniversalLicense) return "Universal"
          return data.examCodes.join("\n")
        }
      },
      {
        type: "custom",
        headerText: "Expire By",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => formatDate(data.expirationDate),
        sort: "expirationDate"
      },
      {
        type: "custom",
        headerText: "Exams",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => `${data.examVouchersRemain}/${data.examVouchersInitial || 0}`
      },
      {
        type: "custom",
        headerText: "Practice tests",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => `${data.practiceVouchersRemain}/${data.practiceVouchersInitial || 0}`
      },
      {
        type: "custom",
        headerText: "Courses",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => `${data.coursesRemain}/${data.coursesInitial || 0}`,
        sort: "coursesRemain"
      },
      {
        type: "custom",
        headerText: "Labs",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => `${data.labsRemain}/${data.labsInitial || 0}`
      },
      (roles.includes(Roles.Admin) || roles.includes(Roles.District_Admin) || roles.includes(Roles.School_Admin)) && {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        content: data => {
          const isDisable = !(new Date(data.expirationDate) >= new Date()) || (
            !data.coursesRemain && !data.practiceVouchersRemain && !data.examVouchersRemain && !data.labsRemain
          )

          return (
            <Button
              variant="outlined"
              color="secondary"
              disabled={isDisable}
              onClick={() => {
                setSelectedLicense(data)
                setModal("TRANSFER_LICENSE")
              }}
              size="small">
              Transfer inventory
            </Button>
          )
        }
      }
      // {
      //   type: "custom",
      //   headerText: "",
      //   headerNoWrap: true,
      //   content: data => {
      //     return (
      //       <Link to={`${LINKS.licenseListPage}/${data?._id}`} component={RouterLink} underline="none">
      //         <Button
      //           variant="outlined"
      //           color="secondary"
      //           size="small"
      //         >
      //           View Logs
      //         </Button>
      //       </Link>
      //     )
      //   }
      // }
    ]
  }, [licensesData, props.districtId, props.type, props.schoolId])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return (props.type === "licenseAdmin" && [
      {
        key: "createNewLicense",
        label: "Create New License",
        variant: "outlined",
        color: "success",
        icon: <Plus/>,
        onClick: () => setModal("CREATE_LICENSE")
      },
      {
        key: "exportLicenses",
        label: "Export Licenses",
        icon: <FileDownload />,
        variant: "contained",
        onClick: handleExportLicenses
      }
    ])
  }, [])

  const handleQuery: DataTableQueryUpdate = (state) => {
    setQuery(state)

    const {itemsPerPage, page, sort, searchQuery, filters} = state

    const showExpired = filters?.find(i => i.id === "showExpired")?.value
    let districtId = props.districtId || filters?.find(i => i.id === "districtId")?.value
    let schoolId = props.schoolId || filters?.find(i => i.id === "schoolId")?.value
    const assignedTeacher = filters?.find(i => i.id === "assignedTeacher")?.value

    setSelectedSchoolId(districtId === selectedDistrictId ? schoolId : null)
    setSelectedDistrictId(districtId)

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      search: searchQuery,
      showExpired,
      assignedTeacher
    }

    licensesFetch({
      fetchPolicy: "network-only",
      variables: {
        ...commonVars,
        districtId,
        schoolId
      }
    }).then(res => {
      setLicensesData(res.data?.licenses || null)
    })

    // if (schoolId) {
    //   schoolsLicensesFetch({
    //     fetchPolicy,
    //     variables: {
    //       ...commonVars,
    //       schoolId
    //     }
    //   }).then(res => {
    //     setLicensesData(res.data?.getSchoolLicenses || null)
    //   })
    // }
    // if (districtId) {
    //   districtLicensesQueryFetch({
    //     fetchPolicy,
    //     variables: {
    //       ...commonVars,
    //       districtId
    //     }
    //   }).then(res => {
    //     setLicensesData(res.data?.getDistrictLicenses || null)
    //   })
    // }
  }

  const handleQuerySchoolIds = (value: string, tableState: DataTableState) => {
    const districtId = tableState.filters?.find(i => i.id === "districtId")?.value

    schoolIdsQueryFetch({
      variables: {
        districtIds: districtId ? [districtId] : undefined,
        search: value
      }
    }).then(res => {
      setSchoolIdsData(res.data?.schools?.items || null)
    })
  }

  const handleQueryDistrictIds = (value: string) => {
    districtIdsQueryFetch({
      variables: {
        search: value
      }
    }).then(res => {
      setDistrictIdsData(res.data?.districts?.items || null)
    })
  }

  const handleQueryTeacherIds = (value: string, tableState: DataTableState) => {
    const districtId = props.districtId || tableState.filters?.find(i => i.id === "districtId")?.value
    const schoolId = props.schoolId || tableState.filters?.find(i => i.id === "schoolId")?.value

    if (schoolId) {
      schoolStaffIdsQueryFetch({
        variables: {
          schoolId,
          search: value
        }
      }).then(res => {
        setTeacherIdsData(res.data?.getSchool?.staff?.items || null)
      })
    } else if (districtId) {
      districtStaffIdsQueryFetch({
        variables: {
          districtId,
          search: value
        }
      }).then(res => {
        setTeacherIdsData(res.data?.getDistrict?.staff?.items || null)
      })
    } else {
      staffIdsQueryFetch({
        variables: {
          isStaff: true
        }
      }).then(res => {
        setTeacherIdsData(res.data?.users?.items || null)
      })
    }
  }

  const tableFilters: DataTableFilters = useMemo(() => {
    const teacherFilter: DataTableFilter = {
      id: "assignedTeacher",
      type: "select-single",
      label: "Teacher",
      loading: districtStaffIdsQuery.loading || schoolStaffIdsQuery.loading || staffIdsQuery.loading,
      onUpdateOptions: handleQueryTeacherIds,
      options: [
        {label: "All teachers", value: ""},
        ...(teacherIdsData || []).filter(i => Boolean(i._id)).map(i => ({
          label: i.fullName,
          value: i._id
        }))
      ]
    }

    return {
      main: props.type === "licenseAdmin" ? [
        {
          id: "districtId",
          type: "select-single",
          label: "District",
          loading: districtIdsQuery.loading,
          onUpdateOptions: handleQueryDistrictIds,
          options: [
            {label: "All districts", value: ""},
            ...(districtIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        },
        {
          id: "schoolId",
          type: "select-single",
          label: "School",
          loading: schoolIdsQuery.loading,
          value: selectedSchoolId,
          onUpdateOptions: handleQuerySchoolIds,
          options: [
            {label: "All schools", value: ""},
            ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
              label: i.name,
              value: i._id
            }))
          ]
        }
      ] : [teacherFilter],
      secondary: props.type !== "licenseAdmin" && [
        {
          id: "showExpired",
          type: "toggle",
          label: "View Expired"
        }
      ],
      additional: [
        props.type === "licenseAdmin" && {
          id: "showExpired",
          type: "toggle",
          label: "View Expired"
        },
        teacherFilter
      ]
    }
  }, [
    props.type,
    schoolIdsQuery.loading,
    districtIdsQuery.loading,
    schoolIdsData,
    districtIdsData,
    selectedSchoolId,
    teacherIdsData,
    districtStaffIdsQuery.loading,
    schoolStaffIdsQuery.loading,
    staffIdsQuery.loading
  ])

  async function handleExportLicenses() {
    const headers = await getApiCallHeaders()

    setModal("EXPORT_LOADING")

    exportLicenses().then(res => {
      const url = res.data?.exportLicenses?.url

      return new JsFileDownloader({
        url,
        headers: Object.entries(headers).map(([name, value]) => ({
          name,
          value
        })),
        filename: url.split("?")[0].split("/").pop() || "licenses.csv"
      })
    }).finally(() => {
      setModal(null)
    })
  }

  const handleCancelModal = (onAfterSubmit?: boolean) => {
    setSelectedLicense(null)
    setModal(null)
    onAfterSubmit && handleQuery(query)
  }

  return (
    <Box>
      <DataTable
        schema={tableSchema}
        data={licensesData?.items}
        loading={licensesLoading}
        actionButtons={tableActionButtons}
        error={!!licensesError}
        itemsTotalCount={licensesData?.total}
        lastPage={!licensesData?.hasMore}
        onQueryUpdate={handleQuery}
        search="Search Inventory"
        filters={tableFilters}
        emptyDataMessage={
          <Box py={20}>
            <Typography variant="h6" textAlign="center">
              No available inventory.
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              textAlign="center">
              Flexible licensing options are available to meet the needs of your
              educational institution and students.
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              textAlign="center">
              Please contact our{" "}
              <Link
                underline="always"
                target="_blank"
                color="primary"
                href="https://knowledge-pillars.com/contact-us/">
                sales support
              </Link>{" "}
              for more information
            </Typography>
          </Box>
        }
      />
      <CustomModal
        open={modal === "TRANSFER_LICENSE"}
        onClose={() => handleCancelModal()}
      >
        <TransferLicenseForm
          onAfterSubmit={() => handleCancelModal(true)}
          license={selectedLicense}
          districtId={props.districtId || selectedLicense?.district?._id}
        />
      </CustomModal>
      <CustomModal
        open={modal === "CREATE_LICENSE"}
        onClose={() => handleCancelModal()}
      >
        <CreateLicenseForm
          onAfterSubmit={() => handleCancelModal(true)}
        />
      </CustomModal>
      <CustomModal open={modal === "EXPORT_LOADING"}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={16} my={8}>
          <CircularProgress />
          <Typography variant="h6" align="center" mt={2}>
            Collecting data...
          </Typography>
        </Box>
      </CustomModal>
      <Dialog
        open={!!licenseId}
        maxWidth="lg"
        onClose={() => setLicenseId(null)}>
        <DialogContent>
          <Box display="flex" justifyItems="center" alignItems="center" py={8} mx={20}>
            <Typography variant="h1" color="textPrimary">
              {licenseId}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
