import {gql} from "@apollo/client"

export default gql`
  query GetDistrictIds(
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    districts(
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        country
        name
        state
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
      }
    }
  }
`
