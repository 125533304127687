import {gql} from "@apollo/client"

export default gql`
  query GetClassroomTeachers(
    $classroomId: GraphQLObjectId!
  ) {
    getClassroom(classroomId: $classroomId) {
      teachers {
        _id
        createdAt
        updatedAt
        cognitoUserId
        username
        studentId
        schoolId
        firstName
        lastName
        email
        country
        city
        postcode
        state
        address
        birthdate
        phone
        classroomIds
        fullName
        photoUrl
        totalTimeSpentInLabs
      }
    }
  }
`
