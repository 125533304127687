import {gql} from "@apollo/client"

export default gql`
  query GetActiveLabSession {
    me {
      _id
      activeLabSession {
        _id
        isConnection
        createdAt
        createdByUser {
          _id
          username
        }
        progressOfUser {
          _id
          username
        }
        progressChannel
        progressFound
      }
    }
  }
`
