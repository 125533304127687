import {gql} from "@apollo/client"

export default gql`
  query ExportSessionsByObjectivesResults(
    $sessionType: SessionType! 
    $examCode: String! 
    $classroomId: GraphQLObjectId!
  ) {
    exportSessionsByObjectivesResults(
      sessionType: $sessionType 
      examCode: $examCode 
      classroomId: $classroomId
    ) {
      url
    }
  }
`
