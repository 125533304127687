import {gql} from "@apollo/client"

export default gql`
  query ExportStaffList(
    $districtId: GraphQLObjectId 
    $schoolId: GraphQLObjectId
  ) {
    exportStaffList(
      districtId: $districtId 
      schoolId: $schoolId
    ) {
      url
    }
  }
`
