import {gql} from "@apollo/client"

export default gql`
  query GetUserSchools($userId: GraphQLObjectId!) {
    getUser(userId: $userId) {
      _id
      adminInSchools {
        _id
        name
        state
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
        district {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
      }
      teacherInSchools {
        _id
        name
        state
        remainingLicenseSeats {
          coursesRemainTotal
          examsRemainTotal
          labsRemainTotal
          practicesRemainTotal
        }
        district {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
      }
    }
  }
`
