import {gql} from "@apollo/client"

export default gql`
  query GetUserGeneralInfo($userId: GraphQLObjectId!) {
    getUser(userId: $userId) {
      _id
      studentId
      email
      firstName
      fullName
      lastName
      totalTimeSpentInLabs
      labsLicenseId
      activeLabSession {
        _id
        isConnection
        progressChannel
      }
    }
  }
`
