import {DatePicker} from "@mui/x-date-pickers"
import {debounce} from "lodash"
import React, {useCallback, useEffect, useState} from "react"
import {Box, FormHelperText, TextField} from "@mui/material"


type Value = number | null

interface Props {
  label?: string
  error?: null | boolean | string | string[]
  touched?: boolean
  value?: Value
  onChange: (value: Value) => void
  onBlur?: any
  name: string
}

export default function DateField({
  name,
  label,
  error,
  touched,
  onChange,
  onBlur,
  value: valueProp
}: Props) {
  const [value, setValue] = useState<Value>(valueProp || null)

  useEffect(() => {
    onChange(value)
  }, [value])

  const createDebouncedDateHandler = useCallback(() => {
    return debounce((newValue: Date | null) => {
      if (+newValue < 0 || isNaN(+newValue)) {
        return
      }

      setValue(newValue ? new Date(newValue.setHours(0, 0, 0, 0)).getTime() : null)
    }, 1000, {leading: true, trailing: true})
  }, [])

  const handleChange = createDebouncedDateHandler()

  return (
    <Box position="relative" width="100%">
      <DatePicker
        label={label || "Date"}
        value={value}
        onChange={handleChange}
        minDate={new Date()}
        renderInput={props => (
          <TextField
            {...props}
            name={name}
            fullWidth
            error={!!(touched && error)}
            onBlur={onBlur}
          />
        )}
      />
      {!!(touched && error) && (
        <FormHelperText
          error
          sx={{
            position: "absolute",
            width: "fit-content",
            maxWidth: "100%",
            bottom: "-8px",
            px: "4px",
            ml: "8px",
            mr: "8px",
            background: "white"
          }}>
          {typeof error === "string" ? (
            error
          ) : typeof error === "object" ? (
            error[0]
          ) : null}
        </FormHelperText>
      )}
    </Box>
  )
}
