import {useMutation} from "@apollo/client"
import {
  Box,
  Button,
  Grid,
  TextField
} from "@mui/material"
import UPDATE_USER_BY_ID from "api/apollo/mutations/UPDATE_USER_BY_ID"
import {Formik} from "formik"
import {UpdateUserByIdMutation, UpdateUserByIdMutationVariables, User} from "generated/graphql"
import React, {useMemo} from "react"
import {useDispatch} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  user: Partial<User>
  onAfterSubmit: (id: string) => void
  isTeacher?: boolean
}

export default function UpdateUserForm({
  user,
  onAfterSubmit,
  isTeacher
}: Props) {
  const dispatch = useDispatch()
  const [updateUserById, {loading}] = useMutation<
    UpdateUserByIdMutation,
    UpdateUserByIdMutationVariables
  >(UPDATE_USER_BY_ID)

  const formInitialValues = useMemo(() => {
    return {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      studentId: user.studentId || ""
    }
  }, [user])

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    updateUserById({
      variables: {
        userId: user._id,
        userPayload: {
          firstName: data.firstName,
          lastName: data.lastName,
          studentId: isTeacher ? undefined : data.studentId
        }
      }
    }).then(() => {
      dispatch(notifyUser({
        message: isTeacher ? "TEACHER_UPDATE_SUCCESS" : "USER_UPDATE_SUCCESS",
        variant: "success"
      }))
      onAfterSubmit(user._id)
    }).catch(err => {
      dispatch(handleError(err))
    })
  }


  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First Name is a required field"),
        lastName: Yup.string().max(255).required("Last Name is a required field"),
        email: Yup.string().email("Must be a valid email").max(255).required(),
        studentId: Yup.string().max(255)
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        dirty,
        isValid
      }) => (
        <form onSubmit={handleSubmit}>
          <Box pt={2}>
            <Box pb={6}>
              <Grid container spacing={4} direction="row">
                <Grid item xs={6}>
                  <TextField
                    name="firstName"
                    value={values["firstName"]}
                    error={!!(touched["firstName"] && errors["firstName"])}
                    helperText={touched["firstName"] && errors["firstName"]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="lastName"
                    value={values["lastName"]}
                    error={!!(touched["lastName"] && errors["lastName"])}
                    helperText={touched["lastName"] && errors["lastName"]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    value={values["email"]}
                    error={!!(touched["email"] && errors["email"])}
                    helperText={touched["email"] && errors["email"]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
                {!isTeacher &&
                  <Grid item xs={6}>
                    <TextField
                      name="studentId"
                      value={values["studentId"]}
                      error={!!(touched["studentId"] && errors["studentId"])}
                      helperText={touched["studentId"] && errors["studentId"]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Student ID"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                }
              </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={loading || !(isValid && dirty)}
                variant="contained"
                color="primary"
                type="submit">
                Save Changes
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
