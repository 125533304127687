import {ButtonPropsColorOverrides, ButtonPropsVariantOverrides} from "@mui/material/Button/Button"
import {OverridableStringUnion} from "@mui/types"
import {SortOrder} from "generated/graphql"
import {ReactElement} from "react"

export interface DataTableHeaderTextInteractive {
  element: JSX.Element
}


export interface DataTableTextCol {
  type: "text"
  fieldName: string
  placeholder?: string
  copyAction?: boolean
}

export interface DataTableAvatarCol {
  type: "avatar"
  fieldName: Array<string>
  image: string
}

export interface DataTableActionCol<T = any> {
  type: "actions"
  actions: Array<{
    icon: JSX.Element
    onClick: (data: T) => void
    tooltipText?: string
    disabled?: boolean
  }>
}

export interface DataTableCheckboxCol<T = any> {
  type: "checkbox"
  key: (data: T) => string
  checked?: (data: T) => boolean
  handler?: (selected: boolean, data: T) => void
  disabled?: (data: T) => boolean
  selectAll?: boolean
}

export interface DataTableCustomCol<T = any> {
  type: "custom"
  content: (data: T) => ReactElement | string
}

export type DataTableColConfig = {
  headerText?: string | DataTableHeaderTextInteractive
  headerNoWrap?: boolean
  contentWrap?: "normal" | "pre" | "pre-line" | "nowrap"
  sort?: string | [string, {
    defaultOrderDesc?: boolean
  }]
}

export type DataTableSort = {
  key: string
  order?: SortOrder
}

export type DataTableSchema<T = any> = Array<DataTableColConfig & (
  DataTableTextCol | DataTableAvatarCol | DataTableActionCol | DataTableCheckboxCol<T> | DataTableCustomCol<T>
)>

export interface DataTableProps<T = any> {
  schema: DataTableSchema<T>
  data: Array<T> | null
  loading?: boolean
  loadingMessage?: string
  error?: boolean | string
  rowsPerPageLimit?: 5 | 10 | 25 | 50
  itemsTotalCount?: number
  lastPage?: boolean
  onQueryUpdate?: DataTableQueryUpdate
  onSelectionUpdate?: (selected: DataTableSelectedList) => void
  fullWidth?: boolean
  noWrap?: boolean
  headerNoWrap?: boolean
  contentNoWrap?: boolean
  emptyCondition?: () => boolean
  emptyDataMessage?: ReactElement | string
  defaultSort?: DataTableColConfig["sort"]
  search?: boolean | string | DataTableSearchConfig
  filters?: DataTableFilters
  actionButtons?: DataTableActionButtons
  actions?: DataTableActions
  actionOptions?: DataTableActionOptions
}

export type DataTableState = {
  itemsPerPage: number
  page: number
  filters: Array<{
    type: DataTableFilter["type"]
    id: string
    value: any
  }> | null
  sort: DataTableSort | null
  searchQuery: string | null
}

export type DataTableSelectedList = string[] | null

export type DataTableClearSelectedList = () => void

export type DataTableSearchConfig = {
  label?: string
  disabled?: boolean
  select?: {
    options: Array<{
      label: string
      value: string
    }>
    loading?: boolean
    onUpdateOptions?: (value: string) => void
  }
}

export type DataTableSelectSingleFilter = {
  type: "select-single"
  label?: string
  loading?: boolean
  disabled?: boolean
  width?: number
  options: Array<{
    label: string
    value: string
  }>
  initialValue?: string
  value?: string
  onUpdateOptions?: (inputValue: string, tableState: DataTableState) => void
  loadOnce?: boolean
}

export type DataTableToggleFilter = {
  type: "toggle"
  label?: string
  initialValue?: boolean
  value?: boolean
}

export type DataTableDateFilter = {
  type: "date"
  label?: string
  initialValue?: [number | null, number | null] | null
  value?: [number | null, number | null] | null
}

export type DataTableFilter = {
  id: string
  key?: string
} & (
  DataTableSelectSingleFilter | DataTableToggleFilter | DataTableDateFilter
)

export type DataTableFilters = {
  resettable?: boolean
  main?: [DataTableFilter] | [DataTableFilter, DataTableFilter]
  secondary?: [DataTableFilter] | [DataTableFilter, DataTableFilter]
  additional?: Array<DataTableFilter>
}

type DataTableActionButton<T = any> = {
  key: string
} & ({
  label: string
  onClick: DataTableActionClick
  icon?: JSX.Element
  variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>
  color?: OverridableStringUnion<
    "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    ButtonPropsColorOverrides
  >
  disabled?: boolean
} | {
  type: "custom"
  content: (selected: DataTableSelectedList, clearSelected: DataTableClearSelectedList, data: T) => ReactElement | string
})

export type DataTableActionButtons<T = any> = (
  [DataTableActionButton<T>] | [DataTableActionButton<T>, DataTableActionButton<T>]
)

export type DataTableActionOptions = {
  label: string
  icon?: JSX.Element
  variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>
  isLeftPosition?: boolean
}

export type DataTableActionItems = Array<{
  key: string
  label: string
  onClick: DataTableActionClick
  icon?: JSX.Element
  disabled?: boolean
}>

export type DataTableActions = {
  options: DataTableActionOptions
  items: DataTableActionItems
}

export type DataTableQueryUpdate<T = null> = (state: DataTableState, options?: T) => void

export type DataTableActionClick = (state: DataTableState, selected: DataTableSelectedList, clearSelected: DataTableClearSelectedList) => void

export const isInteractiveHeaderText = (
  el: string | DataTableHeaderTextInteractive
): el is DataTableHeaderTextInteractive => !!(el as DataTableHeaderTextInteractive).element
