import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import SessionsTable from "components/SessionsTable"
import useMainPageLink from "hooks/useMainPageLink"
import React from "react"
import {useSelector} from "store"

export default function ExamResults() {
  const schoolId = useSelector(store => store.schoolSlice.currentSchool)

  const {mainPageLink} = useMainPageLink()

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Exam Results"
      title="Exam Results"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          text: "Exam Results"
        }
      ]}>
      <SessionsTable
        type="exam"
        schoolId={schoolId}
        detailsLink="/my-results/exam-details/"
      />
    </LayoutWithBreadcrumbs>
  )
}
