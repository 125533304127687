import {ExamSessionStatus, TestingSessionStatus} from "generated/graphql"

export default function getIsSessionCompleted(input: ExamSessionStatus | TestingSessionStatus) {
  return [
    ExamSessionStatus.ProctorReviewInProgress,
    ExamSessionStatus.ReviewedByProctor,
    ExamSessionStatus.ViolationReported,
    ExamSessionStatus.Finished,
    TestingSessionStatus.Finished
  ].includes(input)
}
