import {useMutation} from "@apollo/client"
import {Box, Button, TextField, Typography} from "@mui/material"
import REPORT_TASK from "api/apollo/mutations/REPORT_TASK"
import CustomModal from "components/CustomModal"
import {Formik} from "formik"
import {ReportTaskMutation, ReportTaskMutationVariables} from "generated/graphql"
import React, {useMemo} from "react"
import {useDispatch} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  open: boolean
  handleClose: () => void
  taskId: string
}

export default function ReportModal({open, handleClose, taskId}: Props) {
  const dispatch = useDispatch()
  const formInitialValue = useMemo(() => {
    return {
      message: ""
    }
  }, [])

  const [reportTask, {loading}] = useMutation<
    ReportTaskMutation,
    ReportTaskMutationVariables
  >(REPORT_TASK)

  const handleSubmit = (data: typeof formInitialValue) => {
    if (!data || !Object.values(data)?.length) return

    reportTask({
      variables: {
        reportInput: {message: data.message},
        taskId
      }
    }).then(() => {
      dispatch(notifyUser({
        message: "REPORT_TASK_SUCCESS"
      }))
      handleClose()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
    <CustomModal open={open} onClose={handleClose}>
      <Formik
        initialValues={formInitialValue}
        validationSchema={Yup.object().shape({
          message: Yup.string().required("Description is required")
        })}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Box px={4} minWidth="50vw">
              <Typography variant="h5" textAlign="center" p={2}>
                Report Task
              </Typography>
              <Typography variant="h6" mb={2}>
                If you believe there is a problem with this task, provide as much information as possible in the following description field
              </Typography>
              <Box mb={2}>
                <TextField
                  name="message"
                  value={values["message"]}
                  error={!!(touched["message"] && errors["message"])}
                  helperText={touched["message"] && errors["message"]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={6}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" p={4}>
              <Button type="submit" variant="contained" disabled={loading}>
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </CustomModal>
  )
}
