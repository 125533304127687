import React from "react"
import {Navigate} from "react-router-dom"
import useAuth from "hooks/useAuth"
import {Roles} from "types/access"

export default function GuestGuard({children}) {
  const {isAuthenticated, user} = useAuth()

  if (isAuthenticated) {
    return (
      <Navigate to={user?.roles.includes(Roles.Student) ? "/dashboard" : "/"}/>
    )
  }

  return <>{children}</>
}
