import Button from "@mui/material/Button"
import cn from "classnames"
import {LINKS} from "consts/links"
import {TestingSessionStatus} from "generated/graphql"
import ReportModal from "pages/PracticeExamWindow/components/DefaultExamWindow/components/ReportModal"
import {useNavigate} from "react-router"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {selectTaskDetails} from "store/selectors"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  memo
} from "react"
import {makeStyles, useTheme} from "@mui/styles"
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Theme,
  Typography
} from "@mui/material"
import {DefaultExamWindowProps} from "pages/PracticeExamWindow/types.t"
import {
  addFlaggedTaskThunk,
  getTasksAnswers,
  getSessionTasksStatus,
  fetchSessionExamDetails,
  fetchTask,
  finishSessionExam,
  removeFlaggedTaskThunk,
  startSessionExam,
  submitPracticeTask
} from "store/slices/practiceSession/practiceSession"
import Description from "./components/Description"
import SnackbarCredentials from "components/SnackbarCredentials"
import {
  TaskFooterFirstBar,
  TaskFooterSecondBar
} from "@knowledge-pillars-education-inc/kp-fe-lib"
import CustomPdfModal from "./components/CustomPdfModal"
import Workspace from "../Workspace"
import WordpressWorkspace from "components/WordpressWorkspace"
import DynamicIframeWorkspace from "../DynamicIframeWorkspace"
import Sidebar from "./components/Sidebar"

const useStyles = makeStyles<Theme, {
  showDescriptionInFooter: boolean
}>((theme) => {
  return {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    workspace: {
      position: "relative",
      minHeight: "20%"
    },
    workspaceLayout: {
      position: "relative",
      height: "100%",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontFamily: theme.typography.fontFamily
    },
    footerBasic: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
      background: "white",
      zIndex: 1,
      minWidth: "980px"
    },
    loading: ({showDescriptionInFooter}) => {
      return {
        minHeight: "4px",
        background: theme.palette.grey[200],
        cursor: showDescriptionInFooter ? "ns-resize" : "default"
      }
    },
    modalWrapper: {
      maxHeight: "100%",
      overflow: "auto"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      background: "#ffff",
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column"
    },
    progressBar: {
      marginTop: theme.spacing(4)
    }
  }
})

const DefaultExamWindow = React.forwardRef(({
  showLib,
  setShowLib,
  setPopupType,
  isFullScreen,
  onFullButtonClick,
  codeEditorData,
  setCodeEditorData,
  onRefresh,
  setTimer,
  showSidebar
}: DefaultExamWindowProps, ref) => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  const {loading, sessionTasksStatus} = useSelector(state => state.practiceSession)
  const tasksAnswers = useSelector(state => state.practiceSession?.tasksAnswers)
  const showResult = useSelector(state => state.practiceSession.currentSession?.options?.showResult)
  const currentTask = useSelector(state => state.practiceSession.currentTask)
  const currentSession = useSelector(state => state.practiceSession.currentSession || {})
  const question = useSelector(selectTaskDetails)

  const [runDisabled, setRunDisabled] = useState(null)
  const [kpCodeAnswer, setKpCodeAnswer] = useState(null)
  const [answerConfirmed, setAnswerConfirmed] = useState(false)
  const [readyToBeFinished, setReadyToBeFinished] = useState(false)
  const [differentTypeAnswers, setDifferentTypeAnswers] = useState(null)
  const [openHint, setOpenHint] = useState(false)
  const [iframeWasLoaded, setIframeWasLoaded] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [resizer, setResizer] = useState({
    active: false, value: 0
  })

  const currentTaskType = currentTask?.task?.type
  const {
    examCode: sessionExamCode,
    instance: sessionInstance,
    expirationDate,
    tasks: sessionTasks,
    status
  } = currentSession

  const isWordpressExamWithInstance = useMemo(() => {
    return ["wce", "wws", "wes", "wca"].includes(sessionExamCode)
  }, [sessionExamCode])

  const isOffice365Exam = useMemo(() => {
    return sessionExamCode === "wordcs"
  }, [sessionExamCode])

  const isIframeBasedExam = useMemo(() => isWordpressExamWithInstance || isOffice365Exam, [
    isWordpressExamWithInstance, isOffice365Exam
  ])

  const isPracticalTask = useMemo(() => {
    return currentTaskType === "practical"
  }, [currentTaskType])

  const nextTaskId = useMemo(() => {
    if (!sessionTasks) return null

    const currentTaskIndex = sessionTasks.findIndex(i=> i.id === params.taskId)

    return sessionTasks[currentTaskIndex + 1]?.id || null
  }, [sessionTasks, params])

  const prevTaskId = useMemo(() => {
    if (!sessionTasks) return null

    const currentTaskIndex = sessionTasks.findIndex(i => i.id === params.taskId)

    return sessionTasks[currentTaskIndex - 1]?.id || null
  }, [sessionTasks, params])

  const currentTaskIndex = useMemo(() => {
    if (!sessionTasks) return null

    return sessionTasks.findIndex(i => i.id === params.taskId)
  }, [sessionTasks, params])

  const {examCode, sessionId, taskId} = useMemo(() => params || {}, [params])

  // const nextBtnLabel = useMemo(() => {
  //   if (readyToBeFinished) return "Submit"
  //   return "Submit Task"
  // }, [readyToBeFinished])

  const isTaskFlagged = useMemo(() => {
    return sessionTasksStatus?.some((e) => e.taskId === taskId && e.flag)
  }, [sessionTasksStatus, taskId])

  const taskAnswer = useMemo(() => {
    return tasksAnswers?.find((task) => task.taskId === taskId)
  }, [tasksAnswers, taskId])

  const showDescriptionInFooter = useMemo(() => {
    return isIframeBasedExam && isPracticalTask
  }, [isIframeBasedExam, isPracticalTask])

  const footerHeight = useMemo(() => {
    let footerHeight = "113px"

    if (showDescriptionInFooter) {
      footerHeight = "200px"
    }

    return footerHeight
  }, [isWordpressExamWithInstance, showDescriptionInFooter])

  const sidebarWidth = useMemo(() => "340px", [])

  useEffect(() => {
    if (question?.type === "practical") {
      if (taskAnswer?.sourceCode) {
        setCodeEditorData(taskAnswer?.sourceCode)
      } else {
        setCodeEditorData(question?.practicalDetails?.template)
      }
    }
  }, [taskAnswer, question])

  const s = useStyles({
    // isWordpressExam: isWordpressExamWithInstance,
    // isFullScreen,
    // isPractical: isPracticalTask,
    // isOptionsQuestion: ["single", "multiple"].includes(currentTaskType),
    showDescriptionInFooter
  })

  useEffect(() => {
    dispatch(fetchSessionExamDetails(sessionId))
    dispatch(getSessionTasksStatus(sessionId))
    dispatch(getTasksAnswers(sessionId))
  }, [sessionId])

  useEffect(() => {
    resetQuestion()
  }, [taskId])

  useEffect(() => {
    if (!expirationDate) return

    const finishExamAndNavigate = async () => {
      await dispatch(finishSessionExam(params.sessionId))

      navigate(`/my-results/exam-details/${params.sessionId}`, {
        replace: true
      })
    }

    const timeLeft = Date.parse(expirationDate) - Date.now()

    if (timeLeft >= 0) return

    finishExamAndNavigate()
  }, [expirationDate, sessionId])

  useEffect(() => {
    if (
      currentSession?.id &&
      currentTask?.task?.id !== taskId &&
      (status === TestingSessionStatus.InProgress)
    ) {
      dispatch(fetchTask({sessionId, taskId}))
    }
  }, [currentSession?.id, taskId, status])

  useEffect(() => {
    let interval

    if (status === TestingSessionStatus.Finished) {
      navigate(LINKS.dashboard)
      return
    }

    if (status === TestingSessionStatus.Initiating) {
      interval = setInterval(() => {
        // dispatch(getSessionInstanceWPThunk(sessionId))
        dispatch(fetchSessionExamDetails(sessionId))
      }, 5000)
    }

    if (status === TestingSessionStatus.Initiated) {
      clearInterval(interval)
      instanceRunningHandler()
    }
    return () => {
      clearInterval(interval)
    }
  }, [status, sessionId, taskId])

  useEffect(() => {
    window.addEventListener("mouseup", handleDeactivateResizer)

    return () => {
      window.removeEventListener("mouseup", handleDeactivateResizer)
    }
  }, [])

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (showDescriptionInFooter && resizer.active) {
        setResizer(current => ({
          ...current, value: current.value - e.movementY
        }))
      }
    }

    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [resizer, showDescriptionInFooter])

  const wordPressSubmitTask = async () => {
    await dispatch(submitPracticeTask({taskId, sessionId}))
    await dispatch(getTasksAnswers(sessionId))
  }

  const submitTask = async (input?: string) => {
    const answer = input || kpCodeAnswer || ""
    const body = {}

    if (currentTaskType === "practical") {
      body["sourcecode"] = answer
    } else {
      body["answers"] = differentTypeAnswers || []
    }

    await dispatch(submitPracticeTask({taskId, sessionId, body}))
    await dispatch(getTasksAnswers(sessionId))
  }

  const NextTaskFunctionality = async () => {
    if (!isIframeBasedExam && currentTaskType === "practical" && !kpCodeAnswer) {
      setPopupType("notSubmittedTask")

      return false
    }

    const showByTask = showResult === "task"

    if (!answerConfirmed || !showByTask) {
      if (isWordpressExamWithInstance && currentTaskType === "practical") {
        await wordPressSubmitTask()
      } else {
        await submitTask()
      }

      await dispatch(getSessionTasksStatus(sessionId))
    }

    if (!nextTaskId && showByTask) {
      setReadyToBeFinished(true)
      // setAnswerConfirmed(true)

      setPopupType("correctAnswer")

      return false
    }

    if ((answerConfirmed && showByTask) || !showByTask) {
      onNextQuestion()

      return false
    }

    setPopupType("correctAnswer")
    setAnswerConfirmed(true)

    return false
    // await dispatch(getSessionTasksStatus(sessionId))
    // await dispatch(getTasksAnswers(sessionId))
  }

  const handleFlagClickFooter = () => async () => {
    if (isTaskFlagged) {
      await dispatch(removeFlaggedTaskThunk({taskId, sessionId}))
    } else {
      await dispatch(addFlaggedTaskThunk({taskId, sessionId}))
    }

    await dispatch(getSessionTasksStatus(sessionId))
  }

  const onNextQuestion = () => {
    if (!nextTaskId) {
      setPopupType("tasks")

      return
    }

    navigate(`/exam-window/${examCode}/${sessionId}/${nextTaskId}`, {
      replace: true
    })
  }

  const onPrevTaskClick = () => {
    if (!prevTaskId) return

    navigate(`/exam-window/${params.examCode}/${params.sessionId}/${prevTaskId}`, {
      replace: true
    })
  }

  const handleSwitchTask = (value: "next" | "prev") => {
    if (value === "next") {
      if (!nextTaskId) {
        setPopupType("tasks")

        return
      }

      navigate(`/exam-window/${examCode}/${sessionId}/${nextTaskId}`, {
        replace: true
      })
    } else {
      if (!prevTaskId) return

      navigate(`/exam-window/${params.examCode}/${params.sessionId}/${prevTaskId}`, {
        replace: true
      })
    }
  }

  const resetQuestion = () => {
    setReadyToBeFinished(false)
    setKpCodeAnswer(null)
    setDifferentTypeAnswers(null)
    setAnswerConfirmed(false)
    setResizer({active: false, value: 0})
  }

  useImperativeHandle(ref, () => ({
    async nextTask() {
      if (!readyToBeFinished) {
        await NextTaskFunctionality()
        setPopupType(null)
      } else {
        await dispatch(getSessionTasksStatus(sessionId))
        setPopupType("tasks")
      }
    },

    async repeatQuestion() {
      resetQuestion()
      await dispatch(getTasksAnswers(sessionId))
    }
  }))

  const instanceRunningHandler = async () => {
    await dispatch(startSessionExam(sessionId))
    dispatch(fetchSessionExamDetails(sessionId))
  }

  // const handleCodeAnswer = (code: string) => {
  //   setKpCodeAnswer(code)
  //   if (currentTaskType === "practical" && !!code) {
  //     submitTask(code)
  //   }
  // }

  const handleActivateResizer = () => {
    setResizer(current => ({
      ...current,
      active: true
    }))
  }

  const handleDeactivateResizer = () => {
    setResizer(current => ({
      ...current,
      active: false
    }))
  }

  return (
    <Box
      className={s.root}
      sx={{
        width: showSidebar ? (showLib ? `calc(100% - ${sidebarWidth})` : "100%") : undefined,
        transition: "all 0.35s ease-out"
      }}
    >
      {sessionInstance && openHint && (
        <SnackbarCredentials
          open={openHint}
          handleClose={() => setOpenHint(false)}
          username={sessionInstance.username}
          password={sessionInstance.password}
        />
      )}
      <Box
        className={s.workspace}
        sx={{
          maxHeight: !showSidebar && showLib ? `calc(100% - ${footerHeight})` : "100%",
          height: !showSidebar && showLib && showDescriptionInFooter ? `calc(100% - ${footerHeight} - ${resizer.value}px)` : "100%",
          transition: "all 0.35s ease-out"
        }}
      >
        <Grid
          item
          xs={12}
          className={s.workspaceLayout}
          style={{
            userSelect: resizer.active ? "none" : "auto"
          }}>
          {openDoc && (
            <div className={s.modalWrapper}>
              <CustomPdfModal open={openDoc} setOpen={setOpenDoc}/>
            </div>
          )}
          {openReport && (
            <div className={s.modalWrapper}>
              <ReportModal
                open={openReport}
                handleClose={() => setOpenReport(false)}
                taskId={taskId}
              />
            </div>
          )}
          {status !== TestingSessionStatus.InProgress ? (
            <Backdrop className={s.backdrop} open={true}>
              <Typography variant="h6" align="center">
                We are currently preparing your test environment and it may take
                few minutes. <br/>
                <b>Please don't refresh or close the tab.</b>
              </Typography>
              <CircularProgress className={s.progressBar} color="inherit"/>
            </Backdrop>
          ) : isWordpressExamWithInstance && !["single", "multiple"].includes(currentTaskType) ? (
            // Wordpress iframe
            <WordpressWorkspace
              setOpenHint={setOpenHint}
              iframeWasLoaded={iframeWasLoaded}
              setIframeWasLoaded={setIframeWasLoaded}
            />
          ) : (
            isOffice365Exam
            // Office365
            ? <DynamicIframeWorkspace/>
            // SphereEngine
            : <Workspace
              setKpCodeAnswer={setKpCodeAnswer}
              setDifferentTypeAnswers={setDifferentTypeAnswers}
              setRunDisabled={setRunDisabled}
              taskAnswer={taskAnswer}
              codeEditorData={codeEditorData}
              setCodeEditorData={setCodeEditorData}
              tasksAmount={sessionTasks?.length}
              currentTaskNumber={currentTaskIndex + 1}
              onNextClick={NextTaskFunctionality}
              onRefresh={onRefresh}
              nextDisabled={loading || runDisabled}
            />
          )}
        </Grid>
        <Box
          position="absolute"
          right={20}
          bottom={15}
          zIndex={10}
          sx={{
            transition: ".2s",
            opacity: .5,
            "&:hover": {
              opacity: 1,
              transition: ".2s"
            }
          }}
        >
          <Button
            onClick={() => setShowLib(!showLib)}
            sx={{
              transition: ".2s",
              boxShadow: 1,
              minWidth: "auto",
              backgroundColor: theme.palette.white.main,
              opacity: .3,
              "&:hover": {
                background: theme.palette.white.main,
                opacity: 1,
                transition: ".2s"
              }
            }}
          >
            {showLib ? (
              <OpenInFullIcon fontSize="small"/>
            ) : (
              <CloseFullscreenIcon fontSize="small"/>
            )}
          </Button>
        </Box>
      </Box>
      {showSidebar ? (
        <Box
          position="fixed"
          top={0}
          height="100%"
          width="340px"
          boxShadow={1}
          sx={{
            right: showLib ? 0 : "-340px",
            transition: "all 0.35s ease-out"
          }}
        >
          <Sidebar
            onSwitchTask={handleSwitchTask}
            isWpExam={isWordpressExamWithInstance}
            setTimer={setTimer}
            currentTaskNumber={currentTaskIndex + 1}
            numberOfTasks={sessionTasks?.length}
            setOpenHint={setOpenHint}
            setPopupType={setPopupType}
            setOpenReport={setOpenReport}
            onSubmit={NextTaskFunctionality}
            showDescription={showDescriptionInFooter}
            onFullScreen={onFullButtonClick}
            codeAnswer={kpCodeAnswer}
          />
        </Box>
      ) : (
        <Grid
          item
          xs={12}
          className={cn(s.footer, s.footerBasic)}
          sx={{
            height: !showLib ? 0 : showDescriptionInFooter ? `calc(${footerHeight} + ${resizer.value}px)` : footerHeight,
            userSelect: resizer.active ? "none" : "auto",
            minHeight: showLib ? footerHeight : 0,
            maxHeight: "80%",
            overflow: "hidden",
            transition: "all 0.35s ease-out"
          }}
        >
          <Box className={s.loading} onMouseDown={handleActivateResizer}>
            {loading || runDisabled ? <LinearProgress/> : null}
          </Box>
          <TaskFooterFirstBar
            handleFlagClick={handleFlagClickFooter}
            currentTaskNumber={currentTaskIndex + 1}
            tasksAmount={sessionTasks?.length}
            setOpenHint={setOpenHint}
            btnFlaggedDisable={loading}
            setOpenDoc={setOpenDoc}
            openDoc={openDoc}
            setOpenReport={setOpenReport}
            isTaskFlagged={isTaskFlagged}
            questionType={!isWordpressExamWithInstance && question.type}
            examCode={examCode}
            isWordpressExam={isWordpressExamWithInstance || isOffice365Exam}
            onFullButtonClick={onFullButtonClick}
            isFullScreen={isFullScreen}
          />
          {showDescriptionInFooter && (
            <Description/>
          )}
          <TaskFooterSecondBar
            nextDisabled={loading || runDisabled}
            prevDisabled={!prevTaskId}
            onNextClick={NextTaskFunctionality}
            onPrevClick={onPrevTaskClick}
            onNextQuestion={onNextQuestion}
            nextBtnLabel="Submit Task"
            isTaskFlagged={isTaskFlagged}
            skipTaskShow={nextTaskId}
          />
        </Grid>
      )}
    </Box>
  )
})

export default memo(DefaultExamWindow)
