import {Button, Grid} from "@mui/material"
import {makeStyles} from "@mui/styles"
import classNames from "classnames"
import CustomModal from "components/CustomModal"
import React, {useCallback, useEffect, useState} from "react"
import PDFEmbed from "./components/PDFEmbed"
import {pdfjs} from "react-pdf"
import PDF from "assets/pdf/examDocs/PCA_DOC.pdf"

// https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#use-external-cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const useStyles = makeStyles(() => ({
  controlsContainer: {
    position: "absolute",
    bottom: "1%",
    transition: "opacity ease-in-out 0.2s"
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9f9f9",
    dropShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    borderRadius: "4px"
  },
  button: {
    "&:hover": {
      cursor: "pointer"
    }
  }
}))

export default function CustomPdfModal({open, setOpen}) {
  const s = useStyles()

  const [pagesCount, setPagesCount] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const handleDocumentLoadSuccess = useCallback(({numPages}) => {
    setPagesCount(numPages)
  }, [pagesCount])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CustomModal open={open} onClose={handleClose}>
      <PDFEmbed
        currentPage={currentPage}
        pagesCount={pagesCount}
        value={PDF}
        onLoadSuccess={handleDocumentLoadSuccess}>
        <Grid
          item
          container
          className={classNames(s.controlsContainer, "controls-item")}
          alignItems="center">
          {!currentPage && (
            <Grid item className={s.controls}>
              <Button
                className={s.button}
                onClick={() => setCurrentPage(current => current - 1)}
                disabled={currentPage === 1}>
                {"<"}
              </Button>
              <p>
                {currentPage} of {pagesCount}
              </p>
              <Button
                className={s.button}
                onClick={() => setCurrentPage(current => current + 1)}
                disabled={currentPage === pagesCount}>
                {">"}
              </Button>
            </Grid>
          )}
        </Grid>
      </PDFEmbed>
    </CustomModal>
  )
}
