import {gql} from "@apollo/client"

export default gql`
  query GetUserEnrollments($userId: GraphQLObjectId!) {
    getUser(userId: $userId) {
      classroomIds
      classrooms {
        districtId
        assignedTeachers
        _id
        name
        teachers {
          _id
          fullName
          totalTimeSpentInLabs
        }
        district {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        school {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
      }
    }
  }
`
