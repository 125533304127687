import GET_USER from "api/apollo/queries/GET_USER"
import GET_USER_LAB_DETAILS from "api/apollo/queries/GET_USER_LAB_DETAILS"
import apiCall from "api/rest"
import client from "api/apollo"
import {
  GetUserQuery,
  GetUserQueryVariables,
  GetUserLabDetailsQuery,
  GetUserLabDetailsQueryVariables
} from "generated/graphql"
import {IUserInfo} from "types/common"
import {ENDPOINT} from "consts/endpoints"
import {
  IFetchUserServiceMethods,
  IUpdateUserServiceMethods,
  IUserService
} from "types/services/user.service.t"

const UpdateUserServiceMethods: IUpdateUserServiceMethods = {
  registerAsAProctor: () => {
    return apiCall({
      url: ENDPOINT.registerAsProctor,
      method: "POST"
    })
  }
}

const FetchUserServiceMethods: IFetchUserServiceMethods = {
  fetchUserInfo: async () => {
    const {data} = await client.query<GetUserQuery, GetUserQueryVariables>({
      query: GET_USER,
      fetchPolicy: "network-only"
    })

    return data.me as unknown as IUserInfo
  },
  updateCurrentUser: ({payload}) => {
    return apiCall({
      url: ENDPOINT.updateUserInfo,
      method: "PATCH",
      data: payload
    })
  },
  fetchUserLabDetails: async () => {
    const {data} = await client.query<GetUserLabDetailsQuery, GetUserLabDetailsQueryVariables>({
      query: GET_USER_LAB_DETAILS,
      fetchPolicy: "network-only"
    })

    return data.me as Partial<IUserInfo>
  }
}

const userService: IUserService = {
  ...UpdateUserServiceMethods,
  ...FetchUserServiceMethods
}

export default userService
