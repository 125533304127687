import {Box} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import ConfirmationAlert from "components/ConfirmationAlert"
import useKpEditor from "hooks/useKpEditor"
import React, {FC, useEffect, useMemo, useState} from "react"
import {makeStyles} from "@mui/styles"
import {SphereEnginePropsType} from "pages/PracticeExamWindow/types.t"
import CodeEditor from "@knowledge-pillars-education-inc/code-editor"
import {useSelector} from "store"
import practicalExamStorage from "utils/practicalExamStorage"

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    "& .kps-out-collapse": {
      cursor: "pointer"
    }
  },
  spinner: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    zIndex: 2
  }
}))

export const SphereEngineWorkspace:FC<SphereEnginePropsType> = ({
  question,
  setKpCodeAnswer,
  setRunDisabled,
  codeEditorData,
  setCodeEditorData,
  onRefresh
}) => {
  const s = useStyles()

  const {problem, practicalDetails} = question

  const {compileAnswer, runDisabled, onRunCode} = useKpEditor(question)

  const currentTask = useSelector(state => state.practiceSession.currentTask)
  const currentSession = useSelector(state => state.practiceSession.currentSession || {})

  const [localAnswer, setLocalAnswer] = useState(codeEditorData)
  const [showContinueDialog, setShowContinueDialog] = useState(false)
  const [savingStatus, setSavingStatus] = useState<string | null>(null)

  const savedProgress = useMemo(() => {
    return practicalExamStorage.getItem(currentSession._id, currentTask.task._id)
  }, [])

  const alert = useMemo(() => {
    if (savingStatus) {
      return {
        name: savingStatus,
        code: 0
      }
    } else {
      return compileAnswer ? {
        code: compileAnswer.status_code,
        name: compileAnswer.status_name,
        labels: {
          15: "compiled"
        }
      } : null
    }
  }, [savingStatus, compileAnswer])

  useEffect(() => {
    if (savedProgress) {
      setShowContinueDialog(true)
    }
  }, [savedProgress])

  useEffect(() => {
    setLocalAnswer(codeEditorData)
  }, [codeEditorData])

  useEffect(() => {
    setRunDisabled(runDisabled)
  }, [runDisabled])

  const handleRestoreProgress = () => {
    if (savedProgress) {
      setKpCodeAnswer(savedProgress.value)
      setCodeEditorData(savedProgress.value)
    }

    setShowContinueDialog(false)
  }

  const handleAnswerChange = (value: string) => {
    setKpCodeAnswer(value)
  }

  const handleRunCode = (code: string) => {
    onRunCode(code)
    setKpCodeAnswer(code || " ")
    practicalExamStorage.setItem(currentSession._id, currentTask.task._id, code)
  }

  const handleSave = (code: string) => {
    if (currentSession && currentTask && codeEditorData) {
      setSavingStatus("saving...")

      practicalExamStorage.setItem(currentSession._id, currentTask.task._id, code)

      setTimeout(() => {
        setSavingStatus("saved")

        setTimeout(() => {
          setSavingStatus(null)
        }, 2000)
      }, 500)
    }
  }

  return (
    <Box className={s.root}>
      {!problem ? (
        <Box className={s.spinner}>
          <CircularProgress color="inherit" disableShrink/>
        </Box>
      ) : (
        <CodeEditor
          overview={{
            input: problem.body
          }}
          code={{
            lang: practicalDetails.language,
            template: question?.practicalDetails?.template,
            input: localAnswer,
            onRun: handleRunCode,
            onSave: handleSave,
            onRefresh,
            submissionLoading: runDisabled,
            alert
          }}
          compiled={compileAnswer?.cmpinfo || compileAnswer?.error || compileAnswer?.output || compileAnswer?.signal_desc}
          onChange={handleAnswerChange}
        />
      )}
      <ConfirmationAlert
        isOpen={showContinueDialog}
        setOpen={setShowContinueDialog}
        handleConfirm={handleRestoreProgress}
        handleCancel={() => setShowContinueDialog(false)}
        dialogTitle="Continue where you left off?"
        cancelText={{color: "warning", text: "No"}}
        confirmText={{color: "success", text: "Yes"}}
      />
    </Box>
  )
}
