import React, {useMemo} from "react"
import {makeStyles} from "@mui/styles"

interface Props {
  correctAnswer: Array<{
    id: string
    text: string
    position?: number
  }>
}

const useStyles = makeStyles({
  root: {
    paddingTop: "10px"
  },
  lineWrap: {
    margin: "0 0px 10px 0px",
    display: "flex",
    padding: "5px",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "1px solid #ddd",
    borderRadius: "4px"
  },
  questionSide: {
    flexBasis: "50%"
  },
  answerSide: {
    textAlign: "left",
    flexBasis: "50%"
  },
  dividerArrow: {
    padding: "0 5px",
    alignSelf: "center",
    "& svg": {
      width: "0.5em",
      margin: "0 10px"
    }
  }
})

export default function MatchAnswer({
  correctAnswer
}: Props) {
  const {questionSide, lineWrap, root} = useStyles()

  const sortedList = useMemo(() => {
    return (correctAnswer || []).slice().sort((a, b) => a.position - b.position)
  }, [correctAnswer])

  return (
    <div className={root}>
      {sortedList.map(i => (
        <div key={i.id} className={lineWrap}>
          <div className={questionSide}>
            {i.text}
          </div>
        </div>
      ))}
    </div>
  )
}
