import {SxProps} from "@mui/material"
import SelectAutocomplete from "components/SelectAutocomplete"
import {debounce, throttle} from "lodash"
import React, {useCallback, useEffect, useState} from "react"

type Props = {
  id: string
  label?: string
  icon?: JSX.Element
  sx?: SxProps
  loading?: boolean
  error?: null | boolean | string | string[]
  touched?: boolean
  disabled?: boolean
  options?: Array<{
    label: string
    value: string
  }>
  value?: string
  onChange?: (value: string) => void
  onUpdateOptions?: (inputValue: string) => void
  loadOnce?: boolean
}

export default function SelectAsync({
  id,
  label,
  icon,
  sx,
  loading,
  error,
  touched,
  disabled,
  options,
  value: valueProp,
  onChange,
  onUpdateOptions,
  loadOnce
}: Props) {
  const [loaded, setLoaded] = useState(false)
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [value, setValue] = useState(valueProp || "")

  useEffect(() => {
    if (valueProp !== value) {
      setValue(valueProp || "")
    }
  }, [valueProp])

  useEffect(() => {
    if ((focused || inputValue) && !!onUpdateOptions) {
      if (loadOnce && !loaded) {
        setLoaded(true)
        onUpdateOptions("")
      } else if (!loadOnce) {
        handleUpdateOptions(onUpdateOptions, inputValue)
      }
    }
  }, [focused, inputValue, loaded])

  const handleUpdate = useCallback(throttle((newValue: typeof valueProp) => {
    onChange(newValue)
  }, 900), [])

  useEffect(() => {
    if (onChange) {
      handleUpdate(value)
    }
  }, [value])

  const handleFocus = () => {
    if (!focused) {
      setFocused(true)
    }
  }

  const handleBlur = () => {
    if (focused && !loadOnce) {
      setFocused(false)
    }
  }

  const handleUpdateOptions = useCallback(debounce((cb: typeof onUpdateOptions, newValue: string) => {
    if (cb) {
      cb(newValue)
    }
  }, 900), [value])

  const handleChange = (newValue: string) => {
    setValue(newValue)
  }

  return (
    <SelectAutocomplete
      id={id}
      label={label}
      error={error}
      touched={touched}
      sx={sx}
      icon={icon}
      loading={loading}
      disabled={disabled}
      options={options}
      noOptionsText="Nothing found"
      noFilterOptions={!loadOnce}
      value={value}
      onChange={handleChange}
      onInputChange={setInputValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  )
}
