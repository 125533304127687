import {MoreVert} from "@mui/icons-material"
import {
  DataTableActionItems,
  DataTableActionOptions,
  DataTableClearSelectedList,
  DataTableSelectedList,
  DataTableState
} from "components/DataTable/types.t"
import React, {useEffect, useRef, useState} from "react"
import {
  Box, Button, ClickAwayListener, Grow,
  ListItemIcon, ListItemText,
  MenuItem, MenuList, Paper, Popper
} from "@mui/material"

interface Props {
  state: DataTableState
  selected: DataTableSelectedList
  clearSelected: DataTableClearSelectedList
  id: string
  actions: DataTableActionItems
  options?: DataTableActionOptions
}

export default function ActionsMenu({
  state,
  selected,
  clearSelected,
  id,
  actions,
  options
}: Props) {
  const key = `DataTable-actions-button-${id}`

  const [open, setOpen] = useState(false)

  const anchorRef = useRef(null)
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  const handleClick = (cb: DataTableActionItems[0]["onClick"]) => {
    cb(state, selected, clearSelected)

    setOpen(false)
  }

  return (
    <Box position="relative" zIndex={2}>
      <Button
        ref={anchorRef}
        id={key}
        aria-controls={open ? key : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{px: 1.5, py: 1.5, minWidth: "unset"}}
        onClick={handleToggle}
        startIcon={options?.icon}
        variant={options.variant}
      >
        {options.label || <MoreVert/>}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        sx={{zIndex: 1}}
        transition>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={key}
                  aria-labelledby={key}
                  onKeyDown={handleListKeyDown}>
                  {actions.map(i => (
                    <MenuItem key={i.key} onClick={() => handleClick(i.onClick)} disabled={i.disabled}>
                      {i.icon && (
                        <ListItemIcon>
                          {i.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText>
                        {i.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
