import {User} from "generated/graphql"
import {Roles} from "types/access"

export const getUserRoles = (input: User) => {
  const list: Roles[] = []

  if (input.isAdmin) {
    list.push(Roles.Admin)
  }

  if (input.adminInDistrictIds.length || input.isAdmin) {
    list.push(Roles.District_Admin)
  }

  if (input.adminInSchoolIds.length || list.includes(Roles.District_Admin)) {
    list.push(Roles.School_Admin)
  }

  if (input.teacherInSchoolIds.length) {
    list.push(Roles.Teacher)
  }

  if (list.length === 0 && input.schoolId) {
    list.push(Roles.Student)
  }

  return list
}

export const isRouteAllowed = (
  routeGroups: Array<string>,
  roles: Array<string>
) => {
  if (
    roles?.length &&
    roles?.includes(Roles.Admin) &&
    roles?.includes(Roles.District_Admin) &&
    !routeGroups?.includes(Roles.Student)
  )
    return true

  if (!routeGroups?.length) return true

  return routeGroups.some((routeGroup) => roles?.includes(routeGroup))
}
