import {LINKS} from "consts/links"
import Certificate from "pages/Certificate"
import {useMemo} from "react"
import {useLocation} from "react-router-dom"
import Wrapper from "Wrapper"

export default function App() {
  const location = useLocation()

  const isCertificateTemplatePage = useMemo(() => (
    location.pathname.includes(LINKS.certificateTemplate)
  ), [location])

  return isCertificateTemplatePage ? <Certificate/> : <Wrapper/>
}
