import {gql} from "@apollo/client"

export default gql`
  query GetDistrictSchoolIds(
    $districtId: GraphQLObjectId!
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    getDistrict(districtId: $districtId) {
      schools(
        search: $search
        sortBy: $sortBy
        order: $order
        offset: $offset
        take: $take
      ) {
        total
        hasMore
        items {
          _id
          name
        }
      }
    }
  }
`
