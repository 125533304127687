import {gql} from "@apollo/client"

export default gql`
  mutation CreateSubLicense(
    $quota: LicenseQuotaInput!
    $fromLicenseId: GraphQLObjectId!
    $scope: LicenseScopeInput
    $newLicenseName: String!
  ) {
    createSubLicense(
      quota: $quota
      fromLicenseId: $fromLicenseId
      scope: $scope
      newLicenseName: $newLicenseName
    ) {
      _id
    }
  }
`
