import {gql} from "@apollo/client"

export default gql`
  query GetLicenses(
    $showExpired: Boolean 
    $districtId: GraphQLObjectId 
    $schoolId: GraphQLObjectId 
    $search: String 
    $assignedTeacher: GraphQLObjectId 
    $sortBy: String 
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    licenses(
      showExpired: $showExpired 
      districtId: $districtId 
      schoolId: $schoolId 
      search: $search 
      assignedTeacher: $assignedTeacher 
      sortBy: $sortBy 
      order: $order 
      offset: $offset 
      take: $take
    ) {
      hasMore
      items {
        _id
        courses {
          id
          title {
            raw
            rendered
          }
        }
        isUniversalLicense
        coursesRemain
        coursesInitial
        name
        examVouchersRemain
        examVouchersInitial
        practiceVouchersInitial
        practiceVouchersRemain
        examCodes
        expirationDate
        labsInitial
        labsRemain
        teachers {
          fullName
        }
        school {
          name
        }
        district {
          _id
          name
        }
      }
      total
    }
  }
`
