import AdminLayout from "components/AdminLayout"
import AuthGuard from "components/AuthGuard"
import DashboardLayout from "components/DashboardLayout"
import GuestGuard from "components/GuestGuard"
import LoadingScreen from "components/LoadingScreen"
import MainLayout from "components/MainLayout"
import {LINKS} from "consts/links"
import AddExamByVoucher from "pages/AddExamByVoucher"
import AdditionalExamInfo from "pages/AdditionalExamInfo"
import AddTestingGroup from "pages/AddTestingGroup"
import RegisterAsProctor from "pages/authentication/RegisterAsProctor"
import CreateClassroom from "pages/CreateClassroom"
import DashboardExamsSelection from "pages/DashboardExamsSelection"
import ExamResults from "pages/ExamResults"
import LicenseList from "pages/LicenseList"
import ManageClassroom from "pages/ManageClassroom"
import ManageClassrooms from "pages/ManageClassrooms"
import MyClassrooms from "pages/MyClassrooms"
import PracticeExamWindow from "pages/PracticeExamWindow"
import WPLabWindow from "pages/WPLabWindow"
import PracticeModeOptions from "pages/PracticeModeOptions"
import PracticeResults from "pages/PracticeResults"
import SchoolPersonnel from "pages/SchoolPersonnel"
import SessionDetails from "pages/SessionDetails"
import ExamPassingRate from "pages/ExamPassingRate"
import UserDetails from "pages/UserDetails"
import TestingGroupDetails from "pages/TestingGroupDetails"
import TestingGroups from "pages/TestingGroups"
import {lazy, Suspense} from "react"
import {NonIndexRouteObject} from "react-router"
import {Roles} from "types/access"
import {isRouteAllowed} from "utils/security"

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen/>}>
    <Component {...props}/>
  </Suspense>
)

// Auth
const Login = Loadable(lazy(() => import("pages/authentication/Login")))
const PasswordRecovery = Loadable(lazy(() => import("pages/authentication/PasswordRecovery")))
const PasswordReset = Loadable(lazy(() => import("pages/authentication/PasswordReset")))
const Register = Loadable(lazy(() => import("pages/authentication/Register")))
const VerifyCode = Loadable(lazy(() => import("pages/authentication/VerifyCode")))
const Account = Loadable(lazy(() => import("pages/dashboard/Account")))

// Dashboard
const PracticeTests = Loadable(lazy(() => import("pages/dashboard/PracticeTests")))
const TermsAndContions = Loadable(lazy(() => import("pages/TermsAndConditions")))
const PrivacyPolicy = Loadable(lazy(() => import("pages/PrivacyPolicy")))
const FAQs = Loadable(lazy(() => import("pages/FAQs")))
const RedeemVoucher = Loadable(lazy(() => import("pages/dashboard/RedeemVoucher")))
const PrepareExam = Loadable(lazy(() => import("pages/dashboard/PrepareExam")))
const StudentExamResults = Loadable(lazy(() => import("pages/StudentExamResults")))
const StudentPracticeResults = Loadable(lazy(() => import("pages/StudentPracticeResults")))
const NotFound = Loadable(lazy(() => import("pages/NotFound")))
const SelectExamMode = Loadable(lazy(() => import("pages/SelectExamMode")))
const PracticeAnswers = Loadable(lazy(() => import("pages/PracticeAnswers")))
const Courses = Loadable(lazy(() => import("pages/Courses")))
const Course = Loadable(lazy(() => import("pages/Course")))
// const CourseDemo = Loadable(lazy(() => import("pages/CourseDemo")))
const Admin = Loadable(lazy(() => import("pages/Admin")))
const AdminSchools = Loadable(lazy(() => import("pages/Admin/Schools")))
const AdminSchool = Loadable(lazy(() => import("pages/Admin/SchoolDetails")))
const AdminUsers = Loadable(lazy(() => import("pages/Admin/Users")))
const AdminExamResults = Loadable(lazy(() => import("pages/Admin/ExamResults")))
const AdminDistrict = Loadable(lazy(() => import("pages/Admin/DistrictDetails")))
const AdminTasks = Loadable(lazy(() => import("pages/Admin/Tasks")))
const AdminTask = Loadable(lazy(() => import("pages/Admin/TaskDetails")))
const AdminExams = Loadable(lazy(() => import("pages/Admin/Exams")))
const AdminExam = Loadable(lazy(() => import("pages/Admin/ExamDetails")))
const AdminLicenses = Loadable(lazy(() => import("pages/Admin/Licenses")))
const CodeEditorDemo = Loadable(lazy(() => import("pages/Admin/CodeEditorDemo")))
const DistrictSchools = Loadable(lazy(() => import("pages/DistrictAdmin/Schools")))
const DistrictExamSessions = Loadable(lazy(() => import("pages/DistrictAdmin/ExamSessions")))
const DistrictPracticeSessions = Loadable(lazy(() => import("pages/DistrictAdmin/PracticeSessions")))
const DistrictLicenseList = Loadable(lazy(() => import("pages/DistrictAdmin/LicenseList")))
const DistrictPersonnel = Loadable(lazy(() => import("pages/DistrictAdmin/Personnel")))
const Settings = Loadable(lazy(() => import("pages/Settings")))
const DistrictLicenseLogs = Loadable(lazy(() => import("pages/DistrictAdmin/LicenseLogs")))
const AdminLicenseLogs = Loadable(lazy(() => import("pages/Admin/LicenseLogs")))
const AdminPersonnel = Loadable(lazy(() => import("pages/Admin/Personnel")))
const DistrictStudents = Loadable(lazy(() => import("pages/DistrictAdmin/Students")))
const AdditionalWPLabInfo = Loadable(lazy(() => import("pages/AdditionalWPLabInfo")))
const Students = Loadable(lazy(() => import("pages/Students")))
const LabList = Loadable(lazy(() => import("pages/LabList")))
const DistrictLabList = Loadable(lazy(() => import("pages/DistrictAdmin/LabList")))
const AdminLabList = Loadable(lazy(() => import("pages/Admin/LabList")))
const AdminTaskReports = Loadable(lazy(() => import("pages/Admin/TaskReports")))
const AdminTaskReport = Loadable(lazy(() => import("pages/Admin/TaskReportDetails")))
const ExtendedLabTime = Loadable(lazy(() => import("pages/ExtendedSessionTime")))
const DistrictExtendedSessionTime = Loadable(lazy(() => import("pages/DistrictAdmin/ExtendedSessionTime")))
const AdminExtendedSessionTime = Loadable(lazy(() => import("pages/Admin/ExtendedSessionTime")))
const CertificateDetails = Loadable(lazy(() => import("pages/CertificateDetails")))

interface ChildrenObjectWithGroups extends NonIndexRouteObject {
  groups?: Roles[]
}

export interface RouteObjectWithRoute extends ChildrenObjectWithGroups {
  children: ChildrenObjectWithGroups[]
}

export const routes: RouteObjectWithRoute[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout/>
      </AuthGuard>
    ),
    children: [
      {
        path: "/dashboard",
        element: <DashboardExamsSelection/>,
        groups: [Roles.Student]
      },
      {
        path: "/my-classrooms",
        element: <MyClassrooms/>,
        groups: [Roles.Student]
      },
      {
        path: "/exam-window/:examCode/:sessionId/:taskId",
        element: <PracticeExamWindow/>
      },
      {
        path: `${LINKS.wpLabWindow}/:examCode/:labSessionId`,
        element: <WPLabWindow isStudent/>
      },
      {
        path: "/practice-answers/:sessionId",
        element: <PracticeAnswers/>
      },
      {
        path: "/redeem-key",
        element: <AddExamByVoucher/>,
        groups: [Roles.Student]
      },
      {
        path: `/practice-tests${LINKS.selectExamMode}/:examName`,
        element: <SelectExamMode/>
      },
      {
        path: "/practice-tests/add-exam-by-voucher",
        element: <AddExamByVoucher/>
      },
      {
        path: "/practice-tests/add-exam-by-voucher/:examName",
        element: <AddExamByVoucher/>
      },
      {
        path: "/practice-tests",
        element: <PracticeTests/>,
        groups: [Roles.Student]
      },
      {
        path: "/redeem-voucher",
        element: <RedeemVoucher/>
      },
      {
        path: "/buy-new-voucher",
        element: <RedeemVoucher/>
      },
      {
        path: "/prepare-exam",
        element: <PrepareExam/>
      },
      {
        path: "/my-results",
        element: <StudentExamResults/>,
        groups: [Roles.Student]
      },
      {
        path: "/practice-results",
        element: <StudentPracticeResults/>,
        groups: [Roles.Student]
      },
      {
        path: "/my-results/exam-details/:id",
        element: (
          <SessionDetails
            breadcrumbs={[
              {
                text: "Dashboard",
                path: "/"
              },
              {
                text: "Results",
                path: "VAR"
              }
            ]}
          />
        )
      },
      {
        path: "/practice-results/exam-details/:id",
        element: (
          <SessionDetails
            isPractice
            breadcrumbs={[
              {
                text: "Dashboard",
                path: "/"
              },
              {
                text: "Practice Results",
                path: "VAR"
              }
            ]}
          />
        )
      },
      {
        path: `${LINKS.selectExamMode}/:examName`,
        element: <SelectExamMode/>
      },
      {
        path: `${LINKS.additionalWPLabInfo}/:examName`,
        element: <AdditionalWPLabInfo/>
      },
      {
        path: `${LINKS.selectModeOptions}/:examName`,
        element: <PracticeModeOptions/>
      },
      {
        path: `/practice-tests${LINKS.selectModeOptions}/:examName`,
        element: <PracticeModeOptions/>
      },
      {
        path: `${LINKS.addExamByVoucher}`,
        element: <AddExamByVoucher/>
      },
      {
        path: `${LINKS.addExamByVoucher}/:examName`,
        element: <AddExamByVoucher/>
      },
      {
        path: `${LINKS.testingGroup}/:testingGroupId`,
        element: <TestingGroupDetails/>
      },
      /*{
        path: `${LINKS.examAnalytics}`,
        element: <ExamAnalytics/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District]
      },*/
      {
        path: `${LINKS.examResults}`,
        element: <ExamResults/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.studentPracticeResults}`,
        element: <PracticeResults/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.schoolPersonnel}`,
        element: <SchoolPersonnel/>,
        groups: [
          Roles.School_Admin,
          Roles.Teacher
        ]
      },
      {
        path: `${LINKS.schoolPersonnel}/:id`,
        element: (
          <UserDetails
            type="school"
            userType="teacher"
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.classrooms
              }, {
                text: "School Personnel",
                path: LINKS.schoolPersonnel
              }
            ]}
          />
        ),
        groups: [
          Roles.School_Admin,
          Roles.Teacher
        ]
      },
      {
        path: `${LINKS.addTestingGroup}`,
        element: <AddTestingGroup/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.licenseListPage}`,
        element: <LicenseList/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      // {
      //   path: `${LINKS.licenseLogs}`,
      //   element: <LicenseLogs/>,
      //   groups: [
      //     Roles.School_Admin,
      //     Roles.Sme,
      //     Roles.Teacher,
      //     Roles.District_Admin
      //   ]
      // },
      {
        path: `${LINKS.testingGroup}`,
        element: <TestingGroups/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.addEditTestingGroup}/:groupId`,
        element: <AddTestingGroup/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      // {
      //   path: "manage-students",
      //   element: <ManageStudents/>,
      //   groups: [
      //     Roles.School_Admin,
      //     Roles.Sme,
      //     Roles.Teacher,
      //     Roles.District_Admin
      //   ]
      // },
      {
        path: `${LINKS.studentsListPage}`,
        element: <Students/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: "students/:id",
        element: (
          <UserDetails
            type="school"
            userType="student"
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.classrooms
              },
              {
                text: "Students",
                path: LINKS.studentsListPage
              }
            ]}
          />
        ),
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.studentWpLabWindow}/:labSessionId`,
        element: <WPLabWindow navigatePath={LINKS.labList}/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.labList}`,
        element: <LabList/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.extendedTime}`,
        element: <ExtendedLabTime/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: LINKS.classrooms,
        element: <ManageClassrooms/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.classrooms}/:classroomId`,
        element: <ManageClassroom/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      {
        path: `${LINKS.createClassroom}`,
        element: <CreateClassroom/>,
        groups: [
          Roles.School_Admin,
          Roles.Sme,
          Roles.Teacher,
          Roles.District_Admin
        ]
      },
      /*{
        path: LINKS.classroomListPage,
        element: <ClassroomListPage/>,
        groups: [Group.School_Admin, Group.Sme, Group.Teacher, Group.District],
      },*/
      {
        path: `${LINKS.additionalExamInfoPage}/:examName`,
        element: <AdditionalExamInfo/>,
        groups: [Roles.Student]
      },
      {
        path: `${LINKS.registerAsProctor}`,
        element: <RegisterAsProctor/>,
        groups: [Roles.Teacher, Roles.School_Admin]
      },
      // {
      //   path: `${LINKS.assignPracticeTestsPage}`,
      //   element: <AssignPracticeTests/>,
      //   groups: [Roles.Teacher, Roles.School_Admin, Roles.District_Admin]
      // },
      {
        path: LINKS.settings,
        element: <Settings/>,
        groups: [Roles.School_Admin]
      }
    ]
  },
  {
    path: "c/:id" ,
    element: <MainLayout noSidebar/>,
    children: [{
      path: "",
      element: <CertificateDetails/>
    }]
  },
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login/>
          </GuestGuard>
        )
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery/>
      },
      {
        path: "password-reset",
        element: <PasswordReset/>
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register/>
          </GuestGuard>
        )
      },
      {
        path: "verify-code",
        element: <VerifyCode/>
      }
    ]
  },
  {
    path: "*",
    element: <MainLayout/>,
    children: [
      {
        path: "404",
        element: <NotFound/>
      },
      {
        path: "*",
        element: <NotFound/>
      }
    ]
  },
  {
    element: <MainLayout/>,
    path: "/account",
    children: [
      {
        path: "",
        element: <Account/>,
        groups: [
          Roles.Student,
          Roles.Sme,
          Roles.School_Admin,
          Roles.Admin,
          Roles.District_Admin,
          Roles.Teacher
        ]
      }
    ]
  },
  {
    path: "terms-and-conditions",
    element: <MainLayout/>,
    children: [
      {
        path: "",
        element: <TermsAndContions/>
      }
    ]
  },
  {
    path: "privacy-policy",
    element: <MainLayout/>,
    children: [
      {
        path: "",
        element: <PrivacyPolicy/>
      }
    ]
  },
  {
    path: "faqs",
    element: <MainLayout/>,
    children: [
      {
        path: "",
        element: <FAQs/>
      }
    ]
  },
  {
    path: LINKS.courses,
    element: <MainLayout/>,
    children: [
      {
        path: "",
        element: <Courses/>
      }
    ]
  },
  {
    path: `${LINKS.courses}/:id/*`,
    element: (
      <MainLayout noSidebar>
        <Course/>
      </MainLayout>
    ),
    children: [
      {
        path: "",
        element: <Course/>
      }
    ]
  },
  // {
  //   path: "/course-demo",
  //   element: (
  //     <MainLayout noSidebar/>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <CourseDemo/>
  //     }
  //   ]
  // },
  {
    path: LINKS.districtAdmin,
    element: (
      <AuthGuard rolesAllowed={[Roles.District_Admin]}>
        <MainLayout type="district"/>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DistrictSchools/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "students",
        element: <DistrictStudents/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "students/:id",
        element: (
          <UserDetails
            type="district"
            userType="student"
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.districtAdmin
              },
              {
                text: "Students",
                path: LINKS.districtAdminStudents
              }
            ]}
          />
        ),
        groups: [Roles.District_Admin]
      },
      {
        path: "exam-sessions",
        element: <DistrictExamSessions/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "exam-sessions/:id",
        element: <SessionDetails
          isDistrict
          breadcrumbs={[
            {
              text: "Dashboard",
              path: LINKS.districtAdmin
            },
            {
              text: "Exam Sessions",
              path: LINKS.districtAdminExamSessions
            }
          ]}
        />,
        groups: [Roles.District_Admin]
      },
      {
        path: `${LINKS.districtAdminExtendedTime}`,
        element: <DistrictExtendedSessionTime/>,
        groups: [Roles.District_Admin]
      },
      {
        path: LINKS.districtAdminPracticeSessions,
        element: <DistrictPracticeSessions/>,
        groups: [Roles.District_Admin]
      },
      {
        path: `${LINKS.districtAdminPracticeSessions}/:id`,
        element: <SessionDetails
          isDistrict
          isPractice
          breadcrumbs={[
            {
              text: "Dashboard",
              path: LINKS.districtAdmin
            },
            {
              text: "Practice Sessions",
              path: LINKS.districtAdminPracticeSessions
            }
          ]}
        />,
        groups: [Roles.District_Admin]
      },
      {
        path: "exam-passing-rate",
        element: (
          <ExamPassingRate
            type="district"
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.districtAdmin
              }
            ]}
          />
        ),
        groups: [Roles.District_Admin]
      },
      {
        path: "personnel",
        element: <DistrictPersonnel/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "personnel/:id",
        element: (
          <UserDetails
            type="district"
            userType="teacher"
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.districtAdmin
              }, {
                text: "District Personnel",
                path: LINKS.districtAdminPersonnel
              }
            ]}
          />
        ),
        groups: [Roles.District_Admin]
      },
      {
        path: "management-license-list",
        element: <DistrictLicenseList/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "license-logs",
        element: <DistrictLicenseLogs/>,
        groups: [Roles.District_Admin]
      },
      {
        path: "exam-results/exam-details/:id",
        element: (
          <SessionDetails
            isDistrict
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.districtAdmin
              }
            ]}
          />
        )
      },
      {
        path: "practice-results/exam-details/:id",
        element: (
          <SessionDetails
            isPractice
            isDistrict
            breadcrumbs={[
              {
                text: "Dashboard",
                path: LINKS.districtAdmin
              }
            ]}
          />
        )
      },
      {
        path: "wp-labs",
        element: <DistrictLabList/>,
        groups: [Roles.District_Admin]
      },
      {
        path: `${LINKS.districtAdminStudentWpLabWindow}/:labSessionId`,
        element: <WPLabWindow navigatePath={LINKS.districtAdminLabList}/>,
        groups: [Roles.District_Admin]
      }
    ]
  },
  {
    path: LINKS.admin,
    element: (
      <AuthGuard rolesAllowed={[Roles.Admin]}>
        <AdminLayout/>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Admin/>,
        groups: [Roles.Admin]
      },
      {
        path: "districts/:id",
        element: <AdminDistrict/>,
        groups: [Roles.Admin]
      },
      {
        path: "schools",
        element: <AdminSchools/>,
        groups: [Roles.Admin]
      },
      {
        path: "schools/:id",
        element: <AdminSchool/>,
        groups: [Roles.Admin]
      },
      {
        path: "users",
        element: <AdminUsers/>,
        groups: [Roles.Admin]
      },
      {
        path: "users/:id",
        element: (
          <UserDetails
            type="admin"
            breadcrumbs={[
              {
                text: "Admin"
              },
              {
                path: LINKS.adminUsers,
                text: "Users"
              }
            ]}
          />),
        groups: [Roles.Admin]
      },
      {
        path: "exam-results",
        element: <AdminExamResults/>,
        groups: [Roles.Admin]
      },
      {
        path: "examResults/:id",
        element: (
          <SessionDetails
            breadcrumbs={[
              {
                text: "Admin"
              },
              {
                text: "Exam Results",
                path: "/admin/exam-results"
              }
            ]}
          />
        ),
        groups: [Roles.Admin]
      },
      {
        path: "exam-passing-rate",
        element: (
          <ExamPassingRate
            type="admin"
            breadcrumbs={[
              {
                text: "Admin"
              }
            ]}
          />
        ),
        groups: [Roles.Admin]
      },
      {
        path: "tasks",
        element: <AdminTasks/>,
        groups: [Roles.Admin]
      },
      {
        path: "tasks/:id",
        element: <AdminTask/>,
        groups: [Roles.Admin]
      },
      {
        path: "task-reports",
        element: <AdminTaskReports/>,
        groups: [Roles.Admin]
      },
      {
        path: "task-reports/:id",
        element: <AdminTaskReport/>,
        groups: [Roles.Admin]
      },
      {
        path: "exams",
        element: <AdminExams/>,
        groups: [Roles.Admin]
      },
      {
        path: "exams/:id",
        element: <AdminExam/>,
        groups: [Roles.Admin]
      },
      {
        path: "inventory",
        element: <AdminLicenses/>,
        groups: [Roles.Admin]
      },
      {
        path: "license-logs",
        element: <AdminLicenseLogs/>,
        groups: [Roles.Admin]
      },
      {
        path: `${LINKS.adminExtendedTime}`,
        element: <AdminExtendedSessionTime/>,
        groups: [Roles.Admin]
      },
      {
        path: "personnel",
        element: <AdminPersonnel/>,
        groups: [Roles.Admin]
      },
      {
        path: "personnel/:id",
        element: (
          <UserDetails
            type="admin"
            userType="teacher"
            breadcrumbs={[
              {
                text: "Admin"
              }, {
                text: "Personnel",
                path: LINKS.adminPersonnel
              }
            ]}
          />
        ),
        groups: [Roles.Admin]
      },
      {
        path: "wp-labs",
        element: <AdminLabList/>,
        groups: [Roles.Admin]
      },
      {
        path: `${LINKS.adminStudentWpLabWindow}/:labSessionId`,
        element: <WPLabWindow navigatePath={LINKS.adminLabList}/>,
        groups: [Roles.Admin]
      },
      {
        path: "code-editor-demo",
        element: <CodeEditorDemo/>,
        groups: [Roles.Admin]
      },
      {
        path: "*",
        element: <NotFound link={{path: LINKS.admin, label: "Back"}}/>
      }
    ]
  }
]

const getAllowedRoutes = (roles): RouteObjectWithRoute[] => {
  return routes.reduce((acc, mainRoute) => {
    if (!isRouteAllowed(mainRoute.groups, roles)) return acc

    if (mainRoute?.children?.length) {
      const allowedChildren = mainRoute.children.reduce((acc, childRoute) => {
        if (isRouteAllowed(childRoute.groups, roles)) acc.push(childRoute)
        return acc
      }, [])

      const allowedRoute = {
        ...mainRoute,
        children: allowedChildren
      }

      acc.push(allowedRoute)

      return acc
    }

    if (isRouteAllowed(mainRoute.groups, roles)) acc.push(mainRoute)

    return acc
  }, [])
}

export default getAllowedRoutes
