import React from "react"
import {Helmet} from "react-helmet-async"
import {Box, Card, CardContent, Container} from "@mui/material"
import useSettings from "hooks/useSettings"
import CustomBreadcrumbs, {CustomBreadcrumbsProps} from "components/CustomBreadcrumbs"

interface Props {
  helmetTitle?: string
  title?: string
  breadcrumbs?: CustomBreadcrumbsProps["breadcrumbs"]
  card?: boolean
}

export default function LayoutWithBreadcrumbs({
  helmetTitle,
  title,
  breadcrumbs,
  card = true,
  children
}: React.PropsWithChildren<Props>) {
  const {settings} = useSettings()

  return (
    <>
      <Helmet>
        <title>
          {helmetTitle}
        </title>
      </Helmet>
      <Box pt={3} pb={8} minHeight="100%">
        <Container maxWidth={settings.compact ? "xl" : false}>
          <CustomBreadcrumbs breadcrumbs={breadcrumbs.filter(Boolean)} title={title}/>
          <Box mt={3}>
            {card ? (
              <Card>
                <CardContent>
                  {children}
                </CardContent>
              </Card>
            ) : (
              <>
                {children}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  )
}
