import {gql} from "@apollo/client"

export default gql`
  mutation AssignLab(
    $licenseId: GraphQLObjectId! 
    $studentCognitoIds: [String!]! 
    $override: Boolean! = false
  ) {
    assignLab(
      licenseId: $licenseId 
      studentCognitoIds: $studentCognitoIds 
      override: $override
    ) {
      _id
    }
  }
`
