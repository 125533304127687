import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {District} from "generated/graphql"
import {DistrictService} from "services/district.service"
import {handleError} from "store/slices/notifier/notifier"
import {errorCase, pendingCase} from "store/storeHelpers"
import jsonParse from "utils/jsonParse"
import sort from "utils/sort"

interface IDistrictSliceInitialState {
  loading: boolean
  errorMessage: string
  name: string
  logo: string
  currentDistrict: string
  totalSchools: number
  allDistricts: Array<District>
}

export const initialState: IDistrictSliceInitialState = {
  loading: false,
  errorMessage: "",
  name: null,
  logo: null,
  currentDistrict: null,
  totalSchools: null,
  allDistricts: []
}

export const fetchAdministratedDistrictsThunk = createAsyncThunk(
  "districtSlice/fetchAdministratedDistrictsThunk",
  async (_, thunkAPI) => {
    try {
      return await DistrictService.fetchAdministratedDistricts()
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const getDistrictInfoThunk = createAsyncThunk(
  "districtSlice/fetchDistrictInfoThunk",
  async (districtId: string, thunkAPI) => {
    try {
      return await DistrictService.fetchDistrictInfo(districtId)
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

const districtSlice = createSlice({
  name: "districtSlice",
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setCurrentDistrict(state, action) {
      state.currentDistrict = action.payload
    },
    cleanSpecificDistrictStateField(state, action) {
      if (!action?.payload?.length) return

      action.payload.forEach((fieldName) => {
        state[fieldName] = null
      })
    },
    updateDistrictSchool(state, {payload}) {
      state.allDistricts = state.allDistricts.map(i => {
        if (i._id === payload.id) {
          return {
            ...i,
            schools: {
              ...i.schools,
              items: i.schools.items.map(school => {
                if (school._id === payload.school.id) {
                  return {
                    ...school,
                    ...payload.school
                  }
                }
                return school
              })
            }
          }
        }
        return i
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistrictInfoThunk.pending, pendingCase)
      .addCase(fetchAdministratedDistrictsThunk.pending, pendingCase)
    builder
      .addCase(getDistrictInfoThunk.rejected, errorCase)
      .addCase(fetchAdministratedDistrictsThunk.rejected, errorCase)
    builder
      .addCase(getDistrictInfoThunk.fulfilled, (state, {payload}) => {
        state.loading = false
        const {name, logo, _id, schools} = payload
        state.currentDistrict = _id
        state.name = name
        state.logo = logo
        state.totalSchools = schools?.total || 0
      })
      .addCase(fetchAdministratedDistrictsThunk.fulfilled, (state, {payload}) => {
        state.loading = false
        const selectedAccount = jsonParse(localStorage.getItem("selectedAccount"))
        let districts: District[] = []
        if (payload?.length) {
          districts = sort(payload, "name")
          if (selectedAccount?.districtId) {
            const foundDistrict = districts.find(i =>
              i._id === selectedAccount.districtId
            )
            if (foundDistrict) state.currentDistrict = foundDistrict._id
          }
          // if (!selectedAccount?.districtId && action.payload.length === 1) {
          //   state.currentDistrict = action.payload[0]._id
          // }
        }

        state.allDistricts = districts
      })
  }
})

export const {setCurrentDistrict, cleanSpecificDistrictStateField, updateDistrictSchool} = districtSlice.actions

export default districtSlice.reducer
