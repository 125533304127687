import {Theme} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import React from "react"
import ReactMarkdown from "react-markdown"
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter"
import {oneDark} from "react-syntax-highlighter/dist/esm/styles/prism"

const useStyles = makeStyles<Theme>((theme) => ({
  markdown: {
    "& p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(1)
    },
    "& ol": {
      paddingLeft: theme.spacing(2)
    }
  },
  code: {
    background: theme.palette.kpNeutralColors.light,
    padding: "2px 4px"
  }
}))

interface Props {
  markdown: string
}

export default function Highlight({markdown}: Props) {
  const s = useStyles()

  return (
    <ReactMarkdown
      className={s.markdown}
      children={markdown}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || "")

          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              children={String(children).replace(/\n$/, "")}
              style={oneDark}
              language={match[1]}
              PreTag="div"
            />
          ) : (
            <code {...props} className={cn(s.code, className)}>
              {children}
            </code>
          )
        }
      }}
    />
  )
}
