import {gql} from "@apollo/client"

export default gql`
  query GetTestingGroup($testingGroupId: GraphQLObjectId!) {
    getTestingGroup(testingGroupId: $testingGroupId) {
      _id
      createdAt
      updatedAt
      schoolId
      licenseId
      teacher {
        _id
        username
        firstName
        lastName
        email
        phone
        isProctor
        fullName
        photoUrl
        totalTimeSpentInLabs
      }
      proctor {
        _id
        username
        firstName
        lastName
        email
        phone
        isProctor
        proctorSignedAt
        fullName
        photoUrl
        totalTimeSpentInLabs
      }
      license {
        _id
        createdAt
        updatedAt
        schoolId
        name
        duration
        examCodes
        expirationDate
        examVouchersRemain
        examVouchersInitial
        practiceVouchersRemain
        practiceVouchersInitial
        allowedRetakesCount
        coursesRemain
        coursesInitial
        labsInitial
        labsRemain
      }
      proctoring
      name
      code
      status
      paymentType
      examCode
      isArchived
    }
  }
`
