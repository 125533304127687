import {gql} from "@apollo/client"

export default gql`
  query GetExamSession(
    $examSessionId: GraphQLObjectId!
  ) {
    getExamSession(
      examSessionId: $examSessionId
    ) {
      _id
      _score
      createdAt
      startDate
      updatedAt
      finishDate
      examCode
      examus {
        report {
          archive
        }
      }
      canceledBy {
        fullName
      }
      certificateId
      consideredAsRetake
      cancelReason
      testingGroup {
        proctor {
          _id
          fullName
        }
        status
      }
      progress {
        averageObjectiveScore
        objective {
          _id
          title
        }
      }
      isPassed
      score
      status
      user {
        _id
        firstName
        lastName
      }
      voucherCode
    }
  }
`
