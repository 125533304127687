import ManageClassroomSection from "components/ManageClassroomSection"
import React, {useMemo} from "react"
import {Box} from "@mui/material"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import useMainPageLink from "hooks/useMainPageLink"
import {LINKS} from "consts/links"

export default function CreateClassroom() {
  const pageTitle = useMemo(() => "Classrooms", [])
  const {mainPageLink} = useMainPageLink()

  // const [classroom, setClassroom] = useState<Classroom | null>(null)
  //
  // const [classroomQuery, {loading}] = useLazyQuery<
  //   GetClassroomQuery,
  //   GetClassroomQueryVariables
  // >(GET_CLASSROOM)

  // const handleUpdate = (id?: string) => {
  //   const classroomId = id || classroom?._id
  //   classroomQuery({
  //     fetchPolicy: "network-only",
  //     variables: {
  //       classroomId
  //     }
  //   }).then(res => {
  //     setClassroom(res.data?.getClassroom)
  //   })
  // }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle={pageTitle}
      title={pageTitle}
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          path: LINKS.classrooms,
          text: "Classrooms"
        },
        {
          text: "Create Classroom"
        }
      ]}>
      <Box minHeight="30vw">
        <ManageClassroomSection/>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
