import {Theme} from "@mui/material"
import {makeStyles} from "@mui/styles"

export const useExamWindowStyles = makeStyles<
  Theme,
  {showLib: boolean, showSidebar: boolean}
>((theme) => ({
    root: ({showLib, showSidebar}) => {
     return  {
       position: "relative",
       height: !showSidebar && showLib ? "calc(100vh - 87px)" : "100vh",
       marginTop: !showSidebar && showLib ? "86px" : 0,
       // height: "100vh",
       // marginTop: 0,
       transition: "all 0.35s ease-out"
      }
    },
    wrapper: {
      minHeight: "100vh",
      position: "absolute",
      margin: 0,
      display: "flex",
      flexDirection: "column",
      top: 0,
      left: 0,
      width: "100%"
    },
    scoreCard: {
      margin: "0 auto"
    },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      background: "#ffff",
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column"
    },
    progressBar: {
      marginTop: theme.spacing(4)
    },

    //Styles applied after refactoring

    //globally used
    modalContent: {
      padding: theme.spacing(3, 0)
    },
    modalTitleContainer: {
      paddingBottom: 0,
      display: "grid",
      gridTemplateColumns: "1fr 2fr 1fr"
    },
    modalTitle: {
      fontWeight: "bold"
    },
    DividerContainer: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    DividerBar: {
      height: "2px",
      margin: "auto",
      width: "40%",
      backgroundColor: theme.palette.kpYellowColors.main
    },

    // SphereEngineExam.js
    sphereEngineWorkSpace: {
      minHeight: "80vh",
      [theme.breakpoints.up("xl")]: {
        height: "85vh"
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    sphereEngineFooter: {
      display: "flex",
      flexDirection: "column",
      height: "20vh",
      [theme.breakpoints.up("xl")]: {
        height: "15vh"
      }
    },
    loadingMessage: {
      paddingLeft: "80px",
      paddingRight: "80px"
    },

    //WordpressExam.js
    wpWorkSpace: {
      minHeight: "70vh",
      [theme.breakpoints.up("xl")]: {
        minHeight: "75vh"
        // height: "100vh"
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    wpWorkSpaceFullHeight: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    wpFooter: {
      height: "30vh",
      [theme.breakpoints.up("xl")]: {
        height: "25vh"
      },
      display: "flex",
      flexDirection: "column"
    },
    loadingBarContainer: {
      minHeight: "4px",
      background: theme.palette.grey[200]
    },

    //  ExaminationWindow.js
    noCodeContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      maxWidth: "50%"
    },
    noCodeContainerMsg: {
      padding: theme.spacing(8, 4)
    },
    noCodeButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: theme.spacing(3)
    },
    fullscreenIconWrapper: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      width: "100%",
      backgroundColor: "black",
      height: "4vh"
    },
    fullscreenIcon: {
      margin: "5px 15px 5px 5px",
      "& svg": {
        color: "white",
        fill: "white"
      },
      cursor: "pointer"
    }
  }))
