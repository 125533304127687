import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import {Box, Typography, useTheme} from "@mui/material"
import React from "react"

interface Props {
  index: number
  text: string
  isCorrect: boolean
  isChosen: boolean
}

export default function CorrectIncorrectAnswer({
  text,
  isCorrect,
  isChosen
}: Props) {
  const theme = useTheme()

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      mb={1}
      border="1px solid #ddd"
      borderColor={(isChosen && !isCorrect) ? theme.palette.error.main : "#ddd"}
      borderRadius="4px">
      <Box position="absolute" display="flex" alignItems="center" justifyContent="center"
           width={64} height="100%"
           bgcolor={(isChosen && !isCorrect) ? theme.palette.error.main : "transparent"}>
        {isCorrect ? (
          <DoneIcon color="success"/>
        ) : isChosen ? (
          <CloseIcon style={{fill: "#fff"}}/>
        ) : null}
      </Box>
      <Box position="relative" width="100%" ml="64px" p={2.5} pl={2} pr={5} borderLeft="1px solid #ddd"
           borderColor={(isChosen && !isCorrect) ? theme.palette.error.main : "#ddd"}>
        <Typography variant="body1">
          <code>
            {text}
          </code>
        </Typography>
        {(isCorrect || isChosen) && (
          <Typography position="absolute" bottom={theme.spacing(.3)} right={theme.spacing(.5)}
                      variant="body2" color="textSecondary" fontSize=".7rem">
            {isCorrect ? (isChosen ? "Your (correct) answer" : "Correct answer") : "Your answer"}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
