import {
  Box,
  Button,
  Typography,
  useTheme,
  FormHelperText, Paper
} from "@mui/material"
import React, {useState} from "react"

interface Props {
  error?: string
  onChange: (value: File | null) => void
}
export function FileInput({
  error,
  onChange
}: Props) {
  const theme = useTheme()

  const [file, setFile] = useState<File | null>(null)
  const handleChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      if (e.target.files.length) {
        const currentFile = e.target.files[0]
        setFile(currentFile)
        onChange(currentFile)
      }
    } else {
      setFile(null)
      onChange(null)
    }
  }

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{borderColor: error && theme.palette.error.main, p: 2}}
      >
        <Box>
          <Box display="flex" alignItems="center" flexDirection="column" textAlign="center">
            <Box
              width="100%"
              height="100%"
              sx={{
                position: "relative"
              }}>
              {file &&(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  mb={1}
                >
                  {file.name}
                </Typography>
              )}
              <Button>
                Choose file
              </Button>
              <input
                key={file?.name || "file"}
                type="file"
                onChange={handleChange}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  left: 0,
                  top: 0,
                  zIndex: 1,
                  cursor: "pointer"
                }}
              />
            </Box>
          </Box>
        </Box>
        {file && (
          <Box textAlign="center" mt={1}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => handleChange()}
            >
              Remove File
            </Button>
          </Box>
        )}
      </Paper>
      {error &&
        <FormHelperText error sx={{margin: "3px 14px 0 14px"}}>
          {error}
        </FormHelperText>
      }
    </Box>
  )
}
