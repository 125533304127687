import {Box} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import React, {useMemo, useState} from "react"
import {Outlet, useParams} from "react-router-dom"
import Header from "components/Header"
import Footer from "components/Footer"
import Sidebar from "components/Sidebar"
import {useSelector} from "store"

interface Props {
  noSidebar?: boolean
  type?: "district" | "admin"
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  layout: {
    position: "relative",
    width: "100%",
    height: "100%",
    paddingTop: "64px",
    paddingBottom: "48px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "290px"
    }
  },
  noSidebar: {
    paddingLeft: 0
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
    WebkitOverflowScrolling: "touch"
  },
  contentOnly: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  }
}))

export default function MainLayout({
  noSidebar,
  type,
  children
}: React.PropsWithChildren<Props>) {
  const s = useStyles()
  const params = useParams()

  const {currentSchool} = useSelector(({schoolSlice}) => schoolSlice)

  const [showMobileSidebar, setShowMobileSidebar] = useState(false)

  const checkExamWindowPage = useMemo(() => {
    return !!((params.examCode && params.sessionId) || params.labSessionId)
  }, [params])

  return (
    <Box key={currentSchool} className={s.root}>
      {!checkExamWindowPage && (
        <Header onSidebarMobileOpen={() => setShowMobileSidebar(true)}/>
      )}
      {(!noSidebar && !checkExamWindowPage) && (
        <Sidebar
          onMobileClose={() => setShowMobileSidebar(false)}
          openMobile={showMobileSidebar}
          type={type}
        />
      )}
      <Box className={cn(s.layout, {
        [s.noSidebar]: noSidebar,
        [s.contentOnly]: checkExamWindowPage
      })}
      >
        <Box className={s.content} overflow={noSidebar ? "hidden" : "auto"}>
          {children || <Outlet/>}
        </Box>
      </Box>
      {!checkExamWindowPage && <Footer/>}
    </Box>
  )
}
