type Item = {
  sessionId: string
  taskId: string
  value: string
}

const practicalExamStorage = {
  getStorage() {
    const storage: Item[] = JSON.parse(localStorage.getItem("practicalExamStorage") || "[]")

    return storage
  },
  getItem(sessionId: Item["sessionId"], taskId: Item["taskId"]) {
    const storage = practicalExamStorage.getStorage()
    const target: Item | null = storage.find(i => i.sessionId === sessionId && i.taskId === taskId) || null

    return target
  },
  setItem(sessionId: Item["sessionId"], taskId: Item["taskId"], value: Item["value"]) {
    const storage = practicalExamStorage.getStorage()
    const target = practicalExamStorage.getItem(sessionId, taskId)

    localStorage.setItem("practicalExamStorage", JSON.stringify(target ? [
      ...storage.map(i => {
        if (i.sessionId === target.sessionId && i.taskId === target.taskId) {
          return {...i, value: value}
        } else {
          return i
        }
      })
    ] : [
      ...storage,
      {sessionId, taskId, value}
    ]))
  },
  clear() {
    localStorage.removeItem("practicalExamStorage")
  }
}

export default practicalExamStorage
