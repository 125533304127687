import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {LINKS} from "consts/links"
import {ProctoringType, TestingGroupPaymentType} from "generated/graphql"
import useMainPageLink from "hooks/useMainPageLink"
import React, {useEffect, useMemo, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {getAvailableExamCodes} from "store/slices/exams"
import {
  createTestingGroupThunk,
  fetchLicensesThunk,
  fetchSchoolStaffThunk,
  fetchTestingGroupByIdThunk,
  fetchTestingGroupsThunk,
  setCurrentTestingGroup,
  updateTestingGroupThunk
} from "store/slices/schoolSlice/schoolSlice"
import {getLicensesSelector, selectTeachersAndProctorsForSelectOptions} from "store/slices/schoolSlice/selectors"
import {INewTestingGroup, ITestingGroupItem} from "types/common"
import getExamCodeName from "utils/getExamCodeName"

const useStyles = makeStyles(() => ({
  root: {
    width: "40vw"
  },
  formControl: {
    width: "100%",
    boxSizing: "content-box",
    "& *": {
      boxSizing: "content-box !important"
    }
  }
}))

const fieldsInitial = {
  teacher: {
    name: "teacher",
    label: "Teacher",
    value: "",
    error: null,
    options: [{label: "No teachers available", value: ""}]
  },
  proctoring: {
    name: "proctoring",
    label: "Proctoring",
    value: ProctoringType.Classroom,
    error: null,
    options: [{
      label: "Classroom",
      value: ProctoringType.Classroom
    }, {
      label: "Online Proctoring",
      value: ProctoringType.Online
    }]
  },
  proctor: {
    name: "proctor",
    label: "Proctor",
    value: "",
    error: null,
    options: [{label: "No proctors available", value: ""}]
  },
  examCode: {
    name: "examCode",
    label: "Exam Code",
    value: "",
    error: null,
    options: [{label: "No licenses available", value: ""}]
  },
  paymentType: {
    name: "paymentType",
    label: "Select Payment Type",
    value: TestingGroupPaymentType.LicenseInventory,
    error: null,
    options: [{
      label: "License inventory",
      value: TestingGroupPaymentType.LicenseInventory
    }, {
      label: "Student inventory",
      value: TestingGroupPaymentType.StudentInventory
    }]
  },
  license: {
    name: "license",
    label: "License",
    value: "",
    error: null,
    options: []
  }
}

export default function AddTestingGroup() {
  const theme = useTheme()
  const s = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const {mainPageLink} = useMainPageLink()

  const schoolLoading = useSelector((store) => store.schoolSlice.loading)
  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)
  const currentTestingGroup = useSelector((store) => store.schoolSlice.currentTestingGroup)
  const {teachersOptions, proctorOptions} = useSelector(selectTeachersAndProctorsForSelectOptions)
  const examCodes = useSelector((state) => state.examsSlice.availableExamCodes)
  const licenses = useSelector(getLicensesSelector)

  const [initLoading, setInitLoading] = useState(true)
  const [nameField, setNameField] = useState<{
    value: string
    error: string | null
  }>({
    value: "",
    error: null
  })
  const [fields, setFields] = useState(fieldsInitial)

  const filteredLicenses = useMemo(() => {
    return licenses?.filter(i => new Date(i.expirationDate) >= new Date()) || []
  }, [licenses])

  const filteredExamCodes = useMemo(() => {
    return (examCodes || []).filter(i => {
      return !!filteredLicenses.find(license => {
        if (!license.isUniversalLicense) {
          return license.examCodes.includes(i.code)
        }
        return true
      })
    })
  }, [examCodes, filteredLicenses])

  const licensesByExamCode = useMemo(() => {
    if (!fields.examCode.value || fields.examCode.value === "default") {
      return []
    } else {
      return filteredLicenses.filter(i => {
        if (i.examVouchersRemain > 0 || i.practiceVouchersRemain > 0) {
          if (i.isUniversalLicense) {
            return i
          }
          return  i.examCodes.includes(fields.examCode.value)
        }
        return false
      })
    }
  }, [filteredLicenses, fields.examCode.value])

  const loading = useMemo(() => {
    return initLoading || schoolLoading
  }, [initLoading, schoolLoading])

  useEffect(() => {
    dispatch(fetchSchoolStaffThunk(schoolId))
    dispatch(getAvailableExamCodes())
    dispatch(fetchLicensesThunk(schoolId))

    return () => {
      dispatch(setCurrentTestingGroup(null))
    }
  }, [])

  useEffect(() => {
    if (!params?.groupId) {
      setInitLoading(false)
    } else {
      dispatch(fetchTestingGroupByIdThunk(params?.groupId)).then(res => {
        const data = res.payload as ITestingGroupItem

        setNameField({
          value: data.name,
          error: null
        })

        setFields(current => ({
          ...current,
          teacher: {...current.teacher, value: data.teacherUserId},
          proctoring: {...current.proctoring, value: data.proctoring},
          proctor: {...current.proctor, value: data.proctorUserId},
          examCode: {...current.examCode, value: data.examCode},
          paymentType: {...current.paymentType, value: data.paymentType},
          license: {...current.license, value: data.licenseId}
        }))
      }).finally(() => {
        setInitLoading(false)
      })
    }
  }, [params?.groupId])

  useEffect(() => {
    setFields(current => ({
      ...current,
      teacher: {
        ...current.teacher,
        value: currentTestingGroup?.teacherUserId || "",
        options: teachersOptions || fieldsInitial.teacher.options
      }
    }))
  }, [teachersOptions, currentTestingGroup])

  useEffect(() => {
    setFields(current => ({
      ...current,
      proctor: {
        ...current.proctor,
        value: currentTestingGroup?.proctorUserId || "",
        options: proctorOptions || fieldsInitial.proctor.options
      }
    }))
  }, [proctorOptions, currentTestingGroup])

  useEffect(() => {
    if (!filteredExamCodes?.length) {
      setFields(fieldsInitial)
    } else {
      const options = filteredExamCodes.map(i => ({
        label: `${i.displayCode} - ${getExamCodeName(i.displayCode)}`,
        value: i.code
      }))

      setFields((current) => ({
        ...current,
        examCode: {
          ...current.examCode,
          options,
          value: (
            !options.find(i => i.value === current.examCode.value) ? options[0].value : current.examCode.value
          ) || "default"
        }
      }))
    }
  }, [filteredExamCodes])

  useEffect(() => {
    if (fields.paymentType.value !== TestingGroupPaymentType.LicenseInventory) {
      setFields(current => ({
        ...current,
        license: fieldsInitial.license
      }))
    } else {
      const options = licensesByExamCode.map(i => ({
        label: i.name,
        value: i._id
      }))

      setFields(current => ({
        ...current,
        license: {
          ...current.license,
          value: currentTestingGroup?.licenseId || options[0]?.value || "",
          error: null,
          options
        }
      }))
    }
  }, [fields.paymentType.value, licensesByExamCode, currentTestingGroup])

  const handleFieldFocus = (name) => {
    setFields(current => ({
      ...current,
      [name]: {
        ...current[name],
        error: null
      }
    }))
  }

  const handleChangeSelect = ({target: {name, value}}) => {
    setFields(current => ({
      ...current,
      [name]: {
        ...current[name],
        value
      }
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let isValid = true

    if (!nameField.value) {
      isValid = false

      setNameField(current => ({
        ...current,
        error: "required"
      }))
    }

    const values: Partial<{
      [K in keyof typeof fieldsInitial]: typeof fieldsInitial[K]["value"]
    }> = Object.values(fields).reduce((acc, {name, value}) => {
      if (name === "license" && (
        fields.paymentType.value !== TestingGroupPaymentType.LicenseInventory || !fields.license.value
      )) {
        return acc
      }

      if (name === "proctor" && !fields.proctor.options.length) {
        return acc
      }

      if (value && value !== "default") {
        acc[name] = value

        return acc
      }

      setFields(current => ({
        ...current,
        [name]: {
          ...current[name],
          error: "required"
        }
      }))

      isValid = false

      return acc
    }, {})

    if (isValid) {
      const data: INewTestingGroup = {
        schoolId,
        name: nameField.value,
        teacherUserId: values.teacher,
        proctoring: values.proctoring,
        proctorUserId: values.proctor,
        paymentType: values.paymentType,
        examCode: values.examCode
      }

      if ("license" in values) {
        data.licenseId = values.license
      }

      if (!values.proctor) {
        delete data.proctorUserId
      }

      if (!!params?.groupId) {
        delete data.schoolId

        dispatch(updateTestingGroupThunk({
          id: currentTestingGroup?.id,
          updatedTestingGroup: data
        })).then(res => {
          if (!!res.payload) {
            handleFinish()
          }
        })
      } else {
        dispatch(createTestingGroupThunk(data)).then(res => {
          if (!!res.payload) {
            handleFinish()
          }
        })
      }
    }
  }

  const handleFinish = () => {
    dispatch(fetchTestingGroupsThunk({schoolId}))
    navigate(-1)
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Testing Group"
      title={params?.groupId ? "Testing Group" : "New Testing Group"}
      breadcrumbs={[{
        path: mainPageLink,
        text: "Dashboard"
      }, {
        path: LINKS.testingGroup,
        text: "Testing Groups"
      }, {
        text: params?.groupId ? "Testing Group" : "Add New Group"
      }]}>
      <Box>
        <Box pb={4}>
          <form onSubmit={handleSubmit}>
            <Box>
              {loading ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={24}>
                  <CircularProgress/>
                </Box>
              ) : (
                <Box mb={2} pt={1}>
                  <Typography variant="h5" textAlign="center">
                    {params?.groupId ? "Testing Group" : "Add Testing Group"}
                  </Typography>
                  <Box mt={3} mb={2} width={`calc(50% - ${theme.spacing(1)})`}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="groupName"
                      label="Group Name"
                      onFocus={() => {
                        setNameField(current => ({
                          ...current,
                          error: null
                        }))
                      }}
                      value={nameField.value}
                      onChange={(e) =>
                        setNameField(current => ({
                          ...current,
                          value: e.target.value
                        }))
                      }
                    />
                    {nameField.error && (
                      <Typography variant="subtitle2" color="error">
                        Group Name field is required
                      </Typography>
                    )}
                  </Box>
                  <Grid container spacing={2} mt={2} mb={6}>
                    {fields && Object.values(fields).reduce((acc, {
                      name,
                      label,
                      options,
                      value,
                      error
                    }) => {
                      if (!options?.length) {
                        return acc
                      } else {
                        acc.push(
                          <Grid xs={6} item key={name}>
                            <FormControl
                              className={s.formControl}
                              variant="outlined">
                              <InputLabel id={label}>
                                {label}
                              </InputLabel>
                              <Select
                                labelId={label}
                                label={label}
                                name={name}
                                value={value}
                                error={!!error}
                                onChange={handleChangeSelect}
                                onFocus={() => handleFieldFocus(name)}>
                                {options.map(option => {
                                  if (name === "license") {
                                    // const vouchersRemain = licensesByExamCode.find(i => (
                                    //   i._id === option.value
                                    // ))?.vouchersRemain || 0

                                    const license = licensesByExamCode.find(i => i._id === option.value)

                                    const vouchersRemain = license?.examVouchersRemain || license?.practiceVouchersRemain || 0

                                    return (
                                      <MenuItem key={option.label} value={option.value}>
                                        {option.label}
                                        <span style={{
                                          marginLeft: theme.spacing(.5),
                                          color: vouchersRemain ? theme.palette.success.main : theme.palette.error.main
                                        }}>
                                          (Availability: {vouchersRemain})
                                        </span>
                                      </MenuItem>
                                    )
                                  } else {
                                    return (
                                      <MenuItem key={option.label} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    )
                                  }
                                })}
                              </Select>
                            </FormControl>
                            {error && (
                              <Typography variant="subtitle2" color="error">
                                {error === "required" ? `${label} field is required` : error}
                              </Typography>
                            )}
                          </Grid>
                        )

                        return acc
                      }
                    }, [])}
                  </Grid>
                </Box>
              )}
              {!loading && (
                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(-1)
                    }}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained">
                    {params?.groupId ? "Save Testing Group" : "Add Testing Group"}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
