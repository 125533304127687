import {gql} from "@apollo/client"

export default gql`
  query GetTestingGroupsIds(
    $order: SortOrder
    $sortBy: String
    $schoolId: GraphQLObjectId
    $includeArchived: Boolean
    $take: Int = 100
    $offset: Int = 0
    $search: String
  ) {
    testingGroups(
      order: $order
      sortBy: $sortBy
      schoolId: $schoolId
      includeArchived: $includeArchived
      take: $take
      offset: $offset
      search: $search
    ) {
      total
      hasMore
      items {
        _id
        proctoring
        name
        code
        status
        paymentType
        examCode
        isArchived
      }
    }
  }
`
