import "react-perfect-scrollbar/dist/css/styles.css"
import "nprogress/nprogress.css"
import "./index.css"
import {Amplify} from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import {HelmetProvider} from "react-helmet-async"
import {BrowserRouter} from "react-router-dom"
import {Provider as ReduxProvider} from "react-redux"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import App from "./App"
import {AuthProvider} from "contexts/AmplifyContext"
import {SettingsProvider} from "contexts/SettingsContext"
import store from "store"
import {amplifyConfig} from "config"

const kpTarget = /\.knowledge-pillars\.com/

Amplify.configure(amplifyConfig)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "development",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", kpTarget]
    }),
    new Sentry.Replay({
      maskAllText: false,
      networkDetailAllowUrls: [kpTarget]
    })
  ],
  sampleRate: 0.3,
  tracesSampleRate: 0.3,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
)
