import {useLazyQuery, useMutation} from "@apollo/client"
import {Box, Button, Typography} from "@mui/material"
import ADD_ADMIN_TO_DISTRICT from "api/apollo/mutations/ADD_ADMIN_TO_DISTRICT"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import SelectAsync from "components/SelectAsync"
import {Formik} from "formik"
import {
  AddAdminToDistrictMutation,
  AddAdminToDistrictMutationVariables, District,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables
} from "generated/graphql"
import React, {useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  onCancel: () => void
  onSubmit: () => void
  email: string
}

export default function AddDistrictAdminForm(props: Props) {
  const dispatch = useDispatch()

  const [districts, setDistricts] = useState<District[]>([])

  const formInitialValues = useMemo(() => {
    return {
      districtId: ""
    }
  }, [])

  const [addAdminToDistrict] = useMutation<
    AddAdminToDistrictMutation,
    AddAdminToDistrictMutationVariables
  >(ADD_ADMIN_TO_DISTRICT)

  const [districtIdsQueryFetch, districtIdsQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    addAdminToDistrict({
      variables: {
        userEmail: props.email,
        districtId: data.districtId
      }
    }).then(() => {
      props.onSubmit()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  const handleQueryDistricts = (value: string) => {
    districtIdsQueryFetch({
      variables: {
        search: value
      }
    }).then(res => {
      setDistricts(res.data?.districts?.items || [])
    })
  }


  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        districtId: Yup.string().required("District is required")
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        values,
        setFieldValue,
        touched,
        isValid,
        dirty,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Box py={3} px={3} minWidth="35vw">
            <Typography variant="h5" mb={3}>
              Add Admin
            </Typography>
            <Box>
              <SelectAsync
                id="districtId"
                label="District"
                value={values.districtId}
                error={touched.districtId && errors.districtId}
                touched={touched.districtId}
                onChange={value => setFieldValue("districtId", value)}
                loading={districtIdsQuery.loading}
                options={districts.map(i => ({
                  label: i.name,
                  value: i._id
                }))}
                onUpdateOptions={handleQueryDistricts}
              />
            </Box>
            <Box display="flex" mt={4} justifyContent="flex-end" gap={1} ml="auto">
              <Button variant="outlined" onClick={props.onCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!(isValid && dirty)}>
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
