import {DatePicker} from "@mui/x-date-pickers"
import {DataTableDateFilter} from "components/DataTable/types.t"
import {debounce} from "lodash"
import React, {useCallback, useEffect, useState} from "react"
import {Box, TextField, Typography} from "@mui/material"


type Value = [number | null, number | null] | null

interface Props {
  label?: string
  initialValue?: Value
  value?: Value
  onChange: (value: Value)=> void
}

export default function DateFilter({
  label,
  onChange,
  initialValue,
  value: valueProp
}: Props) {
  const [value, setValue] = useState<DataTableDateFilter["value"]>(valueProp || initialValue || null)

  useEffect(() => {
    if (valueProp === null) {
      setValue(initialValue || null)
    }
  }, [valueProp])

  useEffect(() => {
    onChange(value)
  }, [value])

  const createDebouncedDateHandler = useCallback((type: "start" | "end") => {
    return debounce((newValue: Date | null) => {
      if (+newValue < 0 || isNaN(+newValue)) {
        return
      }

      setValue(current => {
        let newDate: typeof current = [current?.[0] || null, current?.[1] || null]

        if (type === "start") {
          newDate[0] = newValue ? new Date(newValue.setHours(0, 0, 0, 0)).getTime() : null
        } else {
          newDate[1] = newValue ? new Date(newValue.setHours(23, 59, 59, 999)).getTime() : null
        }

        return (newDate[0] === null && newDate[1] === null) ? null : newDate
      })
    }, 1000, {leading: true, trailing: true})
  }, [])

  const handleStartDate = createDebouncedDateHandler("start")
  const handleEndDate = createDebouncedDateHandler("end")

  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="h6">
            {label}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap={1}>
        <DatePicker
          label="Start"
          value={value?.[0] || null}
          onChange={handleStartDate}
          maxDate={value?.[1] ? new Date(value?.[1]) : null}
          renderInput={props => (
            <TextField {...props}/>
          )}
        />
        <DatePicker
          label="End"
          value={value?.[1] || null}
          onChange={handleEndDate}
          minDate={value?.[0] ? new Date(value?.[0]) : null}
          renderInput={props => (
            <TextField {...props}/>
          )}
        />
      </Box>
    </Box>
  )
}
