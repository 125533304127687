export default function convertSecondsToTime(seconds: number) {
  const res = []
  const hours = Math.floor(seconds / 3600 % 24)
  const minutes = Math.floor(seconds / 60 % 60)

  hours && res.push(`${hours}h`)
  minutes && res.push(`${minutes}min`)

  return res.length ? res.join(" ") : "0"
}
