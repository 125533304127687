import React, {useMemo} from "react"
import {CircularProgress, Box} from "@mui/material"
import {Document, Page} from "react-pdf"
import {makeStyles} from "@mui/styles"
import "react-pdf/dist/esm/Page/TextLayer.css"

const useStyles = makeStyles(() => ({
  PDFdoc: {
    position: "relative",
    margin: "1rem 0 0",
    boxShadow: "none",
    "&:hover": {
      "& .controls-item": {
        opacity: "10"
      }
    },
    "& .controls-item": {
      opacity: "0.3"
    },
    overflow: "auto"
  },
  PDFloader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "1100px",
    width: "777px"
  }
}))

interface Props {
  currentPage: number
  pagesCount: number
  value: string | any
  pageHeight?: number
  onLoadSuccess?: (arg?: any) => void
}

export default function PDFEmbed({
  currentPage,
  pagesCount,
  value,
  pageHeight = 1100,
  onLoadSuccess,
  children
}: React.PropsWithChildren<Props>) {
  const s = useStyles()

  const pages = useMemo(() => {
    return pagesCount ? Array.from({length: pagesCount}, (_, i) => i + 1) : [1]
  }, [pagesCount])

  return (
    <Document
      className={s.PDFdoc}
      file={typeof value === "string" ? {url: value} : value}
      onLoadSuccess={onLoadSuccess}
      onLoadError={console.error}
      loading={
        <Box className={s.PDFloader}>
          <CircularProgress color="inherit"/>
        </Box>
      }
      onItemClick={(e) => {
        console.warn(e)
      }}>
      {pagesCount ? (
        pages.map((_, num) => {
          return (
            <Page
              key={num}
              pageNumber={num + 1}
              height={pageHeight}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              renderForms={true}
            />
          )
        })
      ) : (
        <Page
          pageNumber={currentPage}
          height={pageHeight}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          renderForms={true}
          />
      )}
      {children}
    </Document>
  )
}
