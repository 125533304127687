import {Box, Typography} from "@mui/material"
import {DataTableFilter, DataTableSelectSingleFilter} from "components/DataTable/types.t"
import SelectAsync from "components/SelectAsync"
import SelectAutocomplete from "components/SelectAutocomplete"
import React, {useCallback, useEffect, useState} from "react"

type Props = Omit<DataTableSelectSingleFilter, "type" | "onUpdateOptions"> & {
  id: DataTableFilter["id"]
  // tableState: DataTableState
  onUpdateOptions: (inputValue: string) => void
  onChange: (value: string) => void
}

export default function SelectSingleFilter({
  id,
  // tableState,
  label,
  loading,
  disabled,
  options,
  initialValue,
  value: valueProp,
  onChange,
  onUpdateOptions,
  loadOnce
}: Props) {
  const [value, setValue] = useState(valueProp || initialValue || "")

  useEffect(() => {
    if (!onUpdateOptions) {
      if (!options.find(i => i.value === value)) {
        setValue(initialValue || "")
      }
    }
  }, [options])

  useEffect(() => {
    if (typeof valueProp === "string") {
      setValue(valueProp)
    } else if (valueProp === null) {
      setValue(initialValue || "")
    }
  }, [valueProp])

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleUpdateOptions = useCallback((inputValue: string) => {
    if (onUpdateOptions) {
      onUpdateOptions(inputValue)
    }
  }, [])

  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="h6">
            {label}
          </Typography>
        </Box>
      )}
      <Box>
        {!!onUpdateOptions ? (
          <SelectAsync
            id={id}
            label={label}
            sx={{minWidth: 180}}
            loading={loading}
            disabled={disabled}
            options={options}
            value={value}
            onChange={handleChange}
            onUpdateOptions={!!onUpdateOptions ? handleUpdateOptions : undefined}
            loadOnce={loadOnce}
          />
        ) : (
          <SelectAutocomplete
            id={id}
            label={label}
            sx={{minWidth: 180}}
            loading={loading}
            options={options}
            noOptionsText="Nothing found"
            value={value}
            onChange={handleChange}
          />
        )}
      </Box>
    </Box>
  )
}
