import {Typography, useTheme} from "@mui/material"
import React, {useEffect, useState} from "react"

interface Props {
  deadline: string
  onChange: (value: number) => void
}

export default function Timer({
  deadline,
  onChange
}: Props) {
  const theme = useTheme()

  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const convertMilliseconds = (ms: number) => {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor((ms / 1000 / 60) % 60)
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
    const days = Math.floor(ms / (1000 * 60 * 60 * 24))
    return {days, hours, minutes, seconds}
  }

  const format = (value: number) => {
    return value < 10 ? "0" + value : value
  }
  const getTimeUntil = (deadline: string) => {
    if (!deadline) return

    const time = Date.parse(deadline) - Date.parse(new Date().toString())
    onChange(time)
    if (time < 0) {
      setState({days: 0, hours: 0, minutes: 0, seconds: 0})
    } else {
      setState(convertMilliseconds(time))
    }
  }

  useEffect(() => {
    if (!deadline) return

    const intervalID = setInterval(() => getTimeUntil(deadline), 1000)

    return () => clearInterval(intervalID)
  }, [deadline])

  return (
    <Typography color="primary.light">
      <span style={{color: theme.palette.primary.main}}>Time left:</span> {format(state.hours)}:{format(state.minutes)}:{format(state.seconds)}
    </Typography>
  )
}
