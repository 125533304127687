import {gql} from "@apollo/client"

export default gql`
  mutation AddAdminToDistrict(
    $districtId: GraphQLObjectId! 
    $userEmail: String!
  ) {
    addAdminToDistrict(
      districtId: $districtId 
      userEmail: $userEmail
    ) {
      _id
    }
  }
`
