import {Box, Typography} from "@mui/material"
import Button from "@mui/material/Button"
import {TestingSession} from "generated/graphql"
import {startCase} from "lodash"
import React, {useMemo} from "react"
import {useNavigate} from "react-router-dom"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {finishSessionExam} from "store/slices/practiceSession/practiceSession"

interface Props {
  type: "practiceTest" | "examSimulator"
  session: Partial<TestingSession>
  onClose: () => void
}

export default function ActivePracticeSessionForm({
  type,
  session,
  onClose
}: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const title = useMemo(() => {
    return type === "practiceTest" ? "practice" : "exam simulator"
  }, [type])

  const handleJoinSession = () => {
    navigate(`/exam-window/${session.examCode}/${session._id}/${session.tasks[0]}`)
  }

  const handleFinishSession = async () => {
    await dispatch(finishSessionExam(session._id)).catch(err => {
      dispatch(handleError(err))
    }).finally(() => {
      onClose()
    })
  }

  return (
    <Box>
      <Typography variant="h5" color="primary" textAlign="center" mb={2}>
        Active {startCase(title)} Session
      </Typography>
      <Typography variant="subtitle1" color="primary" textAlign="center">
        You have an active {title} session. Please close or join an active {title} session!
      </Typography>
      <Box display="flex" justifyContent="space-between" gap={2} mt={4}>
        <Button
          onClick={handleFinishSession}
          variant="contained"
          color="warning"
        >
          Close Session
        </Button>
        <Button
          onClick={handleJoinSession}
          variant="contained"
          color="success"
        >
          Join Active Session
        </Button>
      </Box>
    </Box>
  )
}
