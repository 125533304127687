import {gql} from "@apollo/client"

export default gql`
  mutation SetClassroomStatus($classroomStatus: ClassroomStatus!, $classroomId: GraphQLObjectId!) {
    setClassroomStatus(classroomStatus: $classroomStatus, classroomId: $classroomId) {
      _id
      status
    }
  }
`
