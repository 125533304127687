import {gql} from "@apollo/client"

export default gql`
  query GetClassroomStudentsIds(
    $classroomId: GraphQLObjectId!
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
    $search: String
  ) {
    users(
      classroomId: $classroomId
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
      search: $search
    ) {
      total
      hasMore
      items {
        _id
        cognitoUserId
        firstName
        fullName
        lastName
        studentId
        username
      }
    }
  }
`
