import {gql} from "@apollo/client"

export default gql`
  mutation DeleteInvitation($invitationId: GraphQLObjectId) {
    deleteInvitation(invitationId: $invitationId) {
      _id
      createdAt
      updatedAt
      status
      decidedAt
      userId
      invitationSourceId
      comment
      classroomId
      classroom {
        _id
        name
        school {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
      }
    }
  }
`
