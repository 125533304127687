import {useMutation, useQuery} from "@apollo/client"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import SET_CLASSROOM_STATUS from "api/apollo/mutations/SET_CLASSROOM_STATUS"
import GET_CLASSROOM from "api/apollo/queries/GET_CLASSROOM"
import ExtendedSessionTimeTable from "components/ExtendedSessionTimeTable"
import LabSessionsTable from "components/LabSessionsTable"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import ManageClassroomSection from "components/ManageClassroomSection"
import SessionsTable from "components/SessionsTable"
import {LINKS} from "consts/links"
import copyToClipboard from "copy-to-clipboard"
import {
  ClassroomStatus,
  GetClassroomQuery,
  GetClassroomQueryVariables,
  SetClassroomStatusMutation,
  SetClassroomStatusMutationVariables
} from "generated/graphql"
import useMainPageLink from "hooks/useMainPageLink"
import ProgressContent from "./components/Progress"
import NotFound from "pages/NotFound"
import React, {useEffect, useMemo, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {handleError} from "store/slices/notifier/notifier"
import RosterContent from "./components/Roster"

export default function ManageClassroom() {
  const dispatch = useDispatch()
  const {mainPageLink} = useMainPageLink()
  const {classroomId} = useParams()

  const schoolId = useSelector(store => store.schoolSlice.currentSchool)

  const classroomQuery = useQuery<GetClassroomQuery, GetClassroomQueryVariables>(GET_CLASSROOM, {
    fetchPolicy: "network-only",
    variables: {
      classroomId
    }
  })

  const classroom = useMemo(() => {
    return classroomQuery.data?.getClassroom || null
  }, [classroomQuery.data])

  const tabs = useMemo(() => {
    return [
      {label: "Roster", value: "roster"},
      {label: "Progress", value: "progress"},
      {label: "Practice Results", value: "practice-results"},
      {label: "Exam Results", value: "exam-results"},
      {label: "Labs", value: "labs"},
      {label: "Extended Time", value: "extended-time"},
      {label: "Settings", value: "settings"}
    ].filter(i => {
      if (i.value === "labs") {
        return classroom?.defaultExams?.some(i => i.displayName.toLowerCase().includes("wordpress")) || false
      }
      return true
    })
  }, [classroom])

  const [initLoading, setInitLoading] = useState(true)
  const [status, setStatus] = useState(classroom?.status)
  const [currentTab, setCurrentTab] = useState("roster")
  const [joinCodePopupAnchor, setJoinCodePopupAnchor] = useState<null | HTMLElement>(null)
  const joinCodePopupOpened = !!joinCodePopupAnchor
  const [showDisplayJoinCodePopup, setShowDisplayJoinCodePopup] = useState(false)

  const [changeStatus] = useMutation<
    SetClassroomStatusMutation,
    SetClassroomStatusMutationVariables
  >(SET_CLASSROOM_STATUS)

  useEffect(() => {
    if (!classroomQuery.loading && initLoading) {
      setInitLoading(false)
    }
  }, [classroomQuery.loading, initLoading])

  useEffect(() => {
    if (!!classroom) {
      setStatus(classroom?.status)
    }
  }, [classroom])

  const handleOpenJoinCodePopup = (event: React.MouseEvent<HTMLElement>) => {
    setJoinCodePopupAnchor(event.currentTarget)
  }

  const handleJoinCodePopupSelect = (action?: "display" | "copy" | "reset" | "disable") => {
    setJoinCodePopupAnchor(null)

    if (action === "display") {
      setShowDisplayJoinCodePopup(true)
    } else if (action === "copy") {
      copyToClipboard(classroom.joinCode)
    } else if (action === "reset") {
      // TODO: add reset join code action
    } else if (action === "disable") {
      // TODO: add disable join code action
    }
  }

  const handleChangeStatus = (value: boolean) => {
    const currentValue = status
    const newValue = value ? ClassroomStatus.Opened : ClassroomStatus.Closed

    setStatus(newValue)

    changeStatus({
      variables: {
        classroomId: classroom._id,
        classroomStatus: newValue
      }
    }).then(() => {
      classroomQuery.refetch()
    }).catch(err => {
      setStatus(currentValue)
      dispatch(handleError(err))
    })
  }

  const pageTitle = useMemo(() => {
    return !classroom ? "Loading..." : `Classroom - ${classroom?.name}`
  }, [classroom])

  return classroomQuery.error ? (
    <NotFound/>
  ) : (
    <LayoutWithBreadcrumbs
      helmetTitle={pageTitle}
      title={pageTitle}
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {
          path: LINKS.classrooms,
          text: "Classrooms"
        },
        {
          text: pageTitle
        }
      ]}>
      {initLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={24}>
          <CircularProgress/>
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" gap="4rem">
            <Tabs
              indicatorColor="primary"
              onChange={(_e, value) => setCurrentTab(value)}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable">
              {tabs.map(i => (
                <Tab key={i.value} label={i.label} value={i.value}/>
              ))}
            </Tabs>
            <Box position="relative" display="flex" alignItems="center" gap="2rem" mb={1}>
              {!classroom.isArchived ? (
                <Box position="relative" display="flex" alignItems="center" gap="1rem">
                  <Typography variant="body2" fontWeight="600">
                    Classroom code
                  </Typography>
                  <Box position="relative">
                    <Button
                      id="join-code-popup-button"
                      aria-controls={joinCodePopupOpened ? "join-code-popup-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={joinCodePopupOpened ? "true" : undefined}
                      variant="outlined"
                      size="large"
                      endIcon={<KeyboardArrowDownIcon/>}
                      onClick={handleOpenJoinCodePopup}>
                      {classroom.joinCode}
                    </Button>
                    <Menu
                      id="join-code-popup-menu"
                      anchorEl={joinCodePopupAnchor}
                      MenuListProps={{
                        "aria-labelledby": "join-code-popup-button"
                      }}
                      open={joinCodePopupOpened}
                      onClose={() => handleJoinCodePopupSelect()}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}>
                      {["Display", "Copy"].map((i) => (
                        <MenuItem
                          key={i}
                          sx={{pr: 11}}
                          onClick={() => {
                            handleJoinCodePopupSelect(i.toLowerCase() as Parameters<typeof handleJoinCodePopupSelect>[0])
                          }}>
                          {i}
                        </MenuItem>
                      ))}
                    </Menu>
                    <Dialog
                      open={showDisplayJoinCodePopup}
                      maxWidth="md"
                      onClose={() => setShowDisplayJoinCodePopup(false)}>
                      <DialogContent>
                        <Box display="flex" justifyItems="center" alignItems="center" py={8} mx={20}>
                          <Typography variant="h1" color="textPrimary">
                            {classroom.joinCode}
                          </Typography>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Box>
              ) : (
                <Typography variant="body1" fontWeight="600" color="warningText.main">
                  Archived
                </Typography>
              )}
              <Box position="relative" display="flex" justifyContent="flex-end" alignItems="center" gap="0.5rem">
                <Typography variant="body1" fontWeight="600">
                  {status === "opened" ? "Open" : "Closed"}
                </Typography>
                <Switch
                  checked={status === "opened"}
                  color="success"
                  edge="start"
                  name="direction"
                  onChange={e => handleChangeStatus(e.target.checked)}
                />
              </Box>
            </Box>
          </Box>
          <Divider/>
          <Box mt={2}>
            {currentTab === "settings" ? (
              <ManageClassroomSection
                classroom={classroom}
                onClassroomUpdate={() => classroomQuery.refetch()}
                loading={classroomQuery.loading}
              />
              // <Settings
              //   classroom={classroom}
              //   onClassroomUpdate={classroomQuery.refetch}
              //   loading={classroomQuery.loading}
              // />
            ) : currentTab === "exam-results" ? (
              <SessionsTable
                type="exam"
                schoolId={schoolId}
                classroomId={classroom._id}
                detailsLink="/my-results/exam-details/"
                defaultExamFilter={classroom.defaultExamFilter}
              />
            ) : currentTab === "practice-results" ?  (
              <SessionsTable
                type="testing"
                schoolId={schoolId}
                classroomId={classroom._id}
                detailsLink="/practice-results/exam-details/"
                defaultExamFilter={classroom.defaultExamFilter}
              />
            ) : currentTab === "progress" ?  (
              <ProgressContent classroom={classroom}/>
            ) : currentTab === "labs" ? (
              <LabSessionsTable
                type="classroom"
                classroomId={classroom._id}
                latestOnly
              />
            ) : currentTab === "extended-time" ? (
              <ExtendedSessionTimeTable classroomId={classroom._id}/>
            ) : (
              <RosterContent
                classroom={classroom}
                onUpdate={classroomQuery.refetch}
              />
            )}
          </Box>
        </Box>
      )}
    </LayoutWithBreadcrumbs>
  )
}
