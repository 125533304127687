import {useLazyQuery, useMutation} from "@apollo/client"
import {
  Box,
  Button,
  Typography
} from "@mui/material"
import ADD_STUDENT_TO_CLASSROOM from "api/apollo/mutations/ADD_STUDENT_TO_CLASSROOM"
import GET_CLASSROOM_IDS from "api/apollo/queries/GET_CLASSROOM_IDS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import SearchIcon from "assets/icons/Search"
import SelectAsync from "components/SelectAsync"
import {Formik} from "formik"
import {
  AddStudentToClassroomMutation, AddStudentToClassroomMutationVariables,
  GetClassroomIdsQuery,
  GetClassroomIdsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables, School,
  User
} from "generated/graphql"
import React, {useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  onAfterSubmit: () => void
  user: Partial<User>
  school?: DeepPartial<School>
  classroomIds?: string[]
}

export default function AddUserToClassroomForm({
  onAfterSubmit,
  user,
  school,
  classroomIds
}: Props) {
  const dispatch = useDispatch()
  const [selectedSchool, setSelectedSchool] = useState(school?._id || "")

  const formInitialValues = useMemo(() => {
    return {
      schoolId: school?._id || "",
      classroomId: ""
    }
  }, [school])

  const [addStudentToClassroom] = useMutation<
    AddStudentToClassroomMutation,
    AddStudentToClassroomMutationVariables
  >(ADD_STUDENT_TO_CLASSROOM)

  const [schoolIdsQueryFetch, schoolIdsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [classroomIdsQueryFetch, classroomIdsQuery] = useLazyQuery<
    GetClassroomIdsQuery,
    GetClassroomIdsQueryVariables
  >(GET_CLASSROOM_IDS)

  const schools = useMemo(() => {
    return school ? (
      [{
        label: school.name,
        value: school._id
      }]
    ) : (
      schoolIdsQuery.data?.schools.items.map(i => ({
        label: i.name,
        value: i._id})
      ) || []
    )
  }, [schoolIdsQuery.data, school])

  const classrooms = useMemo(() => {
    return (selectedSchool &&
      classroomIdsQuery.data?.classrooms.items
      .filter((i) => {
        if (classroomIds?.length) {
          return !classroomIds.includes(i._id)
        }
        return true
      })
      .map(i => ({
        label: i.name,
        value: i._id})
      )
    ) || []
  }, [selectedSchool, classroomIdsQuery.data])

  const handleQuerySchoolIds = (searchQuery: string) => {
    !school && schoolIdsQueryFetch({
      variables: {
        search: searchQuery
      }
    })
  }

  const handleQueryClassroomIds = (searchQuery: string) => {
    selectedSchool && (
      classroomIdsQueryFetch({
        variables: {
          schoolId: selectedSchool,
          search: searchQuery
        }
      })
    )
  }

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    addStudentToClassroom({
      variables: {
        classroomId: data.classroomId,
        userId: user._id
      }
    }).then(() => {
      onAfterSubmit()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        schoolId: Yup.string().required("Required"),
        classroomId: Yup.string().required("Required")
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        setValues,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box px={4} minWidth="40vw">
            <Typography variant="h5" textAlign="center" p={2}>
              Enroll a user in a classroom
            </Typography>
            <Box maxWidth="860px" mt={2}>
              <Box mb={3}>
                <SelectAsync
                  id="schools"
                  label="Schools"
                  disabled={!!school}
                  icon={<SearchIcon/>}
                  error={touched.schoolId && errors.schoolId}
                  touched={touched.schoolId}
                  value={values.schoolId}
                  onChange={(value) => {
                    setSelectedSchool(value)
                    setValues({schoolId: value, classroomId: ""})
                  }}
                  loading={schoolIdsQuery.loading}
                  options={schools}
                  onUpdateOptions={handleQuerySchoolIds}
                />
              </Box>
              <Box>
                <SelectAsync
                  id="classrooms"
                  label="Classrooms"
                  icon={<SearchIcon/>}
                  error={touched.classroomId && errors.classroomId}
                  touched={touched.classroomId}
                  value={values.classroomId}
                  onChange={(value) => {
                    setFieldValue("classroomId", value)
                  }}
                  loading={classroomIdsQuery.loading}
                  options={classrooms}
                  onUpdateOptions={handleQueryClassroomIds}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="end" p={4}>
            <Button type="submit" variant="contained">
              Enroll
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}
