import {gql} from "@apollo/client"

export default gql`
  mutation DeleteJoinRequest($joinRequestId: GraphQLObjectId!) {
    deleteJoinRequest(joinRequestId: $joinRequestId) {
      _id
      status
    }
  }
`
