import React from "react"
import {Box, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {useSelector} from "store"

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100%",
    minHeight: "87px",
    padding: theme.spacing(2, 10),
    overflowY: "auto"
  }
}))

export default function Description() {
  const styles = useStyles()

  const currentTask = useSelector(store => store.practiceSession.currentTask?.task)

  return (
    <Box className={styles.content}>
      <Typography align="left" variant="subtitle1">
        {currentTask?.description}
      </Typography>
    </Box>
  )
}
