import React, {useState} from "react"
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@mui/material"
import ExamsList from "./components/ExamsList"
import {useSelector} from "store"
import {ExamsListSectionProps} from "./types.t"
import {sortedExamsListSelector} from "store/selectors"

export default function ExamsListSection({
  onActivateExam,
  onStartExam
}: ExamsListSectionProps) {
  const [showExpired, setShowExpired] = useState(false)

  const sortedExamsList = useSelector((state) => sortedExamsListSelector(state, showExpired))

  return (
    <Grid item xs={12} mt={3}>
      <Card>
        <CardContent>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}>
            <Grid item ml="auto">
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showExpired}
                      edge="start"
                      name="direction"
                      onChange={() => setShowExpired(current => !current)}
                    />
                  }
                  label={
                    <Box>
                      Expired Switch
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="caption">
                        Display expired access
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <ExamsList
            onStartExam={onStartExam}
            onActivateExam={onActivateExam}
            examsList={sortedExamsList}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
