import {DataTableFilter, DataTableSelectSingleFilter, DataTableState} from "components/DataTable/types.t"
import SelectAsync from "components/SelectAsync"
import SelectAutocomplete from "components/SelectAutocomplete"
import React, {useCallback, useEffect, useState} from "react"

type Props = Omit<DataTableSelectSingleFilter, "type"> & {
  id: DataTableFilter["id"]
  tableState: DataTableState
  onChange: (value: DataTableSelectSingleFilter["options"][0]["value"]) => void
}

export default function SelectSingleFilter({
  id,
  tableState,
  label,
  loading,
  disabled,
  options,
  initialValue,
  value: valueProp,
  onChange,
  onUpdateOptions,
  loadOnce,
  width
}: Props) {
  const [value, setValue] = useState(valueProp || initialValue || "")

  useEffect(() => {
    if (!onUpdateOptions) {
      if (!options.find(i => i.value === value)) {
        setValue(initialValue || "")
      }
    }
  }, [options])

  useEffect(() => {
    if (typeof valueProp === "string") {
      setValue(valueProp)
    } else if (valueProp === null) {
      setValue(initialValue || "")
    }
  }, [valueProp])

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleChange = (value) => {
    setValue(value)
  }

  const handleUpdateOptions = useCallback((inputValue: string) => {
    if (onUpdateOptions) {
      onUpdateOptions(inputValue, tableState)
    }
  }, [tableState])

  return !!onUpdateOptions ? (
    <SelectAsync
      id={id}
      label={label}
      sx={{minWidth: 180, width}}
      loading={loading}
      disabled={disabled}
      options={options}
      value={value}
      onChange={onChange}
      onUpdateOptions={!!onUpdateOptions ? handleUpdateOptions : undefined}
      loadOnce={loadOnce}
    />
  ) : (
    <SelectAutocomplete
      id={id}
      label={label}
      sx={{minWidth: 180, width}}
      loading={loading}
      options={options}
      noOptionsText="Nothing found"
      value={value}
      onChange={handleChange}
    />
  )
}
