import {Box, LinearProgress, Typography} from "@mui/material"
import React from "react"

interface Props {
  label?: string
  value?: number
  width?: string | number
}

export default function ScoreLinear({
  label = "Score",
  value = 0,
  width = "100%"
}: Props) {
  const roundedValue = Math.round(value)

  return (
    <Box>
      <Typography variant="subtitle2" whiteSpace="nowrap">
        {label}: {roundedValue}%
      </Typography>
      <Box width={width}>
        <LinearProgress
          variant="determinate"
          value={roundedValue}
          color={roundedValue < 75 ? "error" : "success"}
        />
      </Box>
    </Box>
  )
}
