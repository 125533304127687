import {gql} from "@apollo/client"

export default gql`
  mutation CreateClassroomInvitation(
    $createClassroomInvitationInput: CreateClassroomInvitationInput
  ) {
    createClassroomInvitation(
      createClassroomInvitationInput: $createClassroomInvitationInput
    ) {
      _id
    }
  }
`
