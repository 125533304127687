import {gql} from "@apollo/client"

export default gql`
  query GetCourseIds {
    courses {
      id
      title {
        rendered
        raw
      }
    }
  }
`
