import {gql} from "@apollo/client"

export default gql`
  query GetSchoolTeachers(
    $schoolId: GraphQLObjectId!
    $offset: Int = 0
    $take: Int = 100
    $sortBy: String
    $order: SortOrder
    $search: String
  ) {
    getSchool(schoolId: $schoolId) {
      teachers(
        take: $take
        offset: $offset
        sortBy: $sortBy
        order: $order
        search: $search
      ) {
        total
        hasMore
        items {
          _id
          id: _id
          createdAt
          updatedAt
          cognitoUserId
          adminInSchoolIds
          teacherInSchoolIds
          username
          isAdmin
          isProctor
          proctorSignedAt
          studentId
          schoolId
          firstName
          lastName
          email
          country
          city
          postcode
          state
          address
          birthdate
          phone
          classroomIds
          fullName
          photoUrl
          vouchers {
            _id
            createdAt
            updatedAt
            createDate
            expirationDate
            examCode
            forTesting
            expired
          }
        }
      }
    }
  }
`
