import {useCallback, useEffect, useRef, useState} from "react"

export default function useElementDimensions () {
  const ref = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState<DOMRect | null>(null)

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect()

    if (domRect) {
      setDimensions(domRect)
    }
  }, [])

  useEffect(() => {
    refresh()
    window.addEventListener("resize", refresh)

    return () => window.removeEventListener("resize", refresh)
  }, [])

  useEffect(() => {
    if (ref.current && dimensions?.width === 0) {
      refresh()
    }
  }, [ref, dimensions])

  return {dimensions, ref}
}
