import {ApolloClient, InMemoryCache} from "@apollo/client"
import {createUploadLink} from "apollo-upload-client"
import {setContext} from "@apollo/client/link/context"
import * as Auth from "aws-amplify/auth"

const httpLink = createUploadLink({
  // Sorry for this hack, I have no control over client env vars –Alex
  uri:
    // process.env.REACT_APP_API_GRAPHQL_URL ||
    process.env.REACT_APP_API_BASE_URL.replace(/\/api$/, "/graphql")
})

const authLink = setContext(async (_, {headers}) => {
  let idtoken: string

  try {
    idtoken = (await Auth.fetchAuthSession()).tokens.idToken.toString()
  } catch {
    idtoken = undefined
  }

  return {
    headers: {
      ...headers,
      idtoken
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default client
