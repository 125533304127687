import React from "react"
import {DataTableActionCol} from "../types.t"
import {Box, IconButton, SxProps, TableCell, Theme, Tooltip} from "@mui/material"

interface Props {
  input: ({id: string} | {_id: string}) & object
  actions: DataTableActionCol["actions"]
  sx?: SxProps<Theme>
}

export default function ActionsCell({
  input,
  actions,
  sx
}: Props) {
  const id = "id" in input ? input?.id : input?._id

  return (
    <TableCell align="center" key={id} sx={sx}>
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        {actions.map((i, num) => (
          <Tooltip title={i.tooltipText || ""} key={num + " _tooltip"}>
            <span>
              <IconButton
                size="small"
                onClick={() => i.onClick(input)}
                disabled={i.disabled}>
                {i.icon}
              </IconButton>
            </span>
          </Tooltip>
        ))}
      </Box>
    </TableCell>
  )
}
