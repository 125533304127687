import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import SessionsTable from "components/SessionsTable"
import useMainPageLink from "hooks/useMainPageLink"
import React from "react"
import {useSelector} from "store"

export default function PracticeResults() {
  const schoolId = useSelector(store => store.schoolSlice.currentSchool)

  const {mainPageLink} = useMainPageLink()

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Practice test results"
      title="Practice test results"
      breadcrumbs={[{
        path: mainPageLink,
        text: "Dashboard"
      }, {
        text: "Practice test results"
      }]}>
      <SessionsTable
        type="testing"
        schoolId={schoolId}
        detailsLink="/practice-results/exam-details/"
      />
    </LayoutWithBreadcrumbs>
  )
}
