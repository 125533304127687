import {gql} from "@apollo/client"

export default gql`
  query GetActivePracticeSession {
    me {
      _id
      activePracticeSession {
        _id
        status
        examCode
        isActive
        isCanceled
        tasks
      }
    }
  }
`
